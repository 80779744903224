import PasswordHide from '@assets/icons/password/PasswordHide';
import PasswordShow from '@assets/icons/password/PasswordShow';
import BasicButton from '@components/editButton/BasicButton';
import { BackgroundColor, WarningColor } from '@styles/colorSemantic';
import { ColumnDivider } from '@styles/common/commonStyles';
import { Input } from '@styles/input';
import { Body4_Medium, Caption1_Regular } from '@styles/typography';
import { Dispatch, SetStateAction } from 'react';
import { MdDelete, MdLibraryAdd } from 'react-icons/md';
import styled from 'styled-components';

interface Props {
    label: string;
    placeholder?: string;
    onChange?: (e: string, type: string, idx?: number) => void;
    value: string;
    type: string;
    inputEdit: boolean;
    $labelWidth?: string;
    $width?: string;
    errMsg?: string;
    setIsPasswordVisible?: () => void;
    isPasswordVisible?: boolean;
    onClick?: () => void;
}

interface AlimTalkReceiversColumnInputWithLabelProps extends Omit<Props, 'value'> {
    value: string[];
    setAlimTalkReceivers: Dispatch<SetStateAction<string[]>>;
}

export const ColumnInputWithLabel = ({ label, placeholder, onChange, value, type, inputEdit, $labelWidth, $width, errMsg, setIsPasswordVisible, isPasswordVisible, onClick }: Props) => {
    return (
        <InputWrapper>
            <Label $labelWidth={$labelWidth}>{label}</Label>
            <Input
                placeholder={placeholder}
                onChange={(e) => onChange && onChange(e.target.value, type)}
                value={value}
                $state={inputEdit}
                readOnly={!inputEdit && true}
                $width={$width}
                type={(type === 'password' || type === 'passwordConfirmation') && !isPasswordVisible ? 'password' : ''}
                $height='35px'
                onClick={type === 'zipCode' && inputEdit ? onClick : undefined}
            />
            {type === 'zipCode' && inputEdit && (
                <BasicButton $type='fill' $height='35px' $width='25%' $marginLeft='18px' onClick={onClick}>
                    우편번호 찾기
                </BasicButton>
            )}
            {errMsg && <ErrorMsg>{errMsg}</ErrorMsg>}
            {type === 'password' && (
                <InputWithLabelIconWrapper onMouseDown={setIsPasswordVisible} $top='13px'>
                    {isPasswordVisible ? <PasswordShow /> : <PasswordHide />}
                </InputWithLabelIconWrapper>
            )}
        </InputWrapper>
    );
};
export const AlimTalkReceiversColumnInputWithLabel = ({
    label,
    placeholder,
    onChange,
    value,
    type,
    inputEdit,
    $labelWidth,
    $width,
    errMsg,
    setAlimTalkReceivers,
}: AlimTalkReceiversColumnInputWithLabelProps) => {
    const addReceiver = () => {
        setAlimTalkReceivers([...value, '']);
    };

    const removeReceiver = (index: number) => {
        const newReceivers = value.filter((_, i) => i !== index);
        setAlimTalkReceivers(newReceivers);
    };

    return (
        <InputWrapper>
            <Label $labelWidth={$labelWidth}>{label}</Label>
            <InputColumnWrapper>
                {value.map((receiver, idx) => (
                    <InputWrapper>
                        <Input
                            placeholder={placeholder}
                            onChange={(e) => onChange && onChange(e.target.value, type, idx)}
                            value={receiver}
                            $state={inputEdit}
                            readOnly={!inputEdit && true}
                            $width={$width}
                            maxLength={13}
                            $height='35px'
                        />
                        {inputEdit && (
                            <InputWithLabelIconWrapper $top='13px' $left='310px' onClick={addReceiver}>
                                <MdLibraryAdd />
                            </InputWithLabelIconWrapper>
                        )}
                        {value.length > 1 && inputEdit && (
                            <InputWithLabelIconWrapper $top='13px' $left='340px' onClick={() => removeReceiver(idx)}>
                                <MdDelete />
                            </InputWithLabelIconWrapper>
                        )}
                    </InputWrapper>
                ))}
            </InputColumnWrapper>

            {errMsg && <ErrorMsg>{errMsg}</ErrorMsg>}
        </InputWrapper>
    );
};

const InputWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    min-height: 50px;
`;

const InputColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Label = styled(Body4_Medium)<{ $labelWidth?: string }>`
    width: ${(props) => props.$labelWidth || '100px'};
`;

const ErrorMsg = styled(Caption1_Regular)<{ $marginLeft?: string }>`
    margin-left: ${(props) => props.$marginLeft || '8px'};
    color: ${WarningColor.color_warning_normal};
`;

const InputWithLabelIconWrapper = styled.button<{ $top: string; $left?: string }>`
    position: absolute;
    left: ${(props) => props.$left || '365px'};
    top: ${(props) => props.$top || '18px'};
    width: 24px;
    height: 24px;
    &:hover {
        cursor: pointer;
        background-color: ${BackgroundColor.color_background_strong};
        border-radius: 50%;
    }
`;
