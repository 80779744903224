import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { IoSearch } from '@react-icons/all-files/io5/IoSearch';
import { BackgroundColor, BorderColor, TextColor } from '@styles/colorSemantic';
import { font } from '@styles/globalStyles';
import { Radius } from '@styles/objectStyles';
import _ from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

type SearchInputProps = {
    // onChange: Dispatch<SetStateAction<string>>;
    onChange: (query: string) => void;
    searchValue: string;
    handleSearchFilter: () => void;
    onChangeDeleteButton: () => void;
};

const SearchInput = ({ onChange, searchValue, handleSearchFilter, onChangeDeleteButton }: SearchInputProps) => {
    return (
        <Wrapper>
            <Input
                placeholder='검색어를 입력해주세요.'
                onChange={(e) => onChange(e.target.value)}
                value={searchValue}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleSearchFilter();
                    }
                }}
            />
            <SearchIconWrapper onClick={handleSearchFilter}>
                <IoSearch size={16} />
            </SearchIconWrapper>
            <CloseButton type='button' hidden={_.isEmpty(searchValue)} onClick={onChangeDeleteButton}>
                <IoClose size={16} />
            </CloseButton>
        </Wrapper>
    );
};

export default SearchInput;

const Wrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 10px;
`;

const Input = styled.input`
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid ${BorderColor.color_border_normal};
    width: 220px;
    height: 50%;
    font-size: ${font.size[14]};
    font-weight: 500;
    padding: 0 8px 0 12px;
    &::placeholder {
        color: ${TextColor.color_text_placeholder};
    }
    &:hover {
        border: 1px solid ${BorderColor.color_border_normal_hover};
    }
    &:focus {
        border: 1px solid ${BorderColor.color_border_normal_press};
    }
`;

const SearchIconWrapper = styled.button`
    height: 26px;
    width: 26px;
    position: absolute;
    right: 10px;
    top: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00326a;
    &:hover {
        border-radius: ${Radius.radius_circle};
        background-color: ${BackgroundColor.color_background_normal};
    }
`;

const CloseButton = styled.button`
    height: 26px;
    width: 26px;
    position: absolute;
    right: 35px;
    top: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00326a;

    &:hover {
        border-radius: ${Radius.radius_circle};
        background-color: ${BackgroundColor.color_background_normal};
    }

    ${(props) =>
        props.hidden &&
        `
            display: none;
        `}
`;
