const baseUrl = {
    prod: 'https://asia-northeast3-opusone-doctor-on.cloudfunctions.net',
    dev: 'http://localhost:5001/opusone-doctor-on/asia-northeast3',
    rest: 'https://api.tlchealthcare.co.kr/sms',
};

const apiKey = 'ko4wQEwYmKuKbTFWLDdG3G';

const endPoint = {
    auth: {
        oauth: '/auth/oauth',
    },
    account: {
        read: '/account/read',
        list: '/account/list',
        search: '/account/search',
        update: '/account/update',
        signOut: '/account/signout',
    },
    doctor: {
        readByUserId: '/o2oDoctor/readByUserId',
        create: '/o2oDoctor/create',
        updateDoctor: '/o2oDoctor/update',
        doctorHours: '/o2oDoctor/doctorHours',
        findByEmail: '/o2oDoctor/findByEmail',
        read: '/o2oDoctor/read',
        list: '/o2oDoctor/list',
        delete: '/o2oDoctor/delete',
        updateStatus: '/o2oDoctor/updateStatus',
    },
    hospital: {
        create: '/o2oHospital/create',
        update: '/o2oHospital/update',
        findByEmail: '/o2oHospital/findByEmail',
        findByName: '/o2oHospital/findByName',
        find: '/o2oHospital/find',
        read: '/o2oHospital/read',
        list: '/o2oHospital/list',
        delete: '/o2oHospital/delete',
        addDoctor: '/o2oHospital/addDoctor',
        removeDoctor: '/o2oHospital/removeDoctor',
        addPharmacy: '/o2oHospital/addPharmacy',
        removePharmacy: '/o2oHospital/removePharmacy',
        compactList: '/o2oHospital/compactList',
    },
    client: {
        getClient: '/clients',
        clientSearchList: '/clients/search',
        clientFindById: '/clients/findById',
        createClient: '/clients/create',
        updateClient: '/clients/update',
        deleteClient: '/clients/delete',
        createAdmin: '/clients/createAdmin',
        updateAdmin: '/clients/updateAdmin',
        deleteAdmin: '/clients/deleteAdmin',
        fintByAdminEmail: '/clients/findByAdminEmail',
    },

    product: {
        productSearchList: '/products/search',
        productFindById: '/products/findById',
        productCreate: '/products/create',
        productUpdate: '/products/update',
    },
    clientNotice: {
        clientNoticeList: '/clientNotices/getList',
        clientNoticeCreate: '/clientNotices/create',
        clientNoticeUpdate: '/clientNotices/update',
        getDetail: '/clientNotices/getDetail',
        getNoticePopup: '/clientNotices/getPopup',
        downloadFile: '/clientNotices/download',
        deleteNotice: '/clientNotices/delete',
    },

    pharmacy: {
        create: '/o2oPharmacy/create',
        update: '/o2oPharmacy/update',
        list: '/o2oPharmacy/list',
        readByUserId: '/o2oPharmacy/readByUserId',
        delete: '/o2oPharmacy/delete',
        pharmacyHours: '/o2oPharmacy/pharmacyHours',
    },
    telemedicine: {
        addMedicinePrice: '/teleMedicine/addMedicinePrice',
        diagnosisStatus: '/teleMedicine/diagnosisStatus',
        deleteDiagnosis: '/teleMedicine/deleteDiagnosis',
        dailyDiagnosisRecord: '/teleMedicine/dailyDiagnosisRecord',
        dailyDiagnosisPharmacyRecord: '/teleMedicine/dailyDiagnosisPharmacyRecord',
        dailyDiagnosisDoctorRecord: '/teleMedicine/dailyDiagnosisDoctorRecord',
        prescription: '/teleMedicine/prescription',
        prescriptionModify: '/teleMedicine/prescriptionModify',
        dailyDiagnosisRecordRange: '/teleMedicine/dailyDiagnosisRecordRange',
        dailyDoctorDiagnosisRecordRange: '/teleMedicine/dailyDoctorDiagnosisRecordRange',
        dailyPharmacyDiagnosisRecordRange: '/teleMedicine/dailyPharmacyDiagnosisRecordRange',
        save: '/teleMedicine/save',
        sendDiagnosisStart: 'teleMedicine/sendDiagnosisStart',
    },
    familyTelemedicine: {
        addMedicinePrice: '/familyTelemedicine/addMedicinePrice',
        diagnosisStatus: '/familyTelemedicine/diagnosisStatus',
        patientNameRecord: '/familyTelemedicine/patientNameRecord',
        patientNameRecordDoctor: '/familyTelemedicine/patientNameRecordDoctor',
        patientNameRecordPharmacy: '/familyTelemedicine/patientNameRecordPharmacy',
        prescription: '/familyTelemedicine/prescription',
        save: 'familyTelemedicine/save',
    },
    reservation: {
        setDoctorSchedule: '/reservation/setDoctorSchedule',
        getDoctorSchedule: '/reservation/getDoctorSchedule',
        updateDoctor: '/reservation/updateDoctor',
        hospitalCancel: '/reservation/hospitalCancel',
        confirm: '/reservation/confirm',
        getEnableSlot: '/reservation/getEnableSlot',
        update: '/reservation/update',
        visitComplete: '/reservation/visitComplete',
        testTodayConfirm: '/reservation/testTodayConfirm',
    },
    kakaoMobilityOrder: {
        order: '/kakaoMobilityOrder/order',
        cancel: '/kakaoMobilityOrder/orderCancelBeforeMatching',
        cancelPay: '/kakaoMobilityOrder/payOrderCancelAfterMatching',
    },
    payple: {
        refund: '/payple/refund',
    },
    pushNotification: {
        doctorMessage: '/pushNotification/doctorMessage',
    },
    unospay: {
        deliveryInfo: '/unospay/deliveryInfo',
        findById: '/unospay/findById',
        findByName: '/unospay/findByName',
        findByKeyword: '/unospay/findByKeyword',
        deleteDeliveryInfo: '/unospay/deleteDeliveryInfo',
        readRecordRange: '/unospay/readRecordRange',
        cancelOrder: '/unospay/cancelOrder',
        changeDeliveryStatus: '/unospay/changeStatus',
        updateAddressInfo: '/unospay/updateAddressInfo',
        resendKakaoAlimtalk: '/unospay/resendKakaoAlimtalk',
        updateTransportInfo: '/unospay/updateTransportInfo',
        fulltextSearch: '/unospay/fulltextSearch',
        changeOrderPlatform: '/unospay/changeOrderPlatform',
        foodProviderList: 'unospay/foodProviderList',
        getGfOrder: 'unospay/getGfOrder',
        getGfOrderByTargetDate: 'unospay/getGfOrderByTargetDate',
        getCsMemoByMobile: 'unospay/getCsInfoByMobile',
        getMealsScheduleData: '/unospay/allMealsSchedule',
        getmealDetail: '/unospay/mealDetail',
        createFoodProvider: '/unospay/createFoodProvider',
        getFoodProvider: '/unospay/foodProviderList',
        orderStatisticsDataCode: '/unospay/orderStatisticsDataCode',
        orderStatisticsDataMonth: '/unospay/orderStatisticsDataMonth',
        postCreateOrderInfo: '/unospay/createOrderInfo',
        updateHospitalInfo: '/unospay/updateHospitalInfo',
    },
    calculate: {
        list: '/unospay/getUnospayPaymentData',
        orderStatisticsTypeData: '/unospay/orderStatisticsTypeData',
    },
    productSetting: {
        list: '/tlcProducts/getProductList',
        create: '/tlcProducts/createProducts',
        delete: '/tlcProducts/deleteProduct',
        put: '/tlcProducts/updateProduct',
        providerFind: '/tlcProducts/getProductCodeByType',
    },
};

export { endPoint, baseUrl, apiKey };
