export const setClientTypeIntToString = (clientType?: number) => {
    switch (clientType) {
        case 0:
            return 'WESTERN_MEDICINE';
        case 1:
            return 'ORIENTAL_MEDICINE';
        case 2:
            return 'B2B';
        case 3:
            return 'FITNESS';
        default:
            return '';
    }
};
