import styled from 'styled-components';
import { CustomPaginationProps } from '@api/models/pagination/customPaginationType';
import PrevSectionJumpIcon from '@assets/icons/pagination/PrevSectionJumpIcon';
import PrevPageIcon from '@assets/icons/pagination/PrevPageIcon';
import NextPageIcon from '@assets/icons/pagination/NextPageIcon';
import NextSectionJumpIcon from '@assets/icons/pagination/NextSectionJumpIcon';
import { Border, Radius } from '@styles/objectStyles';
import { BackgroundColor, BorderColor, PrimaryColor, TextColor, OpacityColor } from '@styles/colorSemantic';
import { font } from '@styles/globalStyles';

const CustomedPagination = ({ currentPage, onChangeCurrentPage: handlePageChange, onChangeSectionIdx: handlePageGroup, sectionIndex, totalPages }: CustomPaginationProps) => {
    return (
        <PaginationLayout>
            <ArrowButtonContainer>
                {totalPages && totalPages > 10 && (
                    <PaginationIconWrapper
                        disabled={currentPage <= 10}
                        onClick={() => {
                            const newPage = Math.floor((currentPage - 1) / 10 - 1) * 10 + 1;
                            handlePageGroup(Math.floor((newPage - 1) / 10), newPage);
                        }}
                    >
                        <PrevSectionJumpIcon />
                    </PaginationIconWrapper>
                )}
                <PaginationIconWrapper
                    disabled={currentPage === 1}
                    onClick={() => {
                        if (currentPage % 10 === 1) {
                            handlePageChange(currentPage - 1, sectionIndex - 1);
                        } else {
                            handlePageChange(currentPage - 1);
                        }
                    }}
                >
                    <PrevPageIcon />
                </PaginationIconWrapper>
            </ArrowButtonContainer>
            <PaginationPageButtonWrapper>
                {Array.from({ length: totalPages || 0 }, (_, index) => index + 1)
                    .slice(sectionIndex * 10, sectionIndex * 10 + 10)
                    .map((page) => (
                        <PaginationPageButton key={page} onClick={() => handlePageChange(page)} selected={page === currentPage}>
                            {page}
                        </PaginationPageButton>
                    ))}
            </PaginationPageButtonWrapper>
            <ArrowButtonContainer>
                <PaginationIconWrapper
                    disabled={currentPage === totalPages}
                    onClick={() => {
                        if (currentPage % 10 === 0) {
                            handlePageChange(currentPage + 1, sectionIndex + 1);
                        } else {
                            handlePageChange(currentPage + 1);
                        }
                    }}
                >
                    <NextPageIcon />
                </PaginationIconWrapper>
                {totalPages && totalPages > 10 && (
                    <PaginationIconWrapper
                        disabled={totalPages && (sectionIndex + 1) * 10 >= totalPages ? true : false}
                        onClick={() => {
                            const newPage = Math.floor((currentPage - 1) / 10 + 1) * 10 + 1;
                            // handlePageChange(newPage);
                            handlePageGroup(Math.floor((newPage - 1) / 10), newPage);
                        }}
                    >
                        <NextSectionJumpIcon />
                    </PaginationIconWrapper>
                )}
            </ArrowButtonContainer>
        </PaginationLayout>
    );
};

export default CustomedPagination;

const ArrowButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const PaginationLayout = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 36px;
    padding-bottom: 36px;
`;

const PaginationIconWrapper = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 37px;
    width: 37px;
    &:hover {
        border-radius: ${Radius.radius_circle};
        border: ${Border.border_1} solid ${BorderColor.color_border_normal};
    }
    &:active {
        border-radius: ${Radius.radius_circle};
        border: ${Border.border_1} solid ${BorderColor.color_border_strong};
    }
    &:disabled {
        opacity: 0.24;
        border: none;
        background: none;
    }
`;
const PaginationPageButtonWrapper = styled.div`
    display: flex;
    height: 100%;
    gap: 2px;
`;

const PaginationPageButton = styled.button<{ selected: boolean }>`
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => (props.selected ? PrimaryColor.color_primary_normal : TextColor.color_text_light)};
    font-size: ${font.size[15]};
    font-weight: ${(props) => (props.selected ? font.weight.bold : font.weight.medium)};
    line-height: 22px;
    background-color: ${(props) => (props.selected ? BackgroundColor.color_background_primary_normal : 'transparent')};
    border: ${(props) => props.selected && `1px solid ${OpacityColor.color_opacity_primary_light_20}`};
    border-radius: ${Radius.radius_circle};
    &:hover {
        ${(props) => !props.selected && `border: ${Border.border_1} solid ${BorderColor.color_border_normal};`}
    }
    &:focus {
        ${(props) => !props.selected && `border: ${Border.border_1} solid ${BorderColor.color_border_strong};`}
    }
    &:disabled {
        color: ${TextColor.color_text_light};
    }
`;
