import { AxiosInstance } from 'axios';
import { IOoDataResponse, IOoResponse } from '../reponses';
import { IOoCompactHospital, IOoHospital } from '../models';
import { baseUrl, endPoint } from './base';
import { OoParamCode, OoParamHospitalCreate, OoParamHospitalDoctor, OoParamHospitalPharmacy, OoParamHospitalUpdate, OoParamName } from '@api/params';
import { OoParamEmail, OoParamId } from '../params';
import { ClientCreateRqData, GetClientsApiResponse, GetClientsSearchApiResponse, GetFindByAdminEmailResponse, ITClient } from '@api/models/client/client';
import { GetProductApiResponse, GetProductsSearchApiResponse, ProductCreateRqData } from '@api/models/product/product';
import { CreateAdminApiRqData, UpdateAdminApiRqData } from '@api/models/admin/admin';
import { GetClientNoticeApiDataList, GetClientNoticeApiResponse } from '@api/models/notice/clientNotice/notice';

class DoctorOnHospitalApi {
    constructor(private readonly axiosInstance: AxiosInstance) {}

    async create(param: OoParamHospitalCreate): Promise<IOoDataResponse<IOoHospital>> {
        return await this.axiosInstance.post<IOoDataResponse<IOoHospital>>(endPoint.hospital.create, param);
    }
    async update(param: OoParamHospitalUpdate): Promise<IOoDataResponse<IOoHospital>> {
        return await this.axiosInstance.put<IOoDataResponse<IOoHospital>>(endPoint.hospital.update, param);
    }

    async findByEmail(param: OoParamEmail): Promise<IOoDataResponse<IOoHospital>> {
        const url = `${endPoint.hospital.findByEmail}/${param.getPathParams()}`;
        return await this.axiosInstance.get<IOoDataResponse<IOoHospital>>(url);
    }

    async findByName(param: OoParamName): Promise<IOoDataResponse<IOoHospital>> {
        const url = `${endPoint.hospital.findByName}/${param.getPathParams()}`;
        return await this.axiosInstance.get<IOoDataResponse<IOoHospital>>(url);
    }

    async find(param: string): Promise<IOoDataResponse<IOoHospital>> {
        const url = `${baseUrl.prod}${endPoint.hospital.find}/${param}`;
        return await this.axiosInstance.get<IOoDataResponse<IOoHospital>>(url);
    }

    async read(param: OoParamId): Promise<IOoDataResponse<IOoHospital>> {
        const url = `${endPoint.hospital.read}/${param.getPathParams()}`;
        return await this.axiosInstance.get<IOoDataResponse<IOoHospital>>(url);
    }

    async list(): Promise<IOoDataResponse<IOoHospital[]>> {
        return await this.axiosInstance.get<IOoDataResponse<IOoHospital[]>>(endPoint.hospital.list);
    }

    async compactList(): Promise<IOoDataResponse<IOoCompactHospital[]>> {
        return await this.axiosInstance.get<IOoDataResponse<IOoCompactHospital[]>>(endPoint.hospital.compactList);
    }

    async delete(param: OoParamId): Promise<IOoResponse> {
        const url = `${endPoint.hospital.delete}/${param.getPathParams()}`;
        return await this.axiosInstance.delete<IOoResponse>(url);
        // console.log('hospital::delete ==>> fake delete sorry. from bsh');
        // return {
        //     status: 'ok',
        //     timestamp: Date.now()
        // };
    }
    async addDoctor(param: OoParamHospitalDoctor): Promise<IOoResponse> {
        return await this.axiosInstance.put<IOoResponse>(endPoint.hospital.addDoctor, param);
    }

    async removeDoctor(param: OoParamHospitalDoctor): Promise<IOoResponse> {
        return await this.axiosInstance.put<IOoResponse>(endPoint.hospital.removeDoctor, param);
    }

    async addPharmacy(param: OoParamHospitalPharmacy): Promise<IOoResponse> {
        return await this.axiosInstance.put<IOoResponse>(endPoint.hospital.addPharmacy, param);
    }

    async removePharmacy(param: OoParamHospitalPharmacy): Promise<IOoResponse> {
        return await this.axiosInstance.put<IOoResponse>(endPoint.hospital.removePharmacy);
    }
}

class DoctorOnHospitalSMSApi {
    constructor(private readonly axiosInstance: AxiosInstance) {}

    async clientNoticeCreate(formData: FormData): Promise<IOoDataResponse<GetClientNoticeApiDataList>> {
        return await this.axiosInstance.post<IOoDataResponse<GetClientNoticeApiDataList>>(endPoint.clientNotice.clientNoticeCreate, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }
    async createClient(rqData: ClientCreateRqData): Promise<IOoDataResponse<GetClientsApiResponse>> {
        return await this.axiosInstance.post<IOoDataResponse<GetClientsApiResponse>>(endPoint.client.createClient, rqData);
    }
    async productCreate(rqData: ProductCreateRqData): Promise<IOoDataResponse<GetProductApiResponse>> {
        try {
            const response = await this.axiosInstance.post<IOoDataResponse<GetProductApiResponse>>(endPoint.product.productCreate, rqData);
            return response;
        } catch (error: any) {
            if (error.response) {
                console.log(error.response, 'error.response');
                return error.response;
            } else {
                throw error;
            }
        }
    }
    async createAdmin(rqData: CreateAdminApiRqData): Promise<IOoDataResponse<GetClientsApiResponse>> {
        return await this.axiosInstance.post<IOoDataResponse<GetClientsApiResponse>>(endPoint.client.createAdmin, rqData);
    }
    async clientList(): Promise<IOoDataResponse<ITClient[]>> {
        return await this.axiosInstance.get<IOoDataResponse<ITClient[]>>(endPoint.client.getClient);
    }
    async clientFindById(id: string): Promise<IOoDataResponse<GetClientsApiResponse>> {
        const url = `${endPoint.client.clientFindById}/${id}`;
        return await this.axiosInstance.get<IOoDataResponse<GetClientsApiResponse>>(url);
    }
    async clientSearchList(page: number, limit: number, keyword?: string, clientType?: string): Promise<IOoDataResponse<GetClientsSearchApiResponse>> {
        const url = `${endPoint.client.clientSearchList}?page=${page}&limit=${limit}&keyword=${keyword}&clientType=${clientType}`;
        return await this.axiosInstance.get<IOoDataResponse<GetClientsSearchApiResponse>>(url);
    }
    async productSearchList(page: number, limit: number, keyword?: string): Promise<IOoDataResponse<GetProductsSearchApiResponse>> {
        const url = `${endPoint.product.productSearchList}?page=${page}&limit=${limit}&keyword=${keyword}`;
        return await this.axiosInstance.get<IOoDataResponse<GetProductsSearchApiResponse>>(url);
    }

    async clientNoticeList(page: number, limit: number, target?: string): Promise<IOoDataResponse<GetClientNoticeApiResponse>> {
        const url = `${endPoint.clientNotice.clientNoticeList}?page=${page}&limit=${limit}&target=${target}`;
        return await this.axiosInstance.get<IOoDataResponse<GetClientNoticeApiResponse>>(url);
    }
    async getNoticePopup(clientType: string): Promise<IOoDataResponse<GetClientNoticeApiDataList[]>> {
        const url = `${endPoint.clientNotice.getNoticePopup}/${clientType}`;
        return await this.axiosInstance.get<IOoDataResponse<GetClientNoticeApiDataList[]>>(url);
    }
    async findByAdminEmail(email: string): Promise<IOoDataResponse<GetFindByAdminEmailResponse>> {
        const url = `${endPoint.client.fintByAdminEmail}/${email}`;
        return await this.axiosInstance.get<IOoDataResponse<GetFindByAdminEmailResponse>>(url);
    }

    async clientNoticeDetail(id: string): Promise<IOoDataResponse<GetClientNoticeApiDataList>> {
        const url = `${endPoint.clientNotice.getDetail}/${id}`;
        return await this.axiosInstance.get<IOoDataResponse<GetClientNoticeApiDataList>>(url);
    }
    async clientNoticeDownloadFile(noticeId: string, fileId: string): Promise<any> {
        const url = `${endPoint.clientNotice.downloadFile}/${noticeId}/${fileId}`;
        return await this.axiosInstance.get<any>(url, { responseType: 'blob' });
    }

    async deleteNotice(id: string): Promise<IOoDataResponse<GetClientNoticeApiDataList>> {
        const url = `${endPoint.clientNotice.deleteNotice}/${id}`;
        return await this.axiosInstance.delete<IOoDataResponse<GetClientNoticeApiDataList>>(url);
    }

    async deleteAdmin(id: string): Promise<IOoDataResponse<GetClientsApiResponse>> {
        const url = `${endPoint.client.deleteAdmin}/${id}`;
        return await this.axiosInstance.delete<IOoDataResponse<GetClientsApiResponse>>(url);
    }

    async deleteClient(id: string): Promise<IOoDataResponse<GetClientsApiResponse>> {
        const url = `${endPoint.client.deleteClient}/${id}`;
        return await this.axiosInstance.delete<IOoDataResponse<GetClientsApiResponse>>(url);
    }

    async updateClient(id: string, rqData: ClientCreateRqData): Promise<IOoDataResponse<GetClientsApiResponse>> {
        const url = `${endPoint.client.updateClient}/${id}`;
        return await this.axiosInstance.put<IOoDataResponse<GetClientsApiResponse>>(url, rqData);
    }
    async updateAdmin(id: string, rqData: UpdateAdminApiRqData): Promise<IOoDataResponse<GetClientsApiResponse>> {
        const url = `${endPoint.client.updateAdmin}/${id}`;
        return await this.axiosInstance.put<IOoDataResponse<GetClientsApiResponse>>(url, rqData);
    }

    async clientNoticeUpdate(id: string, formData: FormData): Promise<IOoDataResponse<GetClientNoticeApiDataList>> {
        const url = `${endPoint.clientNotice.clientNoticeUpdate}/${id}`;
        return await this.axiosInstance.put<IOoDataResponse<GetClientNoticeApiDataList>>(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    async productFindById(id: string): Promise<IOoDataResponse<GetProductApiResponse>> {
        const url = `${endPoint.product.productFindById}/${id}`;
        return await this.axiosInstance.get<IOoDataResponse<GetProductApiResponse>>(url);
    }

    async productUpdate(id: string, rqData: ProductCreateRqData): Promise<IOoDataResponse<GetProductApiResponse>> {
        const url = `${endPoint.product.productUpdate}/${id}`;
        try {
            const response = await this.axiosInstance.put<IOoDataResponse<GetProductApiResponse>>(url, rqData);
            return response;
        } catch (error: any) {
            if (error.response) {
                console.log(error.response, 'error.response');
                return error.response;
            } else {
                throw error;
            }
        }
    }
}

export { DoctorOnHospitalApi, DoctorOnHospitalSMSApi };
