// 로컬 스토리지에 데이터 저장
function setLocalStorage(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
}

// 로컬 스토리지에서 데이터 가져오기
function getLocalStorage(key: string) {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
}

// 로컬 스토리지에서 데이터 삭제
function removeLocalStorage(key: string) {
    localStorage.removeItem(key);
}

export { setLocalStorage, getLocalStorage, removeLocalStorage };
