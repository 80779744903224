import React, { useMemo } from 'react';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames/bind';
import buttonStyles from '../../../styles/common/Button.module.scss';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { Button } from 'react-bootstrap';
import { hospitalListState } from '@recoils/display';
import { CustomCard } from '@components/custom';
import { BankInfo, HospitalInfo, HospitalMedicine } from '@components/infoForm';
import { HospitalPictures } from '@components/hospitalPictures';
import { HospitalOperatingHours } from '@components/hospitalOperatingHours';
import { useModalHook } from '@hooks/modal';
import { useHospitalFunctionHook } from '@hooks/hospital';

export const HospitalInfoDetailPage = () => {
    const bx = classNames.bind(buttonStyles);
    const { getHospitalById, deleteHospital } = useHospitalFunctionHook();

    const location = useLocation();
    const id = _.get(location.state, 'data') as string;
    console.log(id, 'Hospital');
    const list = useRecoilValue(hospitalListState);

    const hospitalData = useMemo(() => {
        return getHospitalById(id);
    }, [list]);

    const { addModal } = useModalHook();
    const isDelete = (e: any) => {
        e && deleteHospital(id);
    };

    return (
        <Wrapper>
            {!_.isEmpty(list) && !_.isUndefined(hospitalData) && (
                <>
                    <div>
                        <CustomCard
                            buttonHidden={false}
                            title='병원 기본 정보 [필수]'
                            children={<HospitalInfo data={hospitalData} componentName='hospitalInfo' />}
                            component='hospitalInfo'
                            subTitle='병원 로고 [필수]'
                        />
                        <CustomCard buttonHidden={false} title='병원 사진 [필수]' children={<HospitalPictures data={hospitalData} componentName='hospitalLogo' />} component='hospitalLogo' />
                        <CustomCard
                            buttonHidden={false}
                            children={<BankInfo data={hospitalData} target='hospital' componentName='hospitalBankInfo' />}
                            title='정산 은행 정보'
                            component='hospitalBankInfo'
                        />
                        <CustomCard buttonHidden={false} children={<HospitalMedicine data={hospitalData} componentName='hospitalMedicine' />} title='병원약 발송 픽업지' component='hospitalMedicine' />
                        <CustomCard buttonHidden={false} children={<HospitalOperatingHours data={hospitalData} componentName='hospitalTime' />} title='병원 운영시간' component='hospitalTime' />
                    </div>
                    <ButtonContainer>
                        <Button
                            type='button'
                            onClick={() =>
                                addModal({
                                    type: 'twoBtnModal',
                                    data: {
                                        title: '병원을 삭제하시겠습니까?',
                                        contents: <div>선택하신 병원이 삭제됩니다. 이 동작은 취소 할 수 없습니다.</div>,
                                        buttons: [
                                            { title: '취소' },
                                            {
                                                title: '삭제하기',
                                                callback: (e: any) => isDelete(e),
                                            },
                                        ],
                                        goBack: false,
                                    },
                                })
                            }
                            className={bx('diagnosis-cancel-button')}
                        >
                            삭제
                        </Button>
                    </ButtonContainer>
                </>
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    padding: 70px 0px;
    height: 100%;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
`;
