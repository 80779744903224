export enum commonErrText {
    required = '필수 정보입니다.',
    email = '이메일 형식을 확인해주세요.',
    password = '영문, 숫자 포함 8자 이상 입력해주세요.',
    confirmPassword = '비밀번호가 일치하지 않습니다.',
    phoneNumber = '연락처를 정확히 입력해주세요.',
    identityNumber = '주민등록번호를 정확히 입력해주세요.',
    requiredSchoolAgeAndSemester = '필수 정보입니다. 학령과 학기 모두 선택해주세요.',
    requiredYearAndMonth = '필수 정보입니다. 연도와 월을 모두 선택해주세요.',
    confirmRegistSystem = '시스템에 등록된 환자인지 확인해주세요.',
    registeredPatient = '시스템이 등록된 환자입니다.',
    notRegisteredPatient = '시스템에 미 등록된 환자입니다. 새로운 환자로 등록합니다.',
}
