import classNames from 'classnames/bind';
import styles from '../../../../../../styles/common/Icon.module.scss';
import { useRecoilValue } from 'recoil';
import { oauthListState, selectedUserState } from '@recoils/oauth';
import { useNavigate } from 'react-router-dom';
import { useOuathManage } from '@hooks/oauth';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { IOoDataOAuth } from '@api/reponses';
import { NavDropdown } from 'react-bootstrap';

export const useNavbarHooks = () => {
    const cx = classNames.bind(styles);

    const oauthList = useRecoilValue(oauthListState);
    const selectedUser = useRecoilValue(selectedUserState);
    const navigate = useNavigate();
    const { remove, select } = useOuathManage();

    const onChangeUser = () => {
        navigate('/');
    };

    const onSwitchUser = (e: any) => select(e.target.id);
    const onClickLogout = () => {
        if (window.confirm('로그아웃 하시겠습니까?')) {
            !_.isUndefined(selectedUser) && remove(selectedUser);
            navigate('/');
        }
    };

    // 현재 로그인 되어 있는 리스트 불러오기 - bsh
    const GetUserList = () => {
        const [list, setList] = useState<Array<IOoDataOAuth>>([]);
        useEffect(() => {
            const deepOauthList = _.cloneDeep(oauthList);
            const index = _.findIndex(deepOauthList, (item) => _.isEqual(item.user.id, selectedUser?.user.id ?? ''));
            if (_.gte(index, 0)) {
                deepOauthList.splice(index, 1);
                setList(deepOauthList);
            }
        }, []);

        return (
            <>
                {list.map((item) => (
                    <NavDropdown.Item key={item.user.id} id={item.user.id} onClick={onSwitchUser}>
                        {item.user.name}
                    </NavDropdown.Item>
                ))}
                <NavDropdown.Item onClick={onChangeUser}>다른 사용자로 로그인</NavDropdown.Item>
            </>
        );
    };

    return {
        cx,
        oauthList,
        selectedUser,
        navigate,
        remove,
        select,
        onChangeUser,
        onSwitchUser,
        onClickLogout,
        GetUserList,
    };
};
