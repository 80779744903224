import { GetClientsApiResponse } from '@api/models/client/client';
import { GetProductApiResponse, ProductsCreateOptionList } from '@api/models/product/product';
import { isRequestSucceed } from '@api/reponses';
import { DoctorOnAPI } from '@api/request';
import { DropdownWithLabel } from '@components/dropdown/clientTypeDropdown';
import BasicButton from '@components/editButton/BasicButton';
import { ColumnInputWithLabel } from '@components/input/input';
import { useSuspenseHook } from '@hooks/suspense';
import { InverseColor, PrimaryColor } from '@styles/colorSemantic';
import { font } from '@styles/globalStyles';
import { Input } from '@styles/input';
import { Body3_SemiBold, Body4_Medium } from '@styles/typography';
import { ChangeEvent, useEffect, useState } from 'react';
import { MdDelete, MdLibraryAdd } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import styled from 'styled-components';

const AddProductPage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const [initData, setInitData] = useState<GetProductApiResponse>();
    const [orderProductCode, setOrderProductCode] = useState<string>(''); // 발주서 코드
    const [orderProductName, setOrderProductName] = useState<string>(''); // 발주서 제품명
    const [productName, setProductName] = useState<string>('');
    const [productType, setProductType] = useState<number>(0);
    const [providerName, setProviderName] = useState<string>(''); // 업체명
    const [externalCode, setExternalCode] = useState<string>(''); // 제품 코드
    const [deliveryPrice, setDeliveryPrice] = useState<string>(''); // 업체 배송비
    const [retailPrice, setRetailPrice] = useState<string>('');
    const [supplyPrice, setSupplyPrice] = useState<string>('');
    const [settleRate, setSettleRate] = useState<string>('');
    const [isPurchasedProduct, setIsPurchasedProduct] = useState<number>(0); // 공급 방식
    const [status, setStatus] = useState<number>(0); // 판매 상태
    const [visibilityTarget, setVisibilityTarget] = useState<string[]>([]);
    const [viewProductTarget, setViewProductTarget] = useState(ProductsCreateOptionList.ViewProductTarget);
    const [optionList, setOptionList] = useState<ProductsCreateOptionList[]>([
        {
            optionCode: '',
            optionName: '',
            optionOrderCode: '',
            optionOrderName: '',
            optionPrice: 0,
            optionSupplyPrice: 0,
        },
    ]);
    const [inputEdit, setInputEdit] = useState<boolean>(true);
    const [onProductTypeDropdown, setOnProductTypeDropdown] = useState<boolean>(false);
    const [onPurchasedDropdown, setOnPurchasedDropdown] = useState<boolean>(false);
    const [onStatusDropdown, setOnStatusDropdown] = useState<boolean>(false);

    const { setLoading, setStopLoading, setErrorStatus } = useSuspenseHook();

    useEffect(() => {
        if (state && state.id) {
            setInputEdit(false);
            clientListSearchApi();
        } else {
            setInputEdit(true);
        }
    }, [state]);

    useEffect(() => {
        setInitFunc();
    }, [initData]);

    // 특정 병원 정보 가져오기
    const clientListSearchApi = async () => {
        setLoading();
        try {
            const response = await DoctorOnAPI.shared.smsHospital.productFindById(state.id);

            if (isRequestSucceed(response)) {
                response.data?.visibilityTarget.map((target) => {
                    setViewProductTarget((prev) => {
                        return prev.map((prevItem) => {
                            if (prevItem.target === target) {
                                return { ...prevItem, selected: true };
                            }
                            return prevItem;
                        });
                    });
                });
                setInitData(response.data);
                setStopLoading();
            }
            return response;
        } catch (e: any) {
            setStopLoading();
            console.log(e);
        }
    };

    // 제품 등록 api
    const productCreateApi = async () => {
        setLoading();
        try {
            const response = await DoctorOnAPI.shared.smsHospital.productCreate({
                orderProductCode: orderProductCode,
                orderProductName: orderProductName,
                productName: productName, // required
                productType: productType, // required
                providerName: providerName, // required
                externalCode: externalCode, // required
                deliveryPrice: parseFloat(deliveryPrice.replace(/,/g, '')) || 0,
                retailPrice: parseFloat(retailPrice.replace(/,/g, '')) || 0, // required
                supplyPrice: parseFloat(supplyPrice.replace(/,/g, '')) || 0, // required
                settleRate: parseFloat(settleRate.replace(/,/g, '')) || 0, // required
                isPurchasedProduct: isPurchasedProduct === 1 ? true : false,
                status: status - 1,
                visibilityTarget: visibilityTarget,
                optionList: optionList,
            });

            if (isRequestSucceed(response)) {
                toast.success('제품이 등록되었습니다.');
                navigate('/productList', { replace: true });
                setStopLoading();
            } else {
                toast.error('제품 등록에 실패하였습니다.');
                setStopLoading();
            }
        } catch (e: any) {
            console.log(e, 'error');
            setStopLoading();
            throw new Error(e);
        }
    };

    //  product update api
    const productUpdateApi = async () => {
        setLoading();
        try {
            const response = await DoctorOnAPI.shared.smsHospital.productUpdate(state.id, {
                orderProductCode: orderProductCode,
                orderProductName: orderProductName,
                productName: productName, // required
                productType: productType, // required
                providerName: providerName, // required
                externalCode: externalCode, // required
                deliveryPrice: parseFloat(deliveryPrice.replace(/,/g, '')) || 0,
                retailPrice: parseFloat(retailPrice.replace(/,/g, '')) || 0, // required
                supplyPrice: parseFloat(supplyPrice.replace(/,/g, '')) || 0, // required
                settleRate: parseFloat(settleRate.replace(/,/g, '')) || 0, // required
                isPurchasedProduct: isPurchasedProduct === 1 ? true : false,
                status: status - 1,
                visibilityTarget: visibilityTarget,
                optionList: optionList,
            });

            if (isRequestSucceed(response)) {
                toast.success('제품이 수정되었습니다.');
                navigate('/productList', { replace: true });
                setStopLoading();
            } else {
                toast.error('제품 수정에 실패하였습니다.');
                setStopLoading();
            }
        } catch (e: any) {
            console.log(e, 'error');
            setStopLoading();
            throw new Error(e);
        }
    };

    // input change func
    const onHospitalInfoChange = (e: string, type: string) => {
        if (inputEdit) {
            switch (type) {
                case 'providerName':
                    setProviderName(e);
                    break;
                case 'orderProductCode':
                    setOrderProductCode(e);
                    break;
                case 'orderProductName':
                    setOrderProductName(e);
                    break;
                case 'productName':
                    setProductName(e);
                    break;
                case 'externalCode':
                    setExternalCode(e);
                    break;
                case 'deliveryPrice':
                    if (/^[0-9]{0,}$/g.test(e)) setDeliveryPrice(e);
                    break;
                case 'retailPrice':
                    if (/^[0-9]{0,}$/g.test(e)) setRetailPrice(e);
                    break;
                case 'supplyPrice':
                    if (/^[0-9]{0,}$/g.test(e)) setSupplyPrice(e);
                    break;
                case 'settleRate':
                    if (/^[0-9]*\.?[0-9]*$/g.test(e)) setSettleRate(e);
                    break;
                default:
                    break;
            }
        }
    };

    // input에 초기값 설정
    const setInitFunc = () => {
        if (initData && initData !== null) {
            const updatedOptions = initData.optionList.map(({ _id, ...rest }) => rest);

            setOrderProductCode(initData.orderProductCode);
            setOrderProductName(initData.orderProductName);
            setProductName(initData.productName);
            setProductType(initData.productType);
            setProviderName(initData.providerName);
            setExternalCode(initData.externalCode);
            setDeliveryPrice(initData.deliveryPrice?.toString());
            setRetailPrice(initData.retailPrice?.toString());
            setSupplyPrice(initData.supplyPrice?.toString());
            setSettleRate(initData.settleRate?.toString());
            setIsPurchasedProduct(!initData.isPurchasedProduct ? 1 : 0);
            setStatus(initData.status + 1);
            setVisibilityTarget(initData.visibilityTarget);
            setOptionList(updatedOptions);
            initData.visibilityTarget.map((target) => {
                setViewProductTarget((prev) => {
                    return prev.map((prevItem) => {
                        if (prevItem.target === target) {
                            return { ...prevItem, selected: true };
                        }
                        return prevItem;
                    });
                });
            });
        }
    };

    const handleDropdown = (idx: number, type?: string) => {
        switch (type) {
            case 'productType':
                setProductType(idx);
                break;
            case 'purchasedProduct':
                setIsPurchasedProduct(idx);
                break;
            case 'status':
                setStatus(idx);
                break;
            default:
                break;
        }
    };

    // 취소하기 버튼
    const clickCancelButton = () => {
        navigate('/productList', { replace: true });
    };

    const clickEditButton = (type: string) => {
        if (type === 'cancel') {
            setInitFunc();
            setInputEdit(false);
        } else {
            productUpdateApi();
        }
    };

    // optionlist 변경
    const handleChangeOption = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        const { name, value } = e.target;
        setOptionList((prev) => {
            const updatedOptions = [...prev];
            if (name === 'optionPrice' || name === 'optionSupplyPrice') {
                if (/^[0-9]*\.?[0-9]*$/g.test(value))
                    updatedOptions[index] = {
                        ...updatedOptions[index],
                        [name]: Number(value),
                    };
            } else {
                updatedOptions[index] = {
                    ...updatedOptions[index],
                    [name]: value,
                };
            }
            return updatedOptions;
        });
    };

    // option add func
    const handleAddOption = () => {
        setOptionList((prev) => [
            ...prev,
            {
                optionCode: '',
                optionName: '',
                optionOrderCode: '',
                optionOrderName: '',
                optionPrice: 0,
                optionSupplyPrice: 0,
            },
        ]);
    };

    // option delete func
    const handleDeleteOption = (index: number) => {
        if (optionList.length > 1) {
            setOptionList(optionList.filter((_, idx) => idx !== index));
        }
    };

    // 제품타겟 선택
    const handleProductTarget = (label: string, target: string) => {
        const visibility = [...visibilityTarget];

        setViewProductTarget((prev) => prev.map((item) => (item.label === label ? { ...item, selected: !item.selected } : item)));

        if (visibility.includes(target)) {
            setVisibilityTarget(visibility.filter((item) => item !== target));
        } else {
            setVisibilityTarget([...visibility, target]);
        }
    };

    return (
        <Layout>
            <Container>
                <Title>제품 기본 정보</Title>
                <RowWrapper>
                    <ColumnDivider>
                        <DropdownWithLabel
                            label='카테고리'
                            type='productType'
                            selected={productType}
                            handleDropdown={handleDropdown}
                            list={ProductsCreateOptionList.productTypeList}
                            onDropdown={onProductTypeDropdown}
                            setOnDropdown={setOnProductTypeDropdown}
                            buttonEdit={inputEdit}
                            $dropdownHeight='128px'
                        />

                        <ColumnInputWithLabel label='업체명' placeholder='업체명을 입력해주세요.' onChange={onHospitalInfoChange} value={providerName} type='providerName' inputEdit={inputEdit} />
                        <ColumnInputWithLabel label='제품코드' placeholder='제품코드를 입력해주세요.' onChange={onHospitalInfoChange} value={externalCode} type='externalCode' inputEdit={inputEdit} />
                        <ColumnInputWithLabel label='제품명' placeholder='제품명을 입력해주세요.' onChange={onHospitalInfoChange} value={productName} type='productName' inputEdit={inputEdit} />
                    </ColumnDivider>
                    <ColumnDivider>
                        <DropdownWithLabel
                            label='공급방식'
                            type='purchasedProduct'
                            selected={isPurchasedProduct}
                            handleDropdown={handleDropdown}
                            list={ProductsCreateOptionList.purchasedProductList}
                            onDropdown={onPurchasedDropdown}
                            setOnDropdown={setOnPurchasedDropdown}
                            buttonEdit={inputEdit}
                            $dropdownHeight='88px'
                        />
                        <ColumnInputWithLabel label='배송비' placeholder='배송비를 입력해주세요.' onChange={onHospitalInfoChange} value={deliveryPrice} type='deliveryPrice' inputEdit={inputEdit} />
                        <ColumnInputWithLabel
                            label='발주서 코드'
                            placeholder='발주서 코드를 입력해주세요.'
                            onChange={onHospitalInfoChange}
                            value={orderProductCode}
                            type='orderProductCode'
                            inputEdit={inputEdit}
                        />
                        <ColumnInputWithLabel
                            label='발주서 제품명'
                            placeholder='발주서 제품명을 입력해주세요.'
                            onChange={onHospitalInfoChange}
                            value={orderProductName}
                            type='orderProductName'
                            inputEdit={inputEdit}
                        />
                    </ColumnDivider>
                </RowWrapper>
                <RowWrapper>
                    <ColumnInputWithLabel
                        label='소비자가'
                        placeholder='소비자가를 입력해주세요.'
                        onChange={onHospitalInfoChange}
                        value={retailPrice}
                        type='retailPrice'
                        inputEdit={inputEdit}
                        $labelWidth='70px'
                        $width='230px'
                    />
                    <ColumnInputWithLabel
                        label='공급가'
                        placeholder='공급가를 입력해주세요.'
                        onChange={onHospitalInfoChange}
                        value={supplyPrice}
                        type='supplyPrice'
                        inputEdit={inputEdit}
                        $labelWidth='70px'
                        $width='230px'
                    />
                    <ColumnInputWithLabel
                        label='요율'
                        placeholder='요율을 입력해주세요.'
                        onChange={onHospitalInfoChange}
                        value={settleRate}
                        type='settleRate'
                        inputEdit={inputEdit}
                        $labelWidth='70px'
                        $width='230px'
                    />
                </RowWrapper>
                <ColumnDivider>
                    <RowWrapper>
                        {ProductsCreateOptionList.optionTitle.map((title, idx) => {
                            return <OptionWrapper>{title}</OptionWrapper>;
                        })}
                        {inputEdit && (
                            <OptionButtonWrapper onClick={handleAddOption}>
                                <MdLibraryAdd />
                            </OptionButtonWrapper>
                        )}
                    </RowWrapper>
                    <ColumnDivider>
                        {optionList.map((item, idx) => {
                            return (
                                <RowWrapper>
                                    <Input
                                        placeholder='옵션코드'
                                        value={item.optionCode}
                                        name='optionCode'
                                        onChange={(e) => handleChangeOption(e, idx)}
                                        $state={inputEdit}
                                        readOnly={!inputEdit && true}
                                        $width='15%'
                                        $height='35px'
                                    />
                                    <Input
                                        placeholder='옵션명'
                                        value={item.optionName}
                                        name='optionName'
                                        onChange={(e) => handleChangeOption(e, idx)}
                                        $state={inputEdit}
                                        readOnly={!inputEdit && true}
                                        $width='15%'
                                        $height='35px'
                                    />
                                    <Input
                                        placeholder='발주서 옵션코드'
                                        value={item.optionOrderCode}
                                        name='optionOrderCode'
                                        onChange={(e) => handleChangeOption(e, idx)}
                                        $state={inputEdit}
                                        readOnly={!inputEdit && true}
                                        $width='15%'
                                        $height='35px'
                                    />
                                    <Input
                                        placeholder='발주서 옵션명'
                                        value={item.optionOrderName}
                                        name='optionOrderName'
                                        onChange={(e) => handleChangeOption(e, idx)}
                                        $state={inputEdit}
                                        readOnly={!inputEdit && true}
                                        $width='15%'
                                        $height='35px'
                                    />
                                    <Input
                                        placeholder='옵션금액'
                                        value={item.optionPrice}
                                        name='optionPrice'
                                        onChange={(e) => handleChangeOption(e, idx)}
                                        $state={inputEdit}
                                        readOnly={!inputEdit && true}
                                        $width='15%'
                                        $height='35px'
                                    />
                                    <Input
                                        placeholder='옵션공급가'
                                        value={item.optionSupplyPrice}
                                        name='optionSupplyPrice'
                                        onChange={(e) => handleChangeOption(e, idx)}
                                        $state={inputEdit}
                                        readOnly={!inputEdit && true}
                                        $width='15%'
                                        $height='35px'
                                    />
                                    {inputEdit && (
                                        <OptionButtonWrapper onClick={() => handleDeleteOption(idx)}>
                                            <MdDelete />
                                        </OptionButtonWrapper>
                                    )}
                                </RowWrapper>
                            );
                        })}
                    </ColumnDivider>
                </ColumnDivider>
                <RowWrapper>
                    <Label>제품타겟</Label>
                    <FlexWrapper>
                        {viewProductTarget.map((item, idx) => {
                            const type = item.selected ? 'fill' : 'outLine';
                            if (!inputEdit) {
                                if (item.selected) {
                                    return <SelectedTarget>{item.label}</SelectedTarget>;
                                }
                            } else {
                                return (
                                    <BasicButton $type={type} $height='30px' $width='90px' onClick={() => handleProductTarget(item.label, item.target)} disabled={!inputEdit}>
                                        {item.label}
                                    </BasicButton>
                                );
                            }
                        })}
                    </FlexWrapper>
                    <div>
                        <DropdownWithLabel
                            label='상태'
                            type='status'
                            selected={status}
                            handleDropdown={handleDropdown}
                            list={ProductsCreateOptionList.ProductStatusList}
                            onDropdown={onStatusDropdown}
                            setOnDropdown={setOnStatusDropdown}
                            buttonEdit={inputEdit}
                            $dropdownHeight='128px'
                        />
                    </div>
                </RowWrapper>
            </Container>
            {!state && (
                <ButtonWrapper>
                    <BasicButton $type='warning' $height='50px' onClick={clickCancelButton}>
                        취소하기
                    </BasicButton>
                    <BasicButton $type='fill' $height='50px' onClick={productCreateApi} disabled={!productName || !providerName || !externalCode || !retailPrice || !supplyPrice || !settleRate}>
                        등록하기
                    </BasicButton>
                </ButtonWrapper>
            )}
            {state && (
                <ButtonWrapper>
                    {!inputEdit ? (
                        <BasicButton $type='outLine' $height='50px' onClick={() => setInputEdit(true)}>
                            수정하기
                        </BasicButton>
                    ) : (
                        <>
                            <BasicButton $type='warning' $height='50px' onClick={() => clickEditButton('cancel')}>
                                취소하기
                            </BasicButton>
                            <BasicButton
                                $type='fill'
                                $height='50px'
                                disabled={!productName || !providerName || !externalCode || !retailPrice || !supplyPrice || !settleRate}
                                onClick={() => clickEditButton('edit')}
                            >
                                수정하기
                            </BasicButton>
                        </>
                    )}
                </ButtonWrapper>
            )}
        </Layout>
    );
};

export default AddProductPage;

const FlexWrapper = styled.div`
    display: flex;
    width: 400px;
    gap: 10px;
    // align-items: center;
`;

const Label = styled(Body4_Medium)`
    width: 100px;
`;

const OptionWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0 8px 0 12px;
    // justify-content: center;
    width: 15%;
    height: 35px;
`;

const OptionButtonWrapper = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    // height: 50px;
`;

const RowWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    // gap: 40px;
`;

const ColumnDivider = styled.div`
    display: flex;
    flex-direction: column;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 1024px;
    height: auto;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    padding: 40px;
    gap: 20px;
`;

const Layout = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1024px;
    height: auto;
    gap: 25px;
`;

const Title = styled(Body3_SemiBold)`
    line-height: none;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    gap: 20px;
`;

const SelectedTarget = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 30px;
    border-radius: 4px;
    font-size: ${font.size[14]};
    font-weight: ${font.weight.medium};
    background-color: ${PrimaryColor.color_primary_normal};
    color: ${InverseColor.color_inverse_white};
`;
