import React from 'react';

const PrevPageIcon = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M14.495 6.50501C14.7684 6.77838 14.7684 7.2216 14.495 7.49496L9.99 12L14.495 16.505C14.7684 16.7784 14.7684 17.2216 14.495 17.495C14.2217 17.7683 13.7784 17.7683 13.5051 17.495L8.50507 12.495C8.23171 12.2216 8.23171 11.7784 8.50507 11.505L13.5051 6.50501C13.7784 6.23165 14.2217 6.23165 14.495 6.50501Z'
                fill='#989BA2'
            />
        </svg>
    );
};

export default PrevPageIcon;
