import { IOoHospital } from '@api/models';
import { IOoCalculate, Statistics, StatisticsInfo } from '@api/models/calculate/calculate';
import { DoctorOnAPI } from '@api/request';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import calculateListYearExcelDown from '../UnospayDashBoardPage/components/ExcelDownloadButton/components/calculateListYearExcelDown';
import { useSuspenseHook } from '@hooks/suspense';

interface TitleStyle {
    size: number;
    padding?: string;
}

interface Repeat {
    width?: number;
    rows?: number;
    columns?: number;
    justify_div?: string;
    justify_span?: string;
}

interface Article {
    flex: string;
    gap?: number;
}

const CalculateDetailPage = () => {
    const {
        state: { hospitalCode, hospitalName, hospitalId },
    } = useLocation();
    const [statistics, setStatistics] = useState<Statistics[]>();
    const [statisticsInfo, setStatisticsInfo] = useState<StatisticsInfo>();
    const [selectedYear, setSelectedYear] = useState<string>(moment().format('YYYY'));
    const { setLoading, setStopLoading } = useSuspenseHook();

    const getPrevMonth = (monthData?: string, yearData?: string) => {
        if (monthData) {
            const year = yearData;
            const month = monthData;
            const date = moment(`${year}-${month}`).format('YYYY-MM-DD');

            const startDate = moment(date).format('YYYY .MM .DD');
            const endDate = moment(date).endOf('month').format('YYYY .MM .DD');

            return `${startDate} ~ ${endDate}`;
        }
    };

    const refererListParsing = (list: string[]) => {
        if (list) {
            const changeList = list.map((item) => `${item.replace('|', ' ')}%`).join(', ');
            return changeList;
        } else {
            return '';
        }
    };

    const getCalculateDetailData = async () => {
        try {
            setLoading();
            const response = await DoctorOnAPI.shared.calculate.getOrderStatisticsDataMonth(selectedYear, hospitalCode);
            setStopLoading();
            if (response.data) {
                setStatistics(response.data.statistics);
                setStatisticsInfo(response.data.statisticsInfo);
            }
        } catch (error: any) {
            console.log(error.message);
        }
    };

    const handleClickExcelDown = () => {
        calculateListYearExcelDown('', _.orderBy(statistics, 'month'));
    };

    useEffect(() => {
        getCalculateDetailData();
    }, [selectedYear]);
    console.log(selectedYear, 'selectedYear');
    return (
        <Wrapper>
            <Layout>
                <Title size={25}>{hospitalName}</Title>
                <ArticleRow flex='column' gap={20}>
                    <SubTitle size={20}>정산정보</SubTitle>
                    <ArticleColumn flex='row' gap={50}>
                        <Grid width={50} rows={2} columns={2} justify_div='center' justify_span='center'>
                            <div>거래처코드</div>
                            <span>{hospitalName}</span>
                            <div>추천인</div>
                            <span>{refererListParsing(statisticsInfo?.referrerList!)}</span>
                            <div>은행명</div>
                            <span>{statisticsInfo?.bank}</span>
                            <div>계좌번호</div>
                            <span>{statisticsInfo?.bankAccount}</span>
                            <div>예금주</div>
                            <span>{statisticsInfo?.bankHolderName}</span>
                        </Grid>
                        <MeMoLayout>
                            <div>메모</div>
                            <div>{statisticsInfo?.memo}</div>
                        </MeMoLayout>
                    </ArticleColumn>
                </ArticleRow>
                <ArticleRow flex='column' gap={30}>
                    <ArticleSubColumn flex='row'>
                        <SubTitle size={20}>매출 및 정산 금액</SubTitle>
                        <YearSelectWrapper>
                            <YearSelect value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
                                {Array.from({ length: 2 }, (_, i) => moment().year() - 1 + i).map((year) => (
                                    <option key={year} value={year}>
                                        {year}년
                                    </option>
                                ))}
                            </YearSelect>
                            <ExcelBtn onClick={handleClickExcelDown}>내보내기</ExcelBtn>
                        </YearSelectWrapper>
                    </ArticleSubColumn>
                    <Container>
                        <ArticleColumn flex='column' gap={30}>
                            <Grid width={100} columns={3} justify_div='center'>
                                <div>기간</div>
                                <div>월별 매출액</div>
                                <div>월별 정산 금액</div>
                            </Grid>
                            <Container>
                                {!_.isUndefined(statistics) && statistics.length > 0 ? (
                                    _.orderBy(statistics, 'month').map((item) => {
                                        return (
                                            <LinkStyle
                                                to={`/calculateMonthDetail/${selectedYear}`}
                                                state={{
                                                    hospitalId: statisticsInfo?.hospitalId,
                                                    month: item.month,
                                                }}
                                            >
                                                <Grid columns={3}>
                                                    <span>{getPrevMonth(item.month, selectedYear)}</span>
                                                    <span>{item.totalPrice.toLocaleString()}</span>
                                                    <span>{item.totalHospitalCalPrice.toLocaleString()}</span>
                                                </Grid>
                                            </LinkStyle>
                                        );
                                    })
                                ) : (
                                    <Grid justify_span='center'>
                                        <span>데이터 없음</span>
                                    </Grid>
                                )}
                            </Container>
                        </ArticleColumn>
                    </Container>
                </ArticleRow>
            </Layout>
        </Wrapper>
    );
};

export default CalculateDetailPage;

const Wrapper = styled.div`
    padding: 70px 0px;
    width: 100%;
    max-width: 1280px;
    min-width: 1024px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Layout = styled.div`
    width: 100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
    font-size: 14px;
    padding-bottom: 70px;
`;

const LinkStyle = styled(Link)`
    color: black;
    text-decoration: none;
    &:hover,
    &:focus {
        font-weight: bold;
    }
`;

const Title = styled.span<TitleStyle>`
    font-size: ${(props) => props.size}px;
    font-weight: bold;
    width: 95%;
    margin: 20px auto 0px;
`;

const SubTitle = styled.span<TitleStyle>`
    font-size: ${(props) => props.size}px;
    font-weight: bold;
    padding: ${(props) => props.padding};
`;

const ArticleRow = styled.div<Article>`
    width: 95%;
    height: 100%;
    display: flex;
    flex-direction: ${(props) => props.flex};
    row-gap: ${(props) => props.gap}px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const ArticleColumn = styled.div<Article>`
    display: flex;
    flex-direction: ${(props) => props.flex};
    column-gap: ${(props) => props.gap}px;
`;

const MeMoLayout = styled.div`
    width: 50%;
    height: auto;
    display: flex;
    & > div:first-child {
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #cccccc;
        border-left: 1px solid #cccccc;
        border-bottom: 1px solid #cccccc;
    }
    & > div:last-child {
        width: 80%;
        border: 1px solid #cccccc;
        padding: 10px;
    }
`;

const ArticleSubColumn = styled.div<Article>`
    display: flex;
    flex-direction: ${(props) => props.flex};
    column-gap: ${(props) => props.gap}px;
    justify-content: space-between;
`;

const ExcelBtn = styled.button`
    width: 90px;
    height: 35px;
    border: 1px solid #cccccc;
    border-radius: 10px;

    &:hover,
    &:focus {
        background-color: #cccccc;
    }
`;

const Grid = styled.div<Repeat>`
    width: ${(props) => `${props.width}%`};
    display: grid;
    grid-template-rows: ${(props) => `repeat(${props.rows}, 1fr)`};
    grid-template-columns: ${(props) => `repeat(${props.columns}, 1fr)`};

    div {
        padding: 10px;
        border: 1px solid #cccccc;
        background: #eaeaea;
        color: black;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: ${(props) => props.justify_div};
    }
    span {
        padding: 10px;
        border: 1px solid #cccccc;
        display: flex;
        align-items: center;
        justify-content: ${(props) => props.justify_span};
    }
`;

const YearSelectWrapper = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;

const YearSelect = styled.select`
    padding: 5px 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    height: 35px;
`;
