import React, { useEffect, useState } from "react";
import styled, { CSSProperties } from "styled-components";
import _ from "lodash";
import { IOoCalculateMonthList } from "@api/models/calculate/calculateMonthList";
import { useLocation, useParams } from "react-router";
import moment from "moment";
import { DoctorOnAPI } from "@api/request";
import { OoOrderPlatformType } from "@api/models";
import CalculateExcelDown from "../CalculateExcelDown";
import ClipLoader from "react-spinners/ClipLoader";
import { FaFileExcel } from "react-icons/fa";
import { useNavbarHooks } from "@components/layout/headerContainer/Header/Navbar/hooks/useNavbarHooks";
import { useSuspenseHook } from "@hooks/suspense";
interface FontSize {
  size: number;
}

interface Article {
  flex: string;
  gap?: number;
}

interface Repeat {
  width?: number;
  rows?: number;
  columns?: string;
  justify_div?: string;
  justify_span?: string;
}
const DoctorCalculateMonthDetail = () => {
  const {
    state: { hospitalId, month },
  } = useLocation();
  const [calculateList, setCalculateList] = useState<IOoCalculateMonthList[]>([]);
  const [productTotalPrice, setProductTotalPrice] = useState(0);
  const [calculateTotalPrice, setCalculateTotalPrice] = useState(0);
  const [color, setColor] = useState("#19ce60");
  const { cx, oauthList, selectedUser, navigate, onClickLogout, GetUserList } = useNavbarHooks();

  const {setLoading, setStopLoading} = useSuspenseHook();


  const { year } = useParams();

  const getOrderPlatformIndexToString = (type: number) => {
    switch (type) {
      case OoOrderPlatformType.kiosk:
        return "키오스크";
      case OoOrderPlatformType.mtmMall:
        return "MTM몰";
      case OoOrderPlatformType.telephone:
        return "전화주문";
      case OoOrderPlatformType.test:
        return "TEST";
      default:
        return "-";
    }
  };

  const handleClickExcelDown = () => {
    const nameMaskingCalculateList = calculateList.map((item) => {
      return {
        ...item,
        payerName: item.payerName ? item.payerName : "",
      };
    });
    CalculateExcelDown(nameMaskingCalculateList);
  };

  const getUnospayPayment = async () => {
    // const year = moment().year();
    const date = moment(`${year}-${month}`).format("YYYY-MM-DD");
    const param = {
      startDate: moment(date).format("YYYY-MM-DD"),
      endDate: moment(date).endOf("month").format("YYYY-MM-DD"),
      hospitalId,
    };

    setLoading();
    const response = await DoctorOnAPI.shared.calculate.getUnospayPaymentData(param);
    setStopLoading();

    if (response.status === "ok") {
      setStopLoading();
      const filterCancelListData = response.data?.filter((item) => {
        if (
          _.isUndefined(item.operatorCancelTimestamp) ||
          (_.isEqual(item.operatorCancelTimestamp, 0) && _.isEmpty(item.cancelDateTime))
        ) {
          return item;
        }
      });
      setCalculateList([...filterCancelListData!]);
      setProductTotalPrice(filterCancelListData!.reduce((acc, item) => acc + item.paymentPrice, 0));
      setCalculateTotalPrice(
        filterCancelListData!.reduce((acc, item) => {
          if (!_.isUndefined(item.hospitalCalPrice)) {
            return acc + item.hospitalCalPrice;
          } else {
            return 0;
          }
        }, 0)
      );
    }
  };

  useEffect(() => {
    if (hospitalId && month) {
      getUnospayPayment();
    }
  }, []);

  return (
    <Wrapper>
      <Body>
        <ArticleLayout>
          <TitleLayout>
            <Title size={30}>{month}월별 매출 상세</Title>
            <SubTitleLayout flex="row" gap={40}>
              <span>총 판매가격 {productTotalPrice.toLocaleString()}원</span>
              <span>총 정산가격 {calculateTotalPrice.toLocaleString()}원</span>
              <span>주문 건수 {calculateList.length}건</span>
              <ExcelBtn onClick={handleClickExcelDown}>
                <FaFileExcel />
                다운로드
              </ExcelBtn>
            </SubTitleLayout>
          </TitleLayout>
          <Container>
            <Grid
              width={100}
              columns="60px 130px 110px 100px 150px auto 150px 150px"
              justify_div="center"
              className="grid_first"
            >
              <div>순번</div>
              <div>주문일</div>
              <div>주문자</div>
              <div>주문 경로</div>
              <div>업체명</div>
              <div>상품명</div>
              <div>판매가격</div>
              <div>정산 금액</div>
            </Grid>
            {calculateList.length > 0 ? (
              <ArticleColumn flex="column">
                {_.orderBy(calculateList, "dateTime").map((item, index) => (
                  <Grid
                    width={100}
                    columns={"60px 130px 110px 100px 150px auto 150px 150px"}
                    justify_span="center"
                    key={index}
                  >
                    <span>{index + 1}</span>
                    <span>{moment(item.dateTime).format("YYYY-MM-DD")}</span>
                    <span>{item.payerName} </span>
                    <span>{getOrderPlatformIndexToString(item.orderPlatform)}</span>
                    <span>{item.providerName}</span>
                    <span>{item.goods}</span>
                    <span>{item.paymentPrice.toLocaleString()}</span>
                    <span>{item.hospitalCalPrice?.toLocaleString()}</span>
                  </Grid>
                ))}
              </ArticleColumn>
            ) : (
              <Grid width={100} columns={""} justify_span="center">
                <span>데이터 없음</span>
              </Grid>
            )}
          </Container>
        </ArticleLayout>
        <ArticleLayout>
        </ArticleLayout>
      </Body>
    </Wrapper>
  );
};

export default DoctorCalculateMonthDetail;

const Wrapper = styled.div`
  min-width: 1024px;
  width: 100%;
  height: 88%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  border-radius: 30px;

  @media (min-height: 800px) {
    height: 89%;
  }
    
  @media (min-height: 850px) {
    height: 91%;
  }
  
  @media (min-height: 900px) {
    height: 94%;
  }
  
  @media (min-height: 950px) {
    height: 97%;
  }

  @media (min-height: 1000px) {
    height: 100%;
  }
`;

const Body = styled.div`
  width: 100%;
  height: 95%;
  min-width: 1024px;
  background-color: rgb(255, 255, 255);
  display: grid;
  grid-template-columns: 1400px auto;
  grid-gap: 30px;
  padding: 40px;
  border-radius: 30px;
`;

const ArticleLayout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  width: 100%;
  height: 90%;

  @media (max-width: 1024px) {
    width: 75%;
  }
  @media (max-width: 1280px) and (min-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 1366px) and (min-width: 1280px) {
    width: 67%;
  }
  @media (max-width: 1420px) and (min-width: 1366px) {
    width: 68%;
  }
  @media (max-width: 1536px) and (min-width: 1420px) {
    width: 69%;
  }
  @media (max-width: 1680px) and (min-width: 1536px) {
    width: 71%;
  }
  @media (max-width: 1920px) and (min-width: 1680px) {
    width: 87%;
  }
`;

const Layout = styled.div`
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  font-size: 14px;
  padding-bottom: 70px;
`;

const TitleLayout = styled.div`
  top: 200px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const SubTitleLayout = styled.div<Article>`
  display: flex;
  flex-direction: ${(prev) => prev.flex};
  align-items: center;
  column-gap: ${(prev) => prev.gap}px;

  span {
    background-color: #0281ff;
    padding: 3px 8px;
    color: #ffffff;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
  }
`;

const ExcelBtn = styled.button`
  width: 100px;
  height: 35px;
  border: 1px solid #cccccc;
  border-radius: 10px;
  background-color: #ffffff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;

  svg {
    color: #2176f6;
    font-size: 18px;
  }

  :hover {
    background-color: #2176f6;
    color: #ffffff;
    font-weight: 700;
    svg {
      color: #ffffff;
    }
  }
`;

const Title = styled.span<FontSize>`
  font-size: ${(props) => props.size}px;
  font-weight: bold;
`;

const LogoutButton = styled.input`
  background-color: #ffffff;
  border: 1px solid #d1d7dd;
  width: 100px;
  height: 35px;
  border-radius: 10px;
`;

const ContainerLayout = styled.div``

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 95%;

  .grid_first {
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
  }
`;

const ArticleColumn = styled.div<Article>`
  display: flex;
  flex-direction: ${(props) => props.flex};
  column-gap: ${(props) => props.gap}px;
  height: 95%;
  overflow-y: scroll;
  
  // @media (min-height: 1280px) and (max-height: 1366px) {
  //   height: 90%;
  // }
  // @media (min-height: 1180px) and (max-height: 1280px) {
  //   height: 85%;
  // }
  // @media (min-height: 1100px) and (max-height: 1180px) {
  //   height: 75%;
  // }
  // @media (min-height: 1024px) and (max-height: 1100px) {
  //   height: 70%;
  // }
  // @media (min-height: 924px) and (max-height: 1024px) {
  //   height: 55%;
  // }
  // @media (min-height: 300px) and (max-height: 924px) {
  //   height: 45%;
  // }
  // @media (min-height: 768px) and (max-height: 800px) {
  //   height: 35%;
  // }
  @media (min-height: 1280px) and (max-height: 1366px) {
    height: 1000px;
  }
  @media (min-height: 1180px) and (max-height: 1280px) {
    height: 900px;
  }
  @media (min-height: 1100px) and (max-height: 1180px) {
    height: 800px;
  }
  @media (min-height: 1024px) and (max-height: 1100px) {
    height: 700px;
  }
  @media (min-height: 924px) and (max-height: 1024px) {
    height: 600px;
  }
  @media (min-height: 800px) and (max-height: 924px) {
    height: 500px;
  }
  @media (min-height: 768px) and (max-height: 800px) {
    height: 400px;
  }
`;

const Grid = styled.div<Repeat>`
  width: ${(props) => `${props.width}%`};
  display: grid;
  grid-template-rows: ${(props) => `repeat(${props.rows}, 1fr)`};
  grid-template-columns: ${(props) => `${props.columns};`};

  div {
    padding: 13px;
    background: #ffffff;
    color: black;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.justify_div};
  }
  span {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.justify_span};
    font-size: 14px;
    height: 50px;
  }

  &:nth-child(even) {
    background-color: #f2f7fc;
  }

  span:nth-child(1) {
    cursor: pointer;
  }

  span:nth-child(2) {
    cursor: pointer;
  }
`;
