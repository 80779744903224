import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { IoSearch } from '@react-icons/all-files/io5/IoSearch';
import React from 'react';
import styled, { css } from 'styled-components';
import { IOoDiagnosis, IOoDoctor, IOoHospital, IOoUnospayDelivery } from '@api/models';
import { IOoPharmacy } from '@models/pharmacy';
import _ from 'lodash';

interface SearchProps {
    userName?: string;
    list: Array<IOoDiagnosis> | Array<IOoDoctor> | Array<IOoPharmacy> | Array<IOoHospital> | Array<IOoUnospayDelivery>;
}

interface Props {
    name: string;
    onChangeName: (value: string) => void;
    onChangeDeleteButton: (event: boolean) => void;
    onChangeSubmit: (event: boolean) => void;
}

export const SearchBar = ({ name, onChangeName, onChangeDeleteButton, onChangeSubmit }: Props) => {
    //Enter 검색 Event Trigger
    const onKeyPress = (e: any) => {
        _.isEqual(e.key, 'Enter') ? onChangeSubmit(true) : onChangeSubmit(false);
    };

    return (
        <Wrapper>
            <SearchContainer>
                <SearchInput placeholder='이름으로 검색' value={name} onChange={(e: any) => onChangeName(e.target.value)} onKeyPress={onKeyPress} />
                <CloseButton type='button' hidden={_.isEmpty(name)} onClick={() => onChangeDeleteButton(true)}>
                    <IoClose size={16} />
                </CloseButton>
                <SearchButton type='button' onClick={() => onChangeSubmit(true)}>
                    <IoSearch size={16} />
                </SearchButton>
            </SearchContainer>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    height: 36px;
    display: flex;
    justify-content: flex-end;
`;

const SearchContainer = styled.div`
    width: 230px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0.5px solid #c2c1bd;
    border-radius: 8px;
    background: #ffffff;
`;

const SearchInput = styled.input`
    width: 158px;
    border: none;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #00326a;
    padding-left: 5%;

    &:focus-visible {
        outline: none;
    }
`;

const CloseButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 100%;
    border-width: 0.5px 0px 0.5px 0.5px;
    border-style: solid;
    border-color: #c2c1bd;
    background: #ffffff;
    color: #00326a;

    ${(props) =>
        props.hidden &&
        css`
            display: none;
        `}
`;

const SearchButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 100%;
    background: #ffffff;
    border: 0.5px solid #c2c1bd;
    border-radius: 0px 8px 8px 0px;
    color: #00326a;
`;
