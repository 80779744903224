import styled from 'styled-components';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import CustomedPagination from '@components/pagination/CustomedPagination';
import { ClientNoticeListTableProps } from '@api/models/notice/clientNotice/notice';
import moment from 'moment';
import { ListTable } from '@styles/tableStyles';
import { MdAttachFile } from 'react-icons/md';
import { MemberBlueBadge } from '@components/badge/Badge';
import { ProductVisibilityTarget } from '@api/models/product/productEnum';

const ClientNoticeListTable = ({ data, totalCount, currentPage, sectionIndex, totalPages, onChangeCurrentPage, onChangeSectionIdx, type }: ClientNoticeListTableProps) => {
    // const itemsPerPage = 10;

    const visibleTarget = (type: string) => {
        switch (type) {
            case ProductVisibilityTarget.WESTERN_MEDICINE:
                return '병의원';
            case ProductVisibilityTarget.ORIENTAL_MEDICINE:
                return '한의원';
            case ProductVisibilityTarget.B2B:
                return 'B2B';
            case ProductVisibilityTarget.FITNESS:
                return '피트니스';
        }
    };

    return (
        <ListTable.Wrapper type={type}>
            {data.map((item, index) => {
                // const itemNumber = totalCount - (currentPage - 1) * itemsPerPage - index;
                return (
                    <Link key={index} to={`/addClientNotice`} state={{ id: item._id }} style={{ textDecoration: 'none', color: 'black', overflow: 'visible' }}>
                        <ListTable.ListContainer type={type}>
                            <div className='flexNone' style={{ width: '45%' }}>
                                {item.title}
                            </div>
                            <FlexWrapper className='flexNone' style={{ width: '23%' }}>
                                {item.visibilityTarget.map((visibilityTarget) => {
                                    if (visibleTarget(visibilityTarget)) {
                                        return <MemberBlueBadge>{visibleTarget(visibilityTarget)}</MemberBlueBadge>;
                                    }
                                    return null;
                                })}
                            </FlexWrapper>
                            <div style={{ width: '8%' }}>{item.files.length > 0 ? <MdAttachFile size={20} /> : ''}</div>
                            <div style={{ width: '8%' }}>{item.isActive ? 'Y' : 'N'}</div>
                            <div style={{ width: '8%' }}>{item.isPopup ? 'Y' : 'N'}</div>
                            <div style={{ width: '8%' }}>{moment(item.timestamp).format('YYYY/MM/DD')}</div>
                        </ListTable.ListContainer>
                    </Link>
                );
            })}
            <CustomedPagination currentPage={currentPage} onChangeCurrentPage={onChangeCurrentPage} onChangeSectionIdx={onChangeSectionIdx} sectionIndex={sectionIndex} totalPages={totalPages} />
        </ListTable.Wrapper>
    );
};

export default ClientNoticeListTable;

const FlexWrapper = styled.div`
    display: flex;
    gap: 10px;
`;
