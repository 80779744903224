import { IOoProducts } from "@api/models/productSetting/product";
import React, { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import _, { last, update } from "lodash";
import { FaCheck, FaPen } from "react-icons/fa6";
import { ChoiceProps } from "..";
import ProductAddInfoPage from "pages/Operator/ProductAddInfoPage";

interface ProductItemArticleStyle {
  gridTemplateColumns?: string;
  status?: number;  // status prop 추가
}

interface Props {
  list: IOoProducts[] | undefined;
  state: ChoiceProps | undefined;
  setState: Dispatch<SetStateAction<ChoiceProps | undefined>>;
  setShowPopup: Dispatch<SetStateAction<boolean>>;
  productList: IOoProducts[] | undefined;
  filterProductList: IOoProducts[] | undefined;
  setfilterProductList: Dispatch<SetStateAction<IOoProducts[] | undefined>>;
  setChoiceProvider: Dispatch<SetStateAction<string>>;
  setChoiceType: Dispatch<SetStateAction<string>>;
  getProductSettingList: () => void;
}

interface AddProductInfoType {
  externalCode: string;
  providerName: string;
  productName: string;
  productFullName: string;
  productType: number;
  settleRate: number;
  supplyPrice: number;
  retailPrice: number;
  productCode: string;
  status: number;
  optionList: AddOptionInfoListType[];
  deliveryPrice: number;
  isConsignment: boolean;
  isShow: boolean;
}

interface AddOptionInfoListType {
  optionName:string;
  optionCode:string;
  optionPrice:number;
  optionSupplyPrice:number;
  optionOrderName: string;
  optionOrderCode: string;
}

const titles = ["업체명","제품명","코드","공급가","소비자가","요율","카테고리","공급방식","공개여부", ""];

const ProductSettingList = ({
  list,
  state,
  setState,
  setShowPopup,
  filterProductList,
  setfilterProductList,
}: Props) => {
  const [visible, setVisible] = useState(false);
  const [updateProductInfo, setUpdateProductInfo] = useState<AddProductInfoType>({
    externalCode: "",
    providerName: "",
    productName: "",
    productFullName: "",
    productType: 0,
    settleRate: 0,
    supplyPrice: 0,
    retailPrice: 0,
    deliveryPrice: 0,
    productCode: "",
    status: 0,
    optionList: [],
    isConsignment: false,
    isShow: false,
  });

  const handleDeletePopup = (id: string) => {
    setState({ type: "delete", id });
    setShowPopup(true);
  };

  const findUpdateItem = (id: string) => {
    if (!_.isUndefined(list) && list.find(item => item.id === id)) {
      const findItem = list.find(item => item.id === id);
      if (findItem?.optionList.length === 0) {
        const addOptionDefaultData = { ...findItem, optionList: [{ optionName: "", optionCode: "", optionPrice: 0, optionSupplyPrice:0, optionOrderName: "", optionOrderCode: "" }], status: 0 }
        setUpdateProductInfo(addOptionDefaultData);
      } else {
        setUpdateProductInfo(findItem!);
      }
    }
  }

  const handleClickUpdate = (id: string) => {
    setVisible(true);
    findUpdateItem(id);
  };

  return (
    <ProductListContainer>
      <ProductItemHeadContainer>
        <ProductItemLayout>
          <ProductItemHeader gridTemplateColumns="130px 300px 100px 100px 100px 70px 85px 80px 80px auto">
            {titles.map(title => (
              <th key={title}>{title}</th>
            ))}
          </ProductItemHeader>
        </ProductItemLayout>
      </ProductItemHeadContainer>
      {visible && (
        <ProductAddInfoPage filterProductList={filterProductList} setfilterProductList={setfilterProductList} visible={visible} setVisible={setVisible} title='update' updateProductInfo={updateProductInfo} setUpdateProductInfo={setUpdateProductInfo}/>
      )}
      {_.orderBy(list, "externalCode")?.map((product, idx) => (
        <ProductItemContainer key={product.id} status={product.status}>
          <ProductItemLayout>
              {/* <thead> */}
            
              {/* </thead> */}
              <tbody>
              <ProductItemBody 
                gridTemplateColumns="130px 300px 100px 100px 100px 70px 85px 80px 80px auto"
                status={product.status}
              >
                <td>{product.providerName}</td>
                <td>{product.productFullName}</td>
                <td>{product.externalCode}</td>
                <td>{product.supplyPrice.toLocaleString()}</td>
                <td>{product.retailPrice.toLocaleString()}</td>
                <td>{product.settleRate}%</td>
                <td>{product.productType === 0 ? 'MTM' : product.productType === 1 ? 'MTE' : product.productType === 2 ? 'MTS' : 'MTC'}</td>
                <td>{product.isConsignment ? '사입' : '위탁'}</td>
                <td style={{ justifyContent: 'center'}}>{product.isShow ? '공개' : '미공개'}</td>
                <td>
                <ButtonContainer>
                  {/* 버튼 가로로 정렬을 위해 ButtonContainer 사용 */}
                  {state?.type === "update" && product.id === state.id ? (
                    <ProductUpdateButton>
                      <FaCheck />
                    </ProductUpdateButton>
                  ) : (
                    <ProductUpdateButton onClick={() => handleClickUpdate(product.id)}>
                      <>수정</>
                    </ProductUpdateButton>
                  )}
                  <ProductDeleteButton onClick={() => handleDeletePopup(product.id)}>
                    <>삭제</>
                  </ProductDeleteButton>
                </ButtonContainer>
                </td>
              </ProductItemBody>
              </tbody>
          </ProductItemLayout>
        </ProductItemContainer>
      ))}
    </ProductListContainer>
  );
};

export default ProductSettingList;

const ProductListContainer = styled.div`
  width: 100%;
  max-width: 1500px;
  min-width: 1024px;
  height: 86%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;

  @media all and (min-width: 1920px) {
    height: 83%;
  }
`;

const commonTableStyles = `
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 1200px) {
    font-size: 12px;
  }
`;

const ProductItemHeadContainer = styled.table`
  ${commonTableStyles}
  background-color: #bbd1e9;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const ProductItemContainer = styled.table<ProductItemArticleStyle>`
  ${commonTableStyles}
  background-color: ${props => props.status !== 0 ? '#e0e0e0' : '#ffffff'};
`;

const ProductItemLayout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 4px;
`;

const ProductItemHeader = styled.tr<ProductItemArticleStyle>`
  display: grid;
  grid-template-columns: ${props => props.gridTemplateColumns};
  font-size: 14px;
  font-weight: bold;
  color: #393939;
  background-color: #bbd1e9;

  & > th {
    padding: 10px 10px;
    text-align: left;
    border-right: 1px solid #ffffff;

    &:last-child {
      border-right: none;
    }
  }
`;

const ProductItemBody = styled.tr<ProductItemArticleStyle>`
  display: grid;
  grid-template-columns: ${props => props.gridTemplateColumns};
  background-color: transparent; // 배경색을 투명하게 설정

  & > td {
    padding: 4px 8px;
    border-right: 1px solid #eee;
    font-size: 14px;
    font-weight: 700;
    color: ${props => props.status !== 0 ? '#666666' : '#333333'};
    display: flex;
    align-items: center;

    &:last-child {
      border-right: none;
    }
  }

  & > td:nth-child(4),
  & > td:nth-child(5),
  & > td:nth-child(6) {
    justify-content: right;
  }
  
  & > td:nth-child(7),
  & > td:nth-child(8),
  & > td:nth-child(9) {
    justify-content: center;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  justify-content: center;
  margin: 0 auto;
`;

const ProductUpdateButton = styled.button`
  width: 60px;
  height: 35px;
  background-color: #ffffff;
  border: 2px solid #4a90e2;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  color: #4a90e2;
  transition: all 0.3s ease;

  &:hover {
    background-color: #4a90e2;
    color: #ffffff;
    transform: scale(1.05);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const ProductDeleteButton = styled.button`
  width: 60px;
  height: 35px;
  background-color: #ffffff;
  border: 2px solid #ff4d6d;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  color: #ff4d6d;
  transition: all 0.3s ease;

  &:hover {
    background-color: #ff4d6d;
    color: #ffffff;
    transform: scale(1.05);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;
