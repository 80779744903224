export enum ProductStatus {
    INACTIVE = -1,
    ACTIVE = 0,
    SOLD_OUT = 1,
}

export enum ProductVisibilityTarget {
    WESTERN_MEDICINE = 'WESTERN_MEDICINE',
    ORIENTAL_MEDICINE = 'ORIENTAL_MEDICINE',
    B2B = 'B2B',
    FITNESS = 'FITNESS',
}
