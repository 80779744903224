import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import _ from 'lodash';

declare module 'axios' {
    interface AxiosResponse<T = any> extends Promise<T> {}
}

class HttpClient {
    readonly instance: AxiosInstance;

    private readonly authorization: IOoAuthorizationHeader;

    constructor(baseURL: string, authorization: IOoAuthorizationHeader) {
        this.authorization = authorization;
        this.instance = axios.create({
            baseURL,
        });

        this.initializeResponseInterceptor();
    }

    private initializeResponseInterceptor = () => {
        this.instance.interceptors.request.use(this.handleRequest, this.handleError);

        this.instance.interceptors.response.use(this.handleResponse, this.handleError);
    };

    private handleRequest = (config: AxiosRequestConfig) => {
        if (_.isUndefined(config.headers)) {
            config.headers = {};
        }

        // 요청이 form-data를 사용하는 경우 Content-Type을 multipart/form-data로 설정
        if (config.headers['Content-Type'] === 'multipart/form-data') {
            config.headers['Content-Type'] = 'multipart/form-data';
        } else {
            config.headers['Content-Type'] = 'application/json';
        }

        config.headers['authorization'] = this.authorization.authorization();
        return config;
    };

    private handleResponse = ({ data }: AxiosResponse) => data;

    protected handleError = (error: any) => Promise.reject(error);
}

export { HttpClient };

export interface IOoAuthorizationHeader {
    authorization: () => string;
}
