import { ColumnDivider } from '@styles/common/commonStyles';
import { font } from '@styles/globalStyles';
import { Body4_Regular, Title1_Bold_Input } from '@styles/typography';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import dompurify from 'dompurify';
import ReactQuill from 'react-quill';
import QuillModule from '@components/quill/QuillModule';
import BasicButton from '@components/editButton/BasicButton';
import { useSuspenseHook } from '@hooks/suspense';
import { isRequestSucceed } from '@api/reponses';
import { toast } from 'react-toastify';
import { DoctorOnAPI } from '@api/request';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { GetClientNoticeApiDataList, Quill } from '@api/models/notice/clientNotice/notice';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { MdDownload } from 'react-icons/md';
import { BackgroundColor } from '@styles/colorSemantic';
import { ProductsCreateOptionList } from '@api/models/product/product';
import { DropdownWithLabel } from '@components/dropdown/clientTypeDropdown';
import { acceptSMSList } from 'models/clients/addClientPageTypes';
import useToggle from '@hooks/common/useToggle';
import ConfirmModal from '@components/modal/basicModal/ConfirmModal';

const AddClientNoticePage = () => {
    const sanitizer = dompurify.sanitize;
    const quillRef = useRef<ReactQuill | null>(null);

    const navigate = useNavigate();
    const { state } = useLocation();

    const [initData, setInitData] = useState<GetClientNoticeApiDataList>();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState<string>('');
    const [visibilityTarget, setVisibilityTarget] = useState(ProductsCreateOptionList.ViewProductTarget);
    const [file, setFile] = useState<any[]>([]);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [onDropdown, setOnDropdown] = useState<boolean>(false);
    const [onPopupDropdown, setOnPopupDropdown] = useState<boolean>(false);
    const [isActive, setIsActive] = useState<number>(0);
    const [isPopupActive, setIsPopupActive] = useState<number>(0);

    const [isDeleteNoticeModal, setIsDeleteNoticeModal] = useToggle();
    const { setLoading, setStopLoading, setErrorStatus } = useSuspenseHook();

    useEffect(() => {
        if (state && state.id) {
            getDetailNoticeApi();
        }
    }, []);

    useEffect(() => {
        setInitFunc();
    }, [initData]);

    const modules: {} = useMemo(
        () => ({
            toolbar: {
                container: '#toolBar',
            },
        }),
        []
    );

    const onNoticeChange = (e: ChangeEvent<HTMLInputElement>, type: string) => {
        if (type === 'title') setTitle(e.target.value);
    };

    // 공지사항 등록 api
    const clientNoticeCreateAndUpdateApi = async (type: string): Promise<void> => {
        setLoading();

        try {
            const formData = new FormData();
            formData.append('title', title);
            formData.append('content', content);
            formData.append('isActive', isActive === 0 ? 'true' : 'false');
            formData.append('isPopup', isPopupActive === 0 ? 'true' : 'false');
            formData.append('startDate', startDate ? moment(startDate).format('YYYYMMDD') : '');
            formData.append('endDate', endDate ? moment(endDate).format('YYYYMMDD') : '');
            visibilityTarget.map((visibilityTarget) => {
                if (visibilityTarget.selected) formData.append('visibilityTarget', visibilityTarget.target);
            });

            if (file && type === 'create') {
                file.map((file) => {
                    formData.append('files', file);
                });
            }

            let response: any;

            if (type === 'create') {
                response = await DoctorOnAPI.shared.smsHospital.clientNoticeCreate(formData);
            } else if (type === 'update') {
                response = await DoctorOnAPI.shared.smsHospital.clientNoticeUpdate(state.id, formData);
            }

            if (isRequestSucceed(response)) {
                toast.success(type === 'create' ? '공지사항이 등록되었습니다.' : '공지사항이 수정되었습니다.');
                navigate('/clientNoticeList', { replace: true });
                setStopLoading();
            } else {
                toast.error(type === 'create' ? '공지사항 등록에 실패하였습니다.' : '공지사항 수정에 실패하였습니다.');
                setStopLoading();
                console.log(response, 'response');
            }
        } catch (e: any) {
            console.log(e, 'error');
            setStopLoading();
            throw new Error(e);
        }
    };

    // 공지사항 상세 api
    const getDetailNoticeApi = async () => {
        try {
            const response = await DoctorOnAPI.shared.smsHospital.clientNoticeDetail(state.id);

            if (isRequestSucceed(response)) {
                setInitData(response.data);

                console.log(response, 'response');
            }
        } catch (e: any) {
            console.log(e, 'error');
            throw new Error(e);
        }
    };

    const deleteNoticeApi = async () => {
        try {
            const response = await DoctorOnAPI.shared.smsHospital.deleteNotice(state.id);

            if (isRequestSucceed(response)) {
                setIsDeleteNoticeModal();
                toast.success('공지사항이 삭제되었습니다.');
                navigate('/clientNoticeList', { replace: true });
            }
        } catch (e: any) {
            console.log(e, 'error');
            throw new Error(e);
        }
    };

    const downloadFileApi = async (fileId: string, fileName: string) => {
        try {
            const response = await DoctorOnAPI.shared.smsHospital.clientNoticeDownloadFile(state.id, fileId);

            const aElement = document.createElement('a');
            // 위에서 생성한 aElement변수에 href속성에 넣을 데이터를 설정해준다.
            const blobFile = window.URL.createObjectURL(new Blob([response]));
            aElement.href = blobFile;

            aElement.download = fileName;

            aElement.click();

            setTimeout(() => {
                // 이제 더이상 필요 없으니 생성한 a태그를 1초후 삭제 시켜준다.
                aElement.remove();
            }, 1000);
        } catch (e: any) {
            console.log(e, 'error');
            throw new Error(e);
        }
    };

    const setInitFunc = () => {
        if (initData && initData !== null) {
            let copied = _.cloneDeep(visibilityTarget);

            initData.visibilityTarget.forEach((target) => {
                copied.forEach((item) => target === item.target && (item.selected = true));
            });

            setVisibilityTarget(copied);
            setTitle(initData.title);
            setContent(initData.content);
            setIsActive(initData.isActive ? 0 : 1);
            setIsPopupActive(initData.isPopup ? 0 : 1);
            setStartDate(initData.startDate ? moment(initData.startDate).toDate() : null);
            setEndDate(initData.endDate ? moment(initData.endDate).toDate() : null);
            setFile(initData.files ?? []);
        }
    };

    const handleButton = (type: string) => {
        if (type === 'edit') {
            setIsEdit(true);
        } else if (type === 'cancel') {
            setInitFunc();
            setIsEdit(false);
        } else if (type === 'delete') {
            setIsDeleteNoticeModal();
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const selectedFiles: Array<File> = Array.from(e.target.files);

            // 파일 개수 제한
            if (file.length + selectedFiles.length > 5) {
                toast.error('파일은 최대 5개까지 업로드할 수 있습니다.');
                return;
            }

            // 파일 용량 제한
            const oversizedFiles = selectedFiles.filter((file) => file.size > 10 * 1024 * 1024);
            if (oversizedFiles.length > 0) {
                toast.error('파일 용량은 개당 10MB 미만이어야 합니다.');
                return;
            }

            const difference = _.differenceWith(selectedFiles, file, (selected, upload) => _.isEqual(selected.name, upload.name));

            e.target.value = '';

            if (_.isEmpty(difference)) {
                toast.error('중복된 파일입니다.');
                return;
            }
            setFile([...file, ...difference]);
        }
    };

    const onHandleRemove = (e: any) => {
        const filterData = file.filter((item) => item.name !== e.currentTarget.value);
        setFile(filterData);
    };

    const selectTargetFunc = (idx: number) => {
        const copied = _.cloneDeep(visibilityTarget);
        copied[idx].selected = !copied[idx].selected;
        setVisibilityTarget(copied);
    };

    const handleDropdown = (idx: number, type?: string) => {
        if (type === 'isActive') {
            setIsActive(idx);
            if (idx === 1) {
                setIsPopupActive(1);
            }
        } else if (type === 'isPopupActive') {
            setIsPopupActive(idx);
            if (idx === 0) {
                setIsActive(0);
            }
        }
    };

    return (
        <Layout>
            {isDeleteNoticeModal && (
                <ConfirmModal
                    title='공지사항을 삭제하시겠습니까?'
                    content='해당 공지사항이 완전히 삭제됩니다.'
                    firstButton='취소하기'
                    secondButton='삭제하기'
                    setIsLeaveModal={setIsDeleteNoticeModal}
                    clickDeleteButtonInModal={deleteNoticeApi}
                />
            )}
            <Container>
                <ColumnDivider>
                    <FlexContainer>
                        <FlexWrapper>
                            <Body4_Regular>시작일</Body4_Regular>
                            <DatePickerWrapper>
                                <ReactDatePicker
                                    selected={startDate}
                                    onChange={setStartDate}
                                    dateFormat='yyyy/MM/dd'
                                    minDate={new Date()}
                                    placeholderText='시작 날짜'
                                    disabled={state?.id && !isEdit}
                                />
                            </DatePickerWrapper>
                        </FlexWrapper>
                        <FlexWrapper>
                            <Body4_Regular>종료일</Body4_Regular>
                            <DatePickerWrapper>
                                <ReactDatePicker
                                    selected={endDate}
                                    onChange={setEndDate}
                                    dateFormat='yyyy/MM/dd'
                                    minDate={startDate || new Date()}
                                    placeholderText='종료 날짜'
                                    disabled={state?.id && !isEdit}
                                />
                            </DatePickerWrapper>
                        </FlexWrapper>
                        <FlexWrapper>
                            <Body4_Regular>타겟</Body4_Regular>
                            <FlexWrapper>
                                {visibilityTarget.map((item, index) => {
                                    if (state?.id && !isEdit) {
                                        if (item.selected) {
                                            return (
                                                <BasicButton
                                                    $width='60px'
                                                    $height='30px'
                                                    key={index}
                                                    $type={item.selected ? 'fill' : 'outLine'}
                                                    onClick={() => state?.id && isEdit && selectTargetFunc(index)}
                                                >
                                                    {item.label}
                                                </BasicButton>
                                            );
                                        }
                                    } else {
                                        return (
                                            <BasicButton
                                                $width='60px'
                                                $height='30px'
                                                key={index}
                                                $type={item.selected ? 'fill' : 'outLine'}
                                                onClick={() => ((state?.id && isEdit) || !state) && selectTargetFunc(index)}
                                            >
                                                {item.label}
                                            </BasicButton>
                                        );
                                    }
                                })}
                            </FlexWrapper>
                        </FlexWrapper>
                    </FlexContainer>
                    <Title placeholder='제목' onChange={(e) => onNoticeChange(e, 'title')} value={title} disabled={state?.id && !isEdit} />
                </ColumnDivider>
                <Divider />
                <BodyContainer>
                    {!state || isEdit ? (
                        <>
                            <div id='toolBar'>
                                <QuillModule />
                            </div>
                            <ReactQuill ref={quillRef} theme='snow' modules={modules} formats={Quill.formats} id='quillContent' value={content} onChange={setContent} />
                        </>
                    ) : (
                        <SanitizedContent dangerouslySetInnerHTML={{ __html: sanitizer(`${content}`) }} />
                    )}
                </BodyContainer>
                <FlexWrapper>
                    <FlexWrapper>
                        {!state && (
                            <>
                                <Label htmlFor='uploadFile'>파일 선택</Label>
                                <input type='file' id='uploadFile' name='uploadFile' accept='*' multiple={true} onChange={handleFileChange} />
                            </>
                        )}
                        {file.length > 0 && (
                            <FileList>
                                {file.map((file, index) => (
                                    <FileName key={index}>
                                        <FileNameText>{file.name}</FileNameText>
                                        {!state && (
                                            <FileButton value={file.name} onClick={onHandleRemove} $top='2px' $right='5px'>
                                                <FontAwesomeIcon icon={faClose} />
                                            </FileButton>
                                        )}
                                        {state && !isEdit && (
                                            <FileButton value={file.name} onClick={() => downloadFileApi(file._id, file.name)} $top='2px' $right='-30px'>
                                                <MdDownload />
                                            </FileButton>
                                        )}
                                    </FileName>
                                ))}
                            </FileList>
                        )}
                    </FlexWrapper>
                    <FlexWrapper>
                        <DropdownWithLabel
                            label='노출 여부'
                            selected={isActive}
                            handleDropdown={handleDropdown}
                            type='isActive'
                            list={acceptSMSList}
                            onDropdown={onDropdown}
                            setOnDropdown={setOnDropdown}
                            buttonEdit={!state || isEdit}
                            $width='180px'
                            $dropdownWidth='80px'
                            $dropdownHeight='90px'
                        />
                        <DropdownWithLabel
                            label='팝업 여부'
                            selected={isPopupActive}
                            handleDropdown={handleDropdown}
                            type='isPopupActive'
                            list={acceptSMSList}
                            onDropdown={onPopupDropdown}
                            setOnDropdown={setOnPopupDropdown}
                            buttonEdit={!state || isEdit}
                            $width='180px'
                            $dropdownWidth='80px'
                            $dropdownHeight='90px'
                        />
                    </FlexWrapper>
                </FlexWrapper>
            </Container>
            <ButtonWrapper>
                {!state ? (
                    <BasicButton $type='outLine' $height='50px' onClick={() => clientNoticeCreateAndUpdateApi('create')} disabled={!visibilityTarget.some((item) => item.selected)}>
                        등록하기
                    </BasicButton>
                ) : !isEdit ? (
                    <>
                        <BasicButton $type='warning' $height='50px' onClick={() => handleButton('delete')}>
                            삭제하기
                        </BasicButton>
                        <BasicButton $type='outLine' $height='50px' onClick={() => handleButton('edit')} disabled={!visibilityTarget.some((item) => item.selected)}>
                            수정하기
                        </BasicButton>
                    </>
                ) : (
                    <>
                        <BasicButton $type='warning' $height='50px' onClick={() => handleButton('cancel')}>
                            취소하기
                        </BasicButton>
                        <BasicButton $type='fill' $height='50px' onClick={() => clientNoticeCreateAndUpdateApi('update')} disabled={!visibilityTarget.some((item) => item.selected)}>
                            수정하기
                        </BasicButton>
                    </>
                )}
            </ButtonWrapper>
        </Layout>
    );
};

export default AddClientNoticePage;

const FlexContainer = styled.div`
    display: flex;
    height: 30px;
    gap: 30px;
    margin-bottom: 30px;
`;

const FlexWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
`;

const DatePickerWrapper = styled.div`
    display: flex;
    gap: 20px;
    width: 200px;

    .react-datepicker-wrapper {
        width: 200px;
    }

    .react-datepicker__input-container {
        width: 100%;
    }

    input {
        width: 200px;
        height: 30px;
        padding: 10px;
        border: 1px solid #c4c4c4;
        border-radius: 4px;
        font-size: 16px;
        cursor: pointer;

        &:disabled {
            outline: none;
            border: none;
            background: #ffffff;
            cursor: not-allowed;
            font-weight: bold;
        }
    }

    .react-datepicker {
        font-size: 16px;
    }

    .react-datepicker__header {
        background-color: #f0f0f0;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 2.5rem;
        line-height: 2.5rem;
    }
`;

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1024px;
    height: fit-content;
    // background-color: red;
    gap: 25px;
`;

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 1024px;
    height: auto;
    background-color: #ffffff;
    border: 1px solid #c4c4c4;
    padding: 40px;
    gap: 40px;
`;

const Title = styled(Title1_Bold_Input)`
    border: none;
    outline: none;
    background: none;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::placeholder {
        font-weight: ${font.weight.medium};
        color: #949390;
    }
`;

const Divider = styled.div`
    width: 100%;
    min-height: 1px;
    background-color: #c4c4c4;
    // margin: 10px 0;
`;

const BodyContainer = styled.div`
    width: 100%;
    height: 100%;
    #toolBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        width: 100%;
        border: none;
        color: black;
        font-size: 32px;

        #content {
            width: 100%;
        }
        .ql-formats {
            .image-btn {
                font-size: 18px;
                cursor: pointer;

                .icon-custom {
                    margin-right: 5px;
                    font-size: 24px;
                }
            }
        }
    }

    #quillContent {
        border: 1px solid #c4c4c4;

        // text container
        .ql-container {
            width: 100%;
            height: 700px;
            padding: 5px 10px;
            border: none;
            overflow: hidden;

            .ql-editor {
                overflow: auto; /* 스크롤바가 필요할 때만 보이도록 설정 */
                scrollbar-width: thin; /* Firefox에서 스크롤바 너비 설정 */
                scrollbar-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.1); /* Firefox에서 스크롤바 색상 설정 */

                /* 스크롤바 스타일 */
                &::-webkit-scrollbar {
                    width: 12px; /* 스크롤바 너비 */
                }

                &::-webkit-scrollbar-thumb {
                    background-color: rgba(0, 0, 0, 0.2); /* 스크롤바 색상 */
                    border-radius: 10px; /* 스크롤바 모서리 둥글게 */
                }

                &::-webkit-scrollbar-track {
                    background-color: rgba(0, 0, 0, 0.1); /* 스크롤바 트랙 색상 */
                }
            }
        }
    }
`;

const SanitizedContent = styled.div`
    width: 100%;
    height: 700px;
    overflow: auto; /* 스크롤바가 필요할 때만 보이도록 설정 */
    scrollbar-width: thin; /* Firefox에서 스크롤바 너비 설정 */
    scrollbar-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.1); /* Firefox에서 스크롤바 색상 설정 */

    /* 스크롤바 스타일 */
    &::-webkit-scrollbar {
        width: 12px; /* 스크롤바 너비 */
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2); /* 스크롤바 색상 */
        border-radius: 10px; /* 스크롤바 모서리 둥글게 */
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0.1); /* 스크롤바 트랙 색상 */
    }

    img {
        max-width: 100%;
        // height: auto;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    gap: 20px;
    margin-bottom: 60px;
`;

const FileList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

const FileName = styled.li`
    position: relative;
    display: flex;
    width: 300px;
    height: 30px;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #c4c4c4;
    margin: 5px 0;
    padding-left: 10px;
`;
const FileNameText = styled.span`
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Label = styled.label`
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
        background-color: #0056b3;
    }
`;

const FileButton = styled.button<{ $top?: string; $right?: string }>`
    position: absolute;
    width: 25px;
    height: 25px;
    top: ${(props) => props.$top};
    right: ${(props) => props.$right};
    :hover {
        background-color: ${BackgroundColor.color_background_primary_normal};
        border-radius: 99px;
    }
`;
