import { IOoCalculateList } from '@api/models/calculate/calculateList';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import { FlexDiv } from '@components/flexDiv';
interface Props {
    data: Array<IOoCalculateList>;
    sortInfo: {
        name: string;
        type: boolean;
    };
}

const CalculateListContainer = ({ data, sortInfo }: Props) => {
    const getRefrerName = (list: string[]) => {
        if (list) {
            const nameList = list.map((name) => name.split('|')[0]);
            return nameList;
        } else {
            return [];
        }
    };

    const getType = (type: number) => {
        switch (type) {
            case 0:
                return '양방';
            case 1:
                return '한방';
            case 2:
                return 'B2B';
            case 3:
                return '피트니스';
        }
    };

    return (
        <Wrapper>
            {_.orderBy(data, sortInfo.name, sortInfo.type ? 'asc' : 'desc').map((item, index) => (
                <StatusRow key={index}>
                    <Link
                        key={item.id}
                        to={`/calculateDetail`}
                        state={{
                            hospitalCode: item.hospitalCode,
                            hospitalName: item.hospitalName,
                            hospitalId: item.id,
                        }}
                        style={{ textDecoration: 'none', color: 'black', display: 'block', width: '100%' }}
                    >
                        <ListContainer>
                            <span>{item.hospitalCode}</span>
                            <span> {item.hospitalName}</span>
                            <span>{getType(item.type)}</span>
                            <span>{item.totalPrice.toLocaleString()}</span>
                            <span>{item.totalHospitalCalPrice.toLocaleString()}</span>
                            {item.referer.map((r, index) =>
                                getRefrerName(item.referer)[index] ? (
                                    <span>
                                        {getRefrerName(item.referer)[index]} / {item.totalRefererCalPrice[index]}
                                    </span>
                                ) : (
                                    <span> </span>
                                )
                            )}
                            {/* <FlexDiv flex={1}> {item.id}</FlexDiv>
                <FlexDiv flex={1}> {item.orderCount}</FlexDiv> */}
                        </ListContainer>
                    </Link>
                </StatusRow>
            ))}
        </Wrapper>
    );
};

export default CalculateListContainer;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 70%;
    overflow: scroll;

    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }

    @media (min-height: 1024px) and (max-height: 1280px) {
        height: 65%;
    }

    @media (min-height: 768px) and (max-height: 1024px) {
        height: 60%;
    }
`;

const StatusRow = styled.div`
    background: #ffffff;
    display: flex;
    align-items: center;
    box-shadow: 0 1px 3px -2px;
    text-align: center;
    font-size: 12px;
    margin-bottom: 4px;
    width: 100%;

    & > a:first-child {
        width: 100%;
        height: 64px;
        color: #030303;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const ListContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 8% 19% 6% 12.5% 14% 12% 12% 12%;
    text-align: start;
    height: 60px;
    align-items: center;
    margin-bottom: 5px;
    background: #ffffff;
    column-gap: 0.5%;
    justify-content: center;
    text-align: center;
`;
