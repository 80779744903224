import { useRecoilState, useSetRecoilState } from 'recoil';
import { IOoDataOAuth } from 'api';
import _ from 'lodash';
import { oauthListState, selectedUserState } from '@recoils/oauth';

export const useOuathManage = () => {
    const [oauthList, setOauthList] = useRecoilState(oauthListState);
    const setSelectedUser = useSetRecoilState(selectedUserState);

    const getIndex = (list: Array<IOoDataOAuth>, target: IOoDataOAuth) => {
        return _.findIndex(list, (item) => _.isEqual(item.user.id, target?.user.id));
    };

    const add = (oauth: IOoDataOAuth) => {
        if (getIndex(oauthList, oauth) < 0) {
            setOauthList([...oauthList, oauth]);
            setSelectedUser(oauth);
        } else {
            const isSameToken = _.findIndex(oauthList, (item) => _.isEqual(item.user.id, oauth.user.id));
            setOauthList(_.fill(oauthList, oauthList[isSameToken], isSameToken, isSameToken));
            setSelectedUser(oauth);
        }
    };

    const remove = (oauth: IOoDataOAuth) => {
        const index = getIndex(oauthList, oauth);
        if (index < 0) {
            return;
        }
        const copy = oauthList.slice();
        copy.splice(index, 1);
        setOauthList([...copy]);
        copy.length > 0 ? setSelectedUser(_.last(copy)) : setSelectedUser(undefined);
    };

    const select = (userId: string) => {
        const getData = _.find(oauthList, (item) => _.isEqual(item.user.id, userId));
        setSelectedUser(getData);
    };

    return { add, remove, select };
};
