import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import CustomedPagination from '@components/pagination/CustomedPagination';
import { ProductListTableProps, ProductType } from '@api/models/product/product';
import { ListTable } from '@styles/tableStyles';

const ProductListTable = ({ data, totalCount, currentPage, sectionIndex, totalPages, onChangeCurrentPage, onChangeSectionIdx }: ProductListTableProps) => {
    const itemsPerPage = 10;

    const productType = (type: number): string => {
        switch (type) {
            case ProductType.MTM:
                return 'MTM';
            case ProductType.MTE:
                return 'MTE';
            case ProductType.MTS:
                return 'MTS';
            case ProductType.MTC:
                return 'MTC';
            default:
                return '알 수 없음'; // 기본값
        }
    };

    const isPurchasedProduct = (productType: boolean): string => {
        if (productType) {
            return '사입';
        }
        return '위탁';
    };

    return (
        <ListTable.Wrapper>
            {data.map((item, index) => {
                return (
                    <Link key={index} to={`/addProduct`} state={{ id: item._id }} style={{ textDecoration: 'none', color: 'black', overflow: 'visible' }}>
                        <ListTable.ListContainer status={item.status}>
                            <div>{item.providerName}</div>
                            <div>{item.productName}</div>
                            <div>{item.externalCode}</div>
                            <div>{item.supplyPrice.toLocaleString()}</div>
                            <div>{item.retailPrice.toLocaleString()}</div>
                            <div>{item.settleRate}%</div>
                            <div>{productType(item.productType)}</div>
                            <div>{isPurchasedProduct(item.isPurchasedProduct)}</div>
                        </ListTable.ListContainer>
                    </Link>
                );
            })}
            <CustomedPagination currentPage={currentPage} onChangeCurrentPage={onChangeCurrentPage} onChangeSectionIdx={onChangeSectionIdx} sectionIndex={sectionIndex} totalPages={totalPages} />
        </ListTable.Wrapper>
    );
};

export default ProductListTable;
