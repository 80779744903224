import { DoctorOnAPI } from '@api/request';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import _, { last } from 'lodash';

import { useRecoilValue } from 'recoil';
import { DoctorPageSubTitle } from 'pages/Operator/ListPage/components';
import { selectedUserState } from '@recoils/oauth';
import { GetClientNoticeApiDataList } from '@api/models/notice/clientNotice/notice';

import { isRequestSucceed } from '@api/reponses';
import usePaginationStateHooks from '@hooks/common/usePaginationStateHooks';
import NoticesTable from './NoticesTable';
import { setClientTypeIntToString } from 'functions/setClientTypeFunctions';

const Notices = () => {
    const { pathname } = useLocation();

    const [subTitleTarget, setSubTitleTarget] = useState('');
    const [noticeData, setNoticeData] = useState<GetClientNoticeApiDataList[]>();
    const [totalPages, setTotalPages] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [clientTarget, setClientTarget] = useState<string>('');

    const { setPage, currentPage, pageGroup, handlePageGroup } = usePaginationStateHooks(noticeData);
    const selectedUser = useRecoilValue(selectedUserState);

    useEffect(() => {
        const path = last(pathname.split('/') || '') || '';
        setSubTitleTarget(path);
    }, [pathname]);

    useEffect(() => {
        findByAdminEmailApi();
    }, [selectedUser]);

    useEffect(() => {
        if (clientTarget) noticeListApi(currentPage, 10);
    }, [currentPage, clientTarget]);

    const findByAdminEmailApi = async () => {
        try {
            const response = await DoctorOnAPI.shared.smsHospital.findByAdminEmail(selectedUser!.user.email);

            if (isRequestSucceed(response)) {
                const clientType = setClientTypeIntToString(response.data?.clientType);
                setClientTarget(clientType);
            }
            return response;
        } catch (e: any) {
            console.log(e);
        }
    };

    const noticeListApi = async (page: number, limit: number) => {
        try {
            const response = await DoctorOnAPI.shared.smsHospital.clientNoticeList(page, limit, clientTarget);

            if (isRequestSucceed(response)) {
                setNoticeData(response.data?.list);
                setTotalPages(response.data?.pagination.totalPages || 0);
                setTotalCount(response.data?.pagination.totalCount || 0);
            }
            return response;
        } catch (e: any) {
            console.log(e);
        }
    };

    return (
        <Layout>
            <InnerLayout>
                <DoctorPageSubTitle target={subTitleTarget} level={selectedUser && selectedUser.user.level} />
                {noticeData && noticeData.length > 0 ? (
                    <NoticesTable
                        type='doctor'
                        data={noticeData}
                        totalCount={totalCount}
                        currentPage={currentPage}
                        onChangeCurrentPage={setPage}
                        onChangeSectionIdx={handlePageGroup}
                        sectionIndex={pageGroup}
                        totalPages={totalPages}
                    />
                ) : (
                    <NoListContainer>표시 할 내용이 없습니다.</NoListContainer>
                )}
            </InnerLayout>
        </Layout>
    );
};

export default Notices;

const InnerLayout = styled.div`
    display: flex;
    width: 100%;
    max-width: 1280px;
    height: 100%;
    flex-direction: column;
    row-gap: 15px;
    overflow: auto;

    scrollbar-width: thin; /* Firefox에서 스크롤바 너비 설정 */
    scrollbar-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.1); /* Firefox에서 스크롤바 색상 설정 */

    /* 스크롤바 스타일 */
    &::-webkit-scrollbar {
        width: 12px; /* 스크롤바 너비 */
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2); /* 스크롤바 색상 */
        border-radius: 10px; /* 스크롤바 모서리 둥글게 */
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0.1); /* 스크롤바 트랙 색상 */
    }
`;

const Layout = styled.div`
    display: flex;
    background: #ffffff;
    align-items: center;
    width: 100%;
    height: 80%;
    border-radius: 30px;
    padding: 40px;
    flex-direction: column;

    @media (min-height: 800px) {
        height: 85%;
    }

    @media (min-height: 850px) {
        height: 87%;
    }

    @media (min-height: 900px) {
        height: 90%;
    }

    @media (min-height: 950px) {
        height: 93%;
    }

    @media (min-height: 1000px) {
        height: 95%;
    }
`;

const NoListContainer = styled.div`
    height: 100%;
    @media (min-height: 768px) {
        height: 585px;
    }

    @media (min-height: 1024px) {
        height: 75%;
    }
`;
