import React from 'react';
import { Navbar } from './Navbar';
import styled from 'styled-components';

interface Props {
    children: JSX.Element;
}

export const HeaderLayout = ({ children }: Props) => {
    return (
        <>
            <Navbar />
            <BodyContainer>{children}</BodyContainer>
        </>
    );
};

export const HeaderMarginLayout = ({ children }: Props) => {
    return (
        <>
            <Navbar />
            <BodyMarginContainer>{children}</BodyMarginContainer>
        </>
    );
};

const BodyContainer = styled.div`
    width: 100vw;
    height: calc(100vh - 80px);
    overflow: auto;
    background: #f8f8f8;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
`;

const BodyMarginContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 80px);
    overflow: auto;
    background: #f8f8f8;
    margin-top: 80px;
    padding-top: 100px;
`;
