import styled from 'styled-components';
import BasicButton from '@components/editButton/BasicButton';
import NotiWarning from '@assets/icons/notificationIcons/NotiWarning';
import CloseDialog from '@assets/icons/closeIcons/CloseDialog';
import { ConfirmModalProps } from '@api/models/modal/modalTypes';
import { ModalBackground, ModalContent, ModalFooter, ModalLayout, ModalTitle } from './ModalForms';
import { MdClose } from 'react-icons/md';

const ConfirmModal = ({ setIsLeaveModal, clickDeleteButtonInModal, title, content, firstButton, secondButton }: ConfirmModalProps) => {
    return (
        <ModalBackground>
            <ModalLayout $width='520px'>
                <ModalTitleAndContentContainer>
                    <TitleAndIconWrapper>
                        <ModalTitle>{title}</ModalTitle>
                    </TitleAndIconWrapper>
                    <ModalContent>{content}</ModalContent>
                </ModalTitleAndContentContainer>
                <ModalFooter>
                    <BasicButton $width='160px' $height='48px' onClick={setIsLeaveModal} $type='anotherFill'>
                        {firstButton}
                    </BasicButton>
                    <BasicButton $width='160px' $height='48px' onClick={clickDeleteButtonInModal} $type='warning'>
                        {secondButton}
                    </BasicButton>
                </ModalFooter>
                <CloseButtonWrapper onClick={setIsLeaveModal}>
                    <MdClose />
                </CloseButtonWrapper>
            </ModalLayout>
        </ModalBackground>
    );
};

export default ConfirmModal;

const ModalTitleAndContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 12px;
`;

const TitleAndIconWrapper = styled.div`
    display: flex;
    gap: 8px;
`;

const CloseButtonWrapper = styled.div`
    position: absolute;
    top: 12px;
    right: 12px;
    width: 24px;
    height: 24px;
    cursor: pointer;
`;
