import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { DoctorProfileUploader, HospitalLogoUploader, PharmacyProfileUploader } from '@utils/storage';
import { toast } from 'react-toastify';
import { useSuspenseHook } from '@hooks/index';
import { RiCloseLine } from '@react-icons/all-files/ri/RiCloseLine';
import styled from 'styled-components';
import { EditInfo } from '@recoils/status';

interface ImageProps {
    target: string;
    title?: string;
    hospitalId?: string;
    picturesSrc: string;
    editStatus: EditInfo;
    deleteButtonEnabled?: boolean;
    hiddenRemoveButton?: boolean;
    onChanged: (target: string, title: string) => void;
    onRemoved?: (e: any) => void;
}

export const ImageUpload = ({ target, title, hospitalId, picturesSrc, editStatus, hiddenRemoveButton, onChanged, onRemoved }: ImageProps) => {
    const { setLoading, setStopLoading } = useSuspenseHook();
    const [imgSrc, setImgSrc] = useState<string>('');
    const [attachment, setAttachment] = useState<string>(picturesSrc);
    const [fileObject, setFileObject] = useState<File | undefined>(undefined);

    const onLoadPicture = (e: any) => {
        setLoading();
        const reader = new FileReader();
        const target = e.target.files[0];
        setFileObject(target);

        reader.onload = (finishEvent) => {
            const result = finishEvent.target!.result as string;
            setAttachment(result);
        };
        reader.readAsDataURL(target);
    };

    const convertUrl = async (target: string) => {
        if (_.isUndefined(fileObject)) return;
        let downloadUrl: string | null = '';
        switch (target) {
            case 'doctor':
                downloadUrl = await DoctorProfileUploader.upload(hospitalId!, fileObject);
                break;
            case 'hospital':
                downloadUrl = await HospitalLogoUploader.upload(fileObject);
                break;
            case 'pharmacy':
                downloadUrl = await PharmacyProfileUploader.upload(fileObject);
                break;
        }
        if (_.isEmpty(downloadUrl)) {
            toast.error('오류가 발생했습니다.');
            return;
        }
        setStopLoading();
        return downloadUrl;
    };

    const handleRemovePicture = (e: any) => {
        !_.isUndefined(onRemoved) && onRemoved(e);
    };

    // 이미지 첨부 할 경우, convertUrl 함수 실행
    useEffect(() => {
        convertUrl(target)
            .then((s) => setImgSrc(s || ''))
            .catch((r) => console.log('error! convertUrl'));
    }, [fileObject]);

    useEffect(() => {
        !_.isUndefined(imgSrc) && onChanged(imgSrc, title || '');
    }, [imgSrc]);

    useEffect(() => {
        editStatus.isCancel && setAttachment(picturesSrc);
    }, [editStatus.isCancel]);

    useEffect(() => {
        setAttachment(picturesSrc);
    }, [picturesSrc]);

    return (
        <Wrapper>
            <Container>
                <Input type='file' id={`${title}`} accept='.jpg,.png,.jpeg' onChange={onLoadPicture} disabled={!editStatus.isEdit} />
                {editStatus.isEdit ? (
                    <UploadLabel htmlFor={`${title}`} hidden={!editStatus.isEdit} isEmpty={_.isEmpty(attachment)} isHidden={hiddenRemoveButton}>
                        <div className='edit-pictures-div'>
                            <div>
                                <div onClick={handleRemovePicture} id={`${title}`} hidden={_.isEmpty(attachment) || hiddenRemoveButton}>
                                    <RiCloseLine id={`${title}`} />
                                </div>
                                <div>
                                    <img alt='camera-icon' src={process.env.PUBLIC_URL + 'img/camera-icon.png'} />
                                </div>
                            </div>
                            <div className='image-empty-div' hidden={!_.isEmpty(attachment)}>{`image +`}</div>
                            <AttachedImage hidden={_.isEmpty(attachment)} src={attachment} />
                        </div>
                    </UploadLabel>
                ) : (
                    <UploadLabel htmlFor={`${title}`} hidden={editStatus.isEdit} isEmpty={_.isEmpty(attachment)}>
                        <div className='display-pictures-div'>
                            <div hidden={!_.isEmpty(attachment)}>
                                <div>{`image +`}</div>
                            </div>
                            <AttachedImage hidden={_.isEmpty(attachment)} src={attachment} />
                        </div>
                    </UploadLabel>
                )}
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
`;

const Container = styled.div`
    width: 146px;
    height: 146px;
    border: none;
    margin-top: 8px;
`;

const AttachedImage = styled.img`
    width: 146px;
    height: 146px;
    border-radius: 8px;
    border: 1px solid #dcdbd6;
    object-fit: cover;
    position: absolute;
    z-index: 1;
`;

const Input = styled.input`
    width: 100%;
    height: 100%;
    display: none;
`;

const UploadLabel = styled.label<{ isEmpty: boolean; isHidden?: boolean }>`
    width: 146px;
    height: 146px;
    cursor: pointer;
    border: 1px solid #dcdbd6;
    border-radius: 8px;
    background: #eeeeee;
    z-index: 10;
    position: relative;

    .display-pictures-div {
        display: flex;
        height: 100%;

        & > div:nth-child(1) {
            width: 100%;
            height: 100%;
            font-weight: 400;
            font-size: 14px;
            color: #828282;
            z-index: 2;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }

    .edit-pictures-div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;

        .image-empty-div {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            font-weight: 400;
            font-size: 14px;
            color: #828282;
        }

        & > div:nth-child(1) {
            width: 100%;
            height: 100%;
            font-weight: 400;
            font-size: 14px;
            color: #828282;
            z-index: 2;
            position: absolute;
            display: flex;
            justify-content: ${(props) => (props.isEmpty || props.isHidden ? 'flex-end' : 'space-between')};
            align-items: flex-end;
            flex-direction: column;

            & > div:nth-child(1) {
                width: 36px;
                height: 36px;
                margin-top: 4px;
                padding: 4px;
                z-index: 999;

                & > svg {
                    width: 24px;
                    height: 24px;
                }
            }

            & > div:nth-child(2) {
                width: 36px;
                height: 36px;
                margin-right: 4px;
                margin-top: 4px;
                margin-bottom: 4px;
                background: #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;

                & > img {
                    width: 20px;
                    height: 18px;
                }
            }
        }
    }
`;
