import { atom } from 'recoil';
import { GetClientsSearchApiResponse, ITClient } from '@api/models/client/client';

export const clientListState = atom<Array<ITClient>>({
    key: 'clientListState',
    default: [],
});

// export const clientSearchListState = atom<Array<GetClientsSearchApiResponse>>({
//     key: 'clientListState',
//     default: [],
// });
