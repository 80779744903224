import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ListTitle } from '@components/listTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import _, { last } from 'lodash';
import { useRecoilValue } from 'recoil';
import { selectedUserState } from '@recoils/oauth';
import 'react-calendar/dist/Calendar.css';
import { DoctorOnAPI } from '@api/request';
import { isRequestSucceed } from '@api/reponses';
import SearchInput from '@components/searchBar/SearchInput';
import BasicButton from '@components/editButton/BasicButton';
import { ListSubTitle } from 'pages/Operator/ListPage/components';
import { GetProductApiResponse } from '@api/models/product/product';
import ProductListTable from './ProductListTable';
import usePaginationStateHooks from '@hooks/common/usePaginationStateHooks';

const ProductListPage = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [target, setTarget] = useState<string>('');
    const [searchValue, setSearchValue] = useState<string>('');
    const [totalPages, setTotalPages] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [productData, setProductData] = useState<GetProductApiResponse[]>();

    const { setPage, currentPage, pageGroup, handlePageGroup } = usePaginationStateHooks(productData);

    const selectedUser = useRecoilValue(selectedUserState);

    useEffect(() => {
        productListSearchApi(currentPage, 10, searchValue);
    }, [currentPage]);

    useEffect(() => {
        const path = last(pathname.split('/') || '') || '';
        setTarget(path);
    }, [pathname]);

    // client list search api func
    const productListSearchApi = async (page: number, limit: number, keyword?: string) => {
        try {
            const response = await DoctorOnAPI.shared.smsHospital.productSearchList(page, limit, keyword);

            if (isRequestSucceed(response)) {
                console.log(response);
                setProductData(response.data?.products);
                setTotalPages(response.data?.pagination.totalPages || 0);
                setTotalCount(response.data?.pagination.totalCount || 0);
            }
            return response;
        } catch (e: any) {
            console.log(e);
            throw new Error(e);
        }
    };

    // search button click handler
    const clickSearchButton = () => {
        productListSearchApi(1, 10, searchValue);
        setPage(1);
        handlePageGroup(0, 1);
    };

    // search value delete button click handler
    const clickSearchValueDeleteButton = () => {
        setSearchValue('');
        productListSearchApi(1, 10, '');
        setPage(1);
        handlePageGroup(0, 1);
    };

    // 등록하기 버튼
    const clickRegisterButton = () => {
        navigate('/addProduct');
    };

    return (
        <Wrapper>
            <ListTitle title='제품'>
                <SearchContainer>
                    <SearchInput searchValue={searchValue} onChange={setSearchValue} onChangeDeleteButton={clickSearchValueDeleteButton} handleSearchFilter={clickSearchButton} />
                    <BasicButton $width='100px' $height='40px' $type='outLine' onClick={clickRegisterButton}>
                        등록
                    </BasicButton>
                </SearchContainer>
            </ListTitle>
            <ListSubTitle target={target} level={selectedUser && selectedUser.user.level} />
            {productData && productData.length > 0 ? (
                <ProductListTable
                    data={productData}
                    totalCount={totalCount}
                    currentPage={currentPage}
                    onChangeCurrentPage={setPage}
                    onChangeSectionIdx={handlePageGroup}
                    sectionIndex={pageGroup}
                    totalPages={totalPages}
                />
            ) : (
                <NoListContainer>표시 할 내용이 없습니다.</NoListContainer>
            )}
        </Wrapper>
    );
};

export default ProductListPage;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 4px;
`;

const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: 24px;
`;

const NoListContainer = styled.div`
    height: 100%;
    @media (min-height: 768px) {
        height: 585px;
    }

    @media (min-height: 1024px) {
        height: 75%;
    }
`;
