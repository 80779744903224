// Client Api response type
export interface GetClientNoticeApiDataList {
    _id: string;
    title: string;
    content: string;
    isActive: boolean;
    isPopup: boolean;
    visibilityTarget: string[];
    startDate: string;
    endDate: string;
    files: any;
    timestamp: number;
    __v: number;
}

export interface GetClientNoticeApiResponse {
    list: GetClientNoticeApiDataList[];
    pagination: {
        totalCount: number;
        page: number;
        limit: number;
        totalPages: number;
    };
}

// export interface ClientCreateRqData {
//     name: string; // required
//     code: string; // required
//     tel: string; // required
//     address: string; // required
//     addressDetail: string;
//     zipCode: string; // required
//     bank: string;
//     bankAccount: string;
//     bankHolderName: string;
//     referrerList: string[];
//     clientType: number;
//     businessNumber: string;
// }

export interface ClientNoticeListTableProps {
    type?: string;
    data: GetClientNoticeApiDataList[];
    totalCount: number;
    currentPage: number;
    sectionIndex: number;
    totalPages: number;
    onChangeCurrentPage: (page: number, newGroup?: number) => void;
    onChangeSectionIdx: (group: number, newPage: number) => void;
}

export interface VisibleNoticeData {
    data: GetClientNoticeApiDataList;
    onModal: boolean;
}

export interface HiddenItemsType {
    _id: string;
    timestamp: number;
}

export namespace Quill {
    export const formats: string[] = [
        'header',
        'size',
        'font',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'color',
        'background',
        'align',
        'script',
        'code-block',
        'clean',
    ];
}
