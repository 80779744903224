import React from 'react';
import { HospitalType } from '@api/models';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { ClientListTableProps } from '@api/models/client/client';
import CustomedPagination from '@components/pagination/CustomedPagination';
import { ListTable } from '@styles/tableStyles';

const ClientListTable = ({ data, totalCount, currentPage, sectionIndex, totalPages, onChangeCurrentPage, onChangeSectionIdx }: ClientListTableProps) => {
    const itemsPerPage = 10;

    const clientType = (type: number) => {
        switch (type) {
            case HospitalType.normal:
                return '병의원';
            case HospitalType.orientalMedicine:
                return '한의원';
            case HospitalType.b2b:
                return 'B2B';
            case HospitalType.fitness:
                return '피트니스';
        }
    };

    return (
        <ListTable.Wrapper>
            {data.map((item, index) => {
                // const itemNumber = totalCount - (currentPage - 1) * itemsPerPage - index;
                return (
                    <Link key={index} to={`/addClient`} state={{ id: item._id }} style={{ textDecoration: 'none', color: 'black', overflow: 'visible' }}>
                        <ListTable.ListContainer>
                            {/* <div>{itemNumber}</div> */}
                            <div className='flexNone' style={{ width: '25%' }}>
                                {item.name}
                            </div>
                            <div className='flexNone' style={{ width: '40%' }}>
                                {item.address}
                            </div>
                            <div style={{ width: '10%' }}>{item.code}</div>
                            <div style={{ width: '25%' }}>{clientType(item.clientType)}</div>
                        </ListTable.ListContainer>
                    </Link>
                );
            })}
            <CustomedPagination currentPage={currentPage} onChangeCurrentPage={onChangeCurrentPage} onChangeSectionIdx={onChangeSectionIdx} sectionIndex={sectionIndex} totalPages={totalPages} />
        </ListTable.Wrapper>
    );
};

export default ClientListTable;
