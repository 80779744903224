import axios, {AxiosInstance} from "axios";
import {IOoDataResponse, IOoResponse} from "../reponses";
import {baseUrl, endPoint} from "./base";
import { IOoCalculateList } from "@api/models/calculate/calculateList";
import { DoctorOnAPI } from "./doctorOnAPI";
import { IOoCalculate } from "@api/models/calculate/calculate";
import { OoCalculateMonth } from "@api/params/calculate/calculateMonthParam";
import { IOoCalculateMonthList } from "@api/models/calculate/calculateMonthList";
import { IOoCalculateMonthPaymentData } from "@api/models/calculate/calculateMonthPaymentData";

class DoctorOnCalculateApi {
  constructor(private readonly axiosInstance: AxiosInstance) {}
  /*
  * 업체별 정산목록조회
   */
  async getOrderStatisticsData(param:string):Promise<IOoDataResponse<IOoCalculateList[]>>{
      const url = `${baseUrl.prod}${endPoint.unospay.orderStatisticsDataCode}/${param}`;
      return await this.axiosInstance.get<IOoDataResponse<IOoCalculateList[]>>(url);
  }

  async getOrderStatisticsDataMonth(year:string, hospitalCode:string):Promise<IOoDataResponse<IOoCalculate>>{
    const url = `${baseUrl.prod}${endPoint.unospay.orderStatisticsDataMonth}/${year}/${hospitalCode}`;
      return await this.axiosInstance.get<IOoDataResponse<IOoCalculate>>(url);
  }
  async getUnospayPaymentData(param:OoCalculateMonth):Promise<IOoDataResponse<IOoCalculateMonthList[]>>{
      return await this.axiosInstance.post<IOoDataResponse<IOoCalculateMonthList[]>>(endPoint.calculate.list, param);
  }

  async orderStatisticsTypeData(date:string):Promise<IOoDataResponse<IOoCalculateMonthPaymentData[]>>{
    const url = `${baseUrl.prod}${endPoint.calculate.orderStatisticsTypeData}/${date}`;
    return await this.axiosInstance.get<IOoDataResponse<IOoCalculateMonthPaymentData[]>>(url);
  }
}

export { DoctorOnCalculateApi };
