import styled from 'styled-components';
import { palette } from './globalStyles';
import { BackgroundColor } from './colorSemantic';

export namespace ListTable {
    export const Wrapper = styled.div<{ type?: string }>`
        width: ${(props) => (props.type === 'doctor' ? '100%' : '1024px')};
        color: #949390;
        font-weight: 500;
        font-size: 12px;

        -ms-overflow-style: none;
        ::-webkit-scrollbar {
            display: none;
        }

        @media (min-height: 768px) {
            height: 585px;
        }

        @media (min-height: 1024px) {
            height: 75%;
        }

        & > div {
            flex: 1;
            text-align: center;
        }
    `;

    export const ListContainer = styled.div<{ type?: string; status?: number }>`
        display: flex;
        text-align: center;
        height: 60px;
        align-items: center;
        margin-bottom: 7px;
        background-color: ${(props) => (props.status === -1 ? BackgroundColor.color_background_heavy : '#ffffff')};
        box-shadow: 0px 1px 3px -2px rgba(0, 0, 0, 0.1);
        ${(props) => props.type === 'doctor' && `border: 1px solid #d1d7dd; font-size: 15px;`};

        & > div {
            flex: 1;
        }
        .flexNone {
            flex: none;
            text-align: start;
            padding: 0 20px;
        }

        &:hover {
            transition: box-shadow 0.1s ease-in-out;
            box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1),
                // 우측 하단
                -2px 2px 6px rgba(0, 0, 0, 0.1),
                // 좌측 하단
                0 -2px 6px rgba(0, 0, 0, 0.1); // 상단
        }
    `;
}
