import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames/bind';
import styles from '../../../../../../styles/common/Icon.module.scss';
import { useNavigate } from 'react-router-dom';
import { zIndex } from '@styles/globalStyles';

export const LoginNavbar = () => {
    const cx = classNames.bind(styles);
    const navigate = useNavigate();

    return (
        <>
            {
                <NavBar>
                    <div>
                        <NavbarImageContainer onClick={() => navigate('/home')}>
                            {/* <img src={`${process.env.PUBLIC_URL}/img/doctor-on-logo.png`} */}
                            <img src={`${process.env.PUBLIC_URL}/img/login-logo.png`} alt='logo' className={cx('logo')} />
                        </NavbarImageContainer>
                    </div>
                </NavBar>
            }
        </>
    );
};

const NavBar = styled.div`
    width: 100vw;
    height: 80px;
    background: #ffffff;
    border-bottom: 2px solid #d2d2d2;
    display: flex;
    justify-content: center;
    z-index: ${zIndex.sideBar};
    position: fixed;

    & > div:first-child {
        width: 80%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1% 0 1%;
    }
`;

const NavbarImageContainer = styled.button`
    width: 20%;
    display: flex;
    justify-content: center;
    border: none;
    background: none;
`;
