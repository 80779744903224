import { IOoCalculateMonthList } from '@api/models/calculate/calculateMonthList';
import { DoctorOnAPI } from '@api/request';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router';
import styled, { CSSProperties } from 'styled-components';
import _ from 'lodash';
import { OoDeliveryStatus, OoOrderPlatformType } from '@api/models';
import CalculateExcelDown from 'pages/Doctor/CalculateExcelDown';
import ClipLoader from 'react-spinners/ClipLoader';
import { useSuspenseHook } from '@hooks/suspense';

interface FontSize {
    size: number;
  }

interface Article {
    flex: string;
    gap?: number;
    height?:number;
    alignSelf?:string;
}

interface Repeat {
    width?: number;
    rows?: number;
    columns?: number;
    justify_div?: string;
    justify_span?: string;
  }

const CalculateMonthDetail = () => {
    const {
        state: { hospitalId, month },
      } = useLocation();
    const [calculateList, setCalculateList] = useState<IOoCalculateMonthList[]>([])
    const [showCopyMessage, setShowCopyMessage] = useState({
      show:false,
      id:'',
    });
    const [productTotalPrice, setProductTotalPrice] = useState(0);
    const [calculateTotalPrice, setCalculateTotalPrice] = useState(0);
    const [color ,setColor] = useState('#19ce60')
    const {setLoading, setStopLoading} = useSuspenseHook();

    const {year} = useParams();


    const override: CSSProperties = {
      display: "block",
      marginTop: window.screenY/2,
    };

    const getOrderPlatformIndexToString = (type:number) => {
      switch(type){
        case OoOrderPlatformType.kiosk:
          return '키오스크'
        case OoOrderPlatformType.mtmMall:
          return 'MTM몰'
        case OoOrderPlatformType.telephone:
          return '전화주문'
        case OoOrderPlatformType.test:
          return 'TEST'
        default:
          return '-'
      }
    }


    const copyToClipboard = (id:string) => {
      navigator.clipboard
      .writeText(id)
      .then(() => {
        setShowCopyMessage(prev => ({...prev , show:true , id}));
        setTimeout(() => {
          setShowCopyMessage(prev => ({...prev , show:false , id:''}));
        }, 1000);
      })
      .catch((err) => {
        console.error("Error in copying text: ", err);
      });
      }

    const maskingName = (name: string) => {
        if(_.isEmpty(name)){
          return ' '
        }else{
          if(name.length === 2){
            return name.replace(name.substring(0,1),'*')
          }
          return (name[0] +"*".repeat(name.substring(1, name.length - 1).length) +name[name.length - 1]);
        }
    }

    const getUnospayPayment = async () => {
        // const year = moment().year();
        const date = moment(`${year}-${month}`).format("YYYY-MM-DD");
        const param = {
            startDate: moment(date).format("YYYY-MM-DD"),
            endDate: moment(date).endOf("month").format("YYYY-MM-DD"),
            hospitalId,
        }
        setLoading();
        const response = (await DoctorOnAPI.shared.calculate.getUnospayPaymentData(param));
        setStopLoading();
        if(response.status === 'ok'){
          const filterCancelListData = response.data?.filter(item => {
            if(_.isUndefined(item.operatorCancelTimestamp) || _.isEqual(item.operatorCancelTimestamp, 0) && _.isEmpty(item.cancelDateTime)){
              return item;
            }
          })
          setCalculateList([...filterCancelListData!]);
          setProductTotalPrice(filterCancelListData!.reduce((acc, item) => acc + item.paymentPrice, 0));
          setCalculateTotalPrice(filterCancelListData!.reduce((acc , item) => {
          if(!_.isUndefined(item.hospitalCalPrice)){
            return (acc + item.hospitalCalPrice);
          }else{
            return 0
          }
        },0));
        }
    }

    const handleClickExcelDown = () => {
      CalculateExcelDown(calculateList);
    }

    useEffect(() => {
        if(hospitalId && month){
            getUnospayPayment();
        }
    },[])
    return (
    <Wrapper>
      <Layout>
        <TitleLayout>
          <Title size={30}>{month}월 별 매출 상세</Title>
          <SubTitleLayout flex='row' gap={40}>
              <span>총 상품가격 { productTotalPrice.toLocaleString() }</span>
              <span>총 정산가격 { calculateTotalPrice.toLocaleString() }</span>
              <span>주문 건수 { calculateList.length }건</span>
              <ExcelBtn onClick={handleClickExcelDown}>내보내기</ExcelBtn>
            </SubTitleLayout>
          </TitleLayout>
        <Container>
            <Grid width={100} justify_div="center">
                <div>주문일</div>
                <div>주문자</div>
                <div>주문 경로</div>
                <div>상품 업체명</div>
                <div>상품명</div>
                <div>상품 가격</div>
                <div>거래처 정산 금액</div>
            </Grid>
            {calculateList.length > 0 ? 
            <ArticleColumn flex="column">
            {_.orderBy(calculateList,'dateTime').map((item, index) => (
                    <Grid width={100} justify_span="center" key={index}>
                        <>
                          <span onClick={() => copyToClipboard(item.id!)}>{moment(item.dateTime).format('YYYY-MM-DD')}
                          {showCopyMessage.show && showCopyMessage.id === item.id && (
                            <CopyMessageContainer>Copied to clipboard</CopyMessageContainer>
                          )}
                          </span>
                        </>
                        <>
                          <span onClick={() => copyToClipboard(item.orderId)}>{item.payerName!}
                            {showCopyMessage.show && showCopyMessage.id === item.orderId && (
                              <CopyMessageContainer>Copied to clipboard</CopyMessageContainer>
                            )}
                          </span>
                        </>
                        <span>{getOrderPlatformIndexToString(item.orderPlatform)}</span>
                        <span>{item.providerName}</span>
                        <span>{item.goods}</span>
                        <span>{item.paymentPrice.toLocaleString()}</span>
                        <span>{item.hospitalCalPrice?.toLocaleString()}</span>
                    </Grid>
            ))}
            </ArticleColumn>
            :<Grid width={100} columns={1} justify_span="center"><span>데이터 없음</span></Grid>}
        </Container>
    </Layout>
    </Wrapper>
    )
}

export default CalculateMonthDetail

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1400px;
  min-width: 1280px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding: 30px;
`;

const Layout = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  font-size: 14px;
  padding-bottom: 70px;
`
const TitleLayout = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`

const CopyMessageContainer = styled.p`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 0.5em;
  overflow-y: scroll;
  color: #2176f6;
  margin: 20px 0px 50px 130px;
`;

const SubTitleLayout = styled.div<Article>`
  display: flex;
  flex-direction: ${prev => prev.flex};
  align-items: baseline;
  column-gap: ${prev => prev.gap}px;
`

const ExcelBtn = styled.button`
  width: 90px;
  height: 35px;
  border: 1px solid #cccccc;
  border-radius: 10px;

  &:hover,
  &:focus {
    background-color: #cccccc;
  }
`

const Title = styled.span<FontSize>`
  top: 200px;
  font-size: ${(props) => props.size}px;
  font-weight: bold;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`

const ArticleColumn = styled.div<Article>`
  display: flex;
  flex-direction: ${(props) => props.flex};
  column-gap: ${(props) => props.gap}px;
  align-self: ${(props) => props.alignSelf};
  height: 95%;
  overflow: auto;

  -ms-overflow-style: none;
    ::-webkit-scrollbar {
      display: none;
    }
`;

const ArticlSubeColumn = styled.div<Article>`
  display: flex;
  flex-direction: ${(props) => props.flex};
  column-gap: ${(props) => props.gap}px;
  height: ${props => props.height}%;
  align-self: ${(props) => props.alignSelf};
  overflow: scroll;
  
  @media (min-height: 1024px) and (max-height: 1280px) {
    height: 5%;
  }
  @media (min-height: 768px) and (max-height: 1024px) {
    height: 6%;
  }
  @media (max-height: 768px) {
    height: 9%;
  }
`;

const Grid = styled.div<Repeat>`
  width: ${(props) => `${props.width}%`};
  display: grid;
  grid-template-rows: ${(props) => `repeat(${props.rows}, 1fr)`};
  grid-template-columns: 200px 130px 130px 140px auto 150px 150px;

  div {
    padding: 10px;
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    background: #eaeaea;
    color: black;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.justify_div};
  }
  span {
    padding: 10px;
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.justify_span};
    font-size: 14px;
  }

  span:nth-child(1){
    cursor: pointer;
  }

  span:nth-child(2){
    cursor: pointer;
  }
`;
const ArticleRow = styled.div<Article>`
  margin: 0 auto;
  width: 95%;
  display: flex;
  flex-direction: ${(props) => props.flex};
  row-gap: ${(props) => props.gap}px;
`;