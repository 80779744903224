import { ProductStatus, ProductVisibilityTarget } from './productEnum';

// Product Api response type
export interface GetProductApiResponse {
    _id: string;
    orderProductCode: string;
    orderProductName: string;
    productName: string;
    productType: number;
    providerName: string;
    externalCode: string;
    deliveryPrice: number;
    retailPrice: number;
    supplyPrice: number;
    settleRate: number;
    isPurchasedProduct: boolean;
    status: number;
    visibilityTarget: string[];
    optionList: {
        optionCode: string;
        optionName: string;
        optionOrderCode: string;
        optionOrderName: string;
        optionPrice: number;
        optionSupplyPrice: number;
        _id: string;
    }[];
    timestamp: number;
    __v: number;
}

export interface GetProductsSearchApiResponse {
    products: GetProductApiResponse[];
    pagination: {
        totalCount: number;
        page: number;
        limit: number;
        totalPages: number;
    };
}
export interface ProductCreateRqData {
    orderProductCode: string;
    orderProductName: string;
    productName: string; // required
    productType: number; // required
    providerName: string; // required
    externalCode: string; // required
    deliveryPrice: number;
    retailPrice: number; // required
    supplyPrice: number; // required
    settleRate: number; // required
    isPurchasedProduct: boolean;
    status: number;
    visibilityTarget: string[];
    optionList: ProductsCreateOptionList[];
}
export interface ProductListTableProps {
    data: GetProductApiResponse[];
    totalCount: number;
    currentPage: number;
    sectionIndex: number;
    totalPages: number;
    onChangeCurrentPage: (page: number, newGroup?: number) => void;
    onChangeSectionIdx: (group: number, newPage: number) => void;
}

export enum ProductType {
    MTM = 0,
    MTE,
    MTS,
    MTC,
}

export interface ProductsCreateOptionList {
    optionCode: string;
    optionName: string;
    optionOrderCode: string;
    optionOrderName: string;
    optionPrice: number;
    optionSupplyPrice: number;
}

export namespace ProductsCreateOptionList {
    export const productTypeList = ['MTM', 'MTE', 'MTS'];
    export const purchasedProductList = ['사입', '위탁'];
    export const optionTitle = ['옵션코드', '옵션명', '발주서 옵션코드', '발주서 옵션명', '옵션금액', '옵션공급가'];
    export const ProductStatusList = ['판매중지', '판매중', '품절'];

    export const ViewProductStatus = {
        [ProductStatus.INACTIVE]: '판매중지',
        [ProductStatus.ACTIVE]: '판매중',
        [ProductStatus.SOLD_OUT]: '품절',
    };

    export const ViewProductTarget = [
        { label: '병의원', target: ProductVisibilityTarget.WESTERN_MEDICINE, selected: false },
        { label: '한의원', target: ProductVisibilityTarget.ORIENTAL_MEDICINE, selected: false },
        { label: 'B2B', target: ProductVisibilityTarget.B2B, selected: false },
        { label: '피트니스', target: ProductVisibilityTarget.FITNESS, selected: false },
    ];
}

// export interface ClientCreateRqData {
//     name: string; // required
//     code: string; // required
//     tel: string; // required
//     address: string; // required
//     addressDetail: string;
//     zipCode: string; // required
//     bank: string;
//     bankAccount: string;
//     bankHolderName: string;
//     referrerList: string[];
//     clientType: number;
//     businessNumber: string;
// }
