import { useDaumPostcodePopup } from 'react-daum-postcode';
import { useSetRecoilState } from 'recoil';
import { postCodeState } from '@recoils/postCode';
import { ColumnInputWithLabel } from '@components/input/input';

interface PostCodeProps {
    type?: string;
    inputEdit?: boolean;
    zipCode?: string;
    onHospitalInfoChange?: (e: string, type: string) => void;
}
export const PostCode = ({ type, inputEdit, zipCode, onHospitalInfoChange }: PostCodeProps) => {
    const scriptUrl = '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
    const open = useDaumPostcodePopup(scriptUrl);
    const setPostCode = useSetRecoilState(postCodeState);

    const handleComplete = (data: { address: any; addressType: string; bname: string; buildingName: string; zonecode: string }) => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }

        setPostCode({
            zipCode: data.zonecode,
            address: data.address,
            detailAddress: extraAddress,
        });
    };

    const handleClick = () => {
        open({ onComplete: handleComplete });
    };

    return type === 'zipCode' ? (
        <ColumnInputWithLabel
            label='우편번호'
            placeholder='우편번호를 검색해주세요.'
            onChange={onHospitalInfoChange}
            $width='45%'
            value={zipCode!}
            type='zipCode'
            inputEdit={inputEdit!}
            onClick={handleClick}
        />
    ) : (
        <button type='button' onClick={handleClick}>
            우편번호찾기
        </button>
    );
};
