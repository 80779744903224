import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import styled from 'styled-components';
import styles from '@styles/common/PrescriptionFileAttach.module.scss';
import iconStyles from '../../../styles/common/Icon.module.scss';
import classNames from 'classnames/bind';

interface Props {
    onFileUpload: (e: any) => void;
    onClickFileHref: (e: any) => void;
    onFileRemove: (e: any) => void;
    uploadFile: Array<File>;
}

export const AfterAttachFileTable = ({ onFileUpload, onClickFileHref, onFileRemove, uploadFile }: Props) => {
    const cx = classNames.bind(styles);
    const ix = classNames.bind(iconStyles);
    const FileRow = () => {
        return (
            <div className={cx('filesContainer-div')}>
                {uploadFile.map((item: any, index: number) => (
                    <div className={cx('filesContents-div')} key={index}>
                        <div className={cx('filesName-div')}>
                            <img src={`${process.env.PUBLIC_URL}/img/pdf-icon.png`} className={ix('custom-pdf-icon')} alt='' />
                            <a onClick={onClickFileHref} className={cx('prescriptionLink-a')}>
                                {item.name}
                            </a>
                        </div>
                        <div className={cx('cancelBtn-div')}>
                            <button value={item.name} onClick={onFileRemove}>
                                <FontAwesomeIcon icon={faClose} className={ix('custom-cancel-icon')} />
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <>
            <table>
                <thead>
                    <tr>
                        <th>
                            <div>파일첨부</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <FileRow />
                        </td>
                    </tr>
                </tbody>
            </table>
            <HiddenAttachDiv>
                <label htmlFor='uploadFiles' className={cx('upload-label')}>
                    <FontAwesomeIcon icon={faCloudArrowUp} className={ix('custom-cloud-icon')} />
                    <div>파일 첨부하기</div>
                </label>
                <input type='file' id='uploadFiles' accept='application/pdf,image/jpg,jpeg' multiple={true} onChange={onFileUpload} />
            </HiddenAttachDiv>
        </>
    );
};

const HiddenAttachDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
`;
