import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import _ from 'lodash';
import { fileState } from '@recoils/file';
import { PdfViewer } from '@components/pdfViewer';
import { IOoDiagnosis } from '@api/models';
import classNames from 'classnames/bind';
import buttonStyles from '../../../../../styles/common/Button.module.scss';
import moment from 'moment';
import axios from 'axios';
import FileSaver from 'file-saver';
import styled from 'styled-components';
import styles from '../../../../../styles/diagnosis/Prescription.module.scss';
import { toast } from 'react-toastify';
import { DoctorPrescriptionUploader } from '@utils/storage';
import { OoParamPrescriptionModify } from '@api/params';
import { useMakePdfHook } from '@hooks/common';
import { FilesReadTable } from '@components/diagnosisForm/prescriptionForm/FilesReadTable';
import { pdfUrlState } from '@recoils/common';
import { useDoctorFunctionHook } from '@hooks/doctor';

interface Props {
    data: IOoDiagnosis;
}

export const DisplayPrescription = ({ data }: Props) => {
    const cx = classNames.bind(styles);
    const bx = classNames.bind(buttonStyles);
    const setVisible = useSetRecoilState(fileState);

    const prescription = useMemo(() => {
        return _.isEmpty(data.modifiedPrescription) ? _.head(data.prescription) : _.last(data.modifiedPrescription);
    }, [data]);

    const [uploadFile, setUploadFile] = useState<Array<File>>([]);
    const [pdfUrl, setPdfUrl] = useState<string | URL>(prescription || '');
    const [pdfUrlArr, setPdfUrlArr] = useRecoilState(pdfUrlState(data.id || ''));
    const resetPdfUrlArr = useResetRecoilState(pdfUrlState(data.id || ''));

    const { fileToImageURL, generatePdfFromImages } = useMakePdfHook();

    useEffect(() => {
        resetPdfUrlArr();
    }, []);

    useEffect(() => {
        setPdfUrl(prescription || '');
    }, [prescription]);

    const { modifyTelemedicine } = useDoctorFunctionHook();

    const onClickUrl = (e: any) => {
        resetPdfUrlArr();
        const id = e.currentTarget.id;
        setVisible(true);
        setPdfUrl(id);
    };

    const makePdfUrlForFile = async (file: File) => {
        if (_.includes(file.type, 'image')) {
            try {
                const image = await fileToImageURL(file);
                const pdfBlob = generatePdfFromImages(image);
                const newFile = new File([pdfBlob], 'prescription', { type: 'application/pdf' });
                return await DoctorPrescriptionUploader.upload(newFile, data.patientId || '');
            } catch (e) {
                console.log(e, 'makePdfUrlForFile error!');
            }
            return '';
        }
        return await DoctorPrescriptionUploader.upload(file, data.patientId ?? '');
    };

    const onFileUpload = (e: any) => {
        const selectedFile: File = _.first(e.target.files)!;
        const copied = _.cloneDeep(uploadFile);
        const index = copied.findIndex((item) => _.isEqual(item.name, selectedFile.name));
        index < 0 ? copied.push(selectedFile) : (copied[index] = selectedFile);
        setUploadFile(copied);
    };

    useEffect(() => {
        if (_.isEmpty(uploadFile)) return;
        uploadFile.forEach(async (item) => {
            try {
                const url = await makePdfUrlForFile(item);
                setPdfUrl(url || '');
                setPdfUrlArr({ ...pdfUrlArr, list: [url || ''] });
            } catch (e) {
                console.log(e, 'get uploadFile url error!!!');
            }
        });
    }, [uploadFile]);

    useEffect(() => {
        if (_.isEmpty(pdfUrlArr.list)) return;
        const param = new OoParamPrescriptionModify();
        param.id = data.id || '';
        param.prescription = pdfUrlArr.list;
        modifyTelemedicine(param);
        setUploadFile([]);
    }, [pdfUrlArr]);

    const handleDownload = async (e: any) => {
        e.preventDefault();
        const date = moment().format('yyyyMMDDHHmm');
        try {
            if (typeof pdfUrl !== 'string') return;
            const response = await axios.get(pdfUrl, { responseType: 'arraybuffer' });
            FileSaver.saveAs(new Blob([response.data], { type: 'application/pdf;charset=utf-8' }), `${data.patientName}_${date}`);
        } catch (e: any) {
            toast.error(`다운로드가 실패했습니다.\n(${e})`);
        }
    };

    return (
        <>
            <PdfViewer pdfUrl={pdfUrl} />
            {!_.isUndefined(data.prescription) && _.gt(data.prescription.length, 0) ? (
                <div>
                    <Wrapper>
                        <div>처방전</div>
                        <FilesReadTable onClickUrl={onClickUrl} uploadFiles={[prescription || '']} patientName={data.patientName} checkBoxHidden={true} />
                    </Wrapper>
                    <ButtonContainer>
                        <button className={bx('button')} onClick={handleDownload}>
                            <div className='button-inside-div'>
                                <img src={process.env.PUBLIC_URL + '/img/download-icon.png'} className={cx('custom-download-icon')} alt='downloadButton' />
                                <div>다운로드</div>
                            </div>
                        </button>
                        <LabelContainer htmlFor='uploadFiles'>
                            <div>처방전 변경</div>
                        </LabelContainer>
                        <input type='file' id='uploadFiles' accept='.pdf,.jpeg,.jpg' multiple={true} hidden={true} onChange={onFileUpload} />
                    </ButtonContainer>
                </div>
            ) : (
                <EmptyFileContainer>
                    <div>처방전</div>
                    <table>
                        <thead>
                            <tr>
                                <td>파일첨부</td>
                                <td>없음</td>
                            </tr>
                        </thead>
                    </table>
                </EmptyFileContainer>
            )}
        </>
    );
};

const Wrapper = styled.div`
    margin-top: 48px;

    & > div:nth-child(1) {
        font-weight: 700;
        font-size: 16px;
        padding-bottom: 7px;
    }
`;

const ButtonContainer = styled.div`
    padding-top: 10px;
    display: flex;
    justify-content: flex-end;

    .button-inside-div {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
`;

const EmptyFileContainer = styled.div`
    margin-top: 48px;

    & > div:nth-child(1) {
        font-weight: 700;
        font-size: 16px;
        padding-bottom: 7px;
    }

    & > table {
        margin-top: 8px;
        border-top: 2px solid #c8d6f8;
        border-bottom: 1px solid #c8d6f8;
        width: 100%;
        height: 50px;

        td:first-child {
            background: #f4f6fb;
            width: 10%;
            font-weight: 700;
            text-align: center;
            font-size: 13px;
        }

        td:nth-child(2) {
            padding: 10px;
            font-size: 12px;
        }
    }
`;

const LabelContainer = styled.label`
    width: 140px;
    height: 40px;
    font-weight: 700;
    font-size: 13px;
    background: #fff;
    border: 1px solid #c2c1bd;
    border-radius: 8px;
    color: #000000;
    margin-left: 10px;
    cursor: pointer;

    & > div {
        height: 100%;
        font-weight: 700;
        font-size: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
