import { useQuery } from 'react-query';
import { auth } from '../../firebaseConfig';
import { DoctorOnAPI, isRequestSucceed, OoParamOAuth } from '../../api';
import { useNavigate } from 'react-router-dom';
import { useOuathManage, useSuspenseHook } from '../../hooks';
import { useSetRecoilState } from 'recoil';
import { loginState } from '../../recoils';

export const useOauthQuery = (email: string, password: string, login: boolean) => {
    const navigate = useNavigate();
    const { add } = useOuathManage();
    const setLogin = useSetRecoilState(loginState);

    const { setLoading, setStopLoading, setErrorStatus } = useSuspenseHook();

    return useQuery(
        'oauth',
        async () => {
            try {
                setLoading();
                const credential = await auth.signInWithEmailAndPassword(email, password);
                const idToken = await credential.user?.getIdToken();
                const param = new OoParamOAuth(idToken!);
                const response = await DoctorOnAPI.shared.auth.oauth(param);
                if (isRequestSucceed(response)) {
                    setStopLoading();
                    DoctorOnAPI.shared.setBearerToken(response.data!.bearerToken);
                    add(response.data!);

                    if (response.data?.user.id === 'Ww39YIj5JN6zgKvwwYQW' || response.data?.user.id === 'ZjIQqcyDr2DRT1nWL5hC') {
                        navigate('/home');
                    } else {
                        navigate('/unospay');
                    }
                    setLogin(false);
                }
                setLogin(false);
                return undefined;
            } catch (e: any) {
                setErrorStatus(e);
            }
        },
        {
            enabled: login,
            retry: 1,
        }
    );
};
