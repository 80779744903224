import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const usePaginationStateHooks = (filterData: any) => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    const initialPage = parseInt(queryParams.get('page') || '1');
    const initialPageGroup = parseInt(queryParams.get('pageGroup') || '0');
    const [currentPage, setCurrentPage] = useState(initialPage);
    const [pageGroup, setPageGroup] = useState(initialPageGroup);

    const itemsPerPage = 10;
    const totalPages = filterData && Math.ceil(filterData.length / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = filterData && filterData.slice(startIndex, endIndex);

    const setPage = useCallback(
        (page: number, newGroup?: number) => {
            setCurrentPage(page);

            if (newGroup) {
                queryParams.set('pageGroup', newGroup.toString());
            }

            queryParams.set('page', page.toString());
            navigate({ search: queryParams.toString() });
        },
        [location.search, navigate, pageGroup]
    );

    const handlePageGroup = useCallback(
        (group: number, newPage: number) => {
            setPageGroup(group);

            queryParams.set('pageGroup', group.toString());
            queryParams.set('page', newPage.toString());
            setCurrentPage(newPage);
            navigate({ search: queryParams.toString() });
        },
        [location.search, navigate, currentPage]
    );

    useEffect(() => {
        const page = parseInt(queryParams.get('page') || '1');
        const pageGroup = parseInt(queryParams.get('pageGroup') || '0');
        setCurrentPage(page);
        setPageGroup(pageGroup);
    }, [queryParams]);

    return { totalPages, setPage, currentPage, pageGroup, handlePageGroup, currentData } as const;
};

export default usePaginationStateHooks;
