import _ from 'lodash';
import { NavDropdown } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useNavbarHooks } from '../hooks/useNavbarHooks';
import { HiOutlineMenu } from '@react-icons/all-files/hi/HiOutlineMenu';

const Logo = () => {
    return (
        <svg width='184' height='38' viewBox='0 0 184 38' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M40.8718 0.0101546C39.4329 -0.0658301 38.0823 0.288765 36.9211 0.934636C35.2172 1.89711 33.1345 1.88444 31.4432 0.934636C30.3703 0.352086 29.1586 0.0101546 27.8586 0.0101546C25.4226 0.0101546 23.2768 1.20058 21.9389 3.02422C20.9418 4.39194 19.3514 5.17712 17.6727 5.17712C15.9309 5.17712 14.2395 4.39194 13.2045 2.98622C11.753 1.02328 9.34223 -0.192471 6.67901 0.0354829C3.09438 0.339422 0.229207 3.29016 0.0146342 6.88677C-0.250426 11.1546 3.11963 14.7005 7.32272 14.7005C9.69564 14.7005 11.8035 13.5734 13.1414 11.8131C14.2269 10.3947 15.8804 9.52091 17.6727 9.52091C19.364 9.52091 20.9544 10.3188 21.9515 11.6865C23.2768 13.5101 25.4352 14.7005 27.8586 14.7005C29.1586 14.7005 30.3703 14.3586 31.4306 13.7634C33.1345 12.8009 35.2172 12.8009 36.9211 13.7634C37.9814 14.3586 39.1931 14.7005 40.4931 14.7005C44.6962 14.7005 48.0663 11.1546 47.8012 6.88677C47.574 3.18885 44.5574 0.200116 40.8718 0.0228187V0.0101546Z'
                fill='#41667C'
            />
            <path d='M5.46529 21.7801H0V18.5508H14.8812V21.7801H9.41595V35.6853H5.46529V21.7801Z' fill='#231916' />
            <path d='M17.4944 18.5508H21.445V32.456H30.0153V35.6853H17.4944V18.5508Z' fill='#231916' />
            <path
                d='M31.3531 27.1227C31.3531 21.9557 35.3038 18.2578 40.6302 18.2578C43.5838 18.2578 46.045 19.3343 47.6606 21.2972L45.1236 23.6527C43.975 22.3357 42.5361 21.6518 40.8322 21.6518C37.6388 21.6518 35.3669 23.906 35.3669 27.1354C35.3669 30.3647 37.6388 32.6189 40.8322 32.6189C42.5361 32.6189 43.975 31.9351 45.1236 30.58L47.6606 32.9355C46.045 34.9238 43.5838 36.0002 40.605 36.0002C35.3038 36.0002 31.3531 32.3023 31.3531 27.1354V27.1227Z'
                fill='#231916'
            />
            <path d='M69.1867 19.2461V35.5575H68.0002V27.7944H57.1328V35.5575H55.9463V19.2461H57.1328V26.7433H68.0002V19.2461H69.1867Z' fill='#231916' />
            <path
                d='M85.1909 29.7429H74.7652C74.7905 30.6927 75.0303 31.5286 75.4847 32.2757C75.9391 33.0103 76.5449 33.5801 77.3022 33.9854C78.0722 34.3906 78.9305 34.5933 79.8897 34.5933C80.6849 34.5933 81.417 34.454 82.0859 34.1627C82.7549 33.8714 83.3229 33.4535 83.7646 32.8963L84.4336 33.6688C83.9035 34.302 83.2471 34.7959 82.452 35.1378C81.6568 35.4798 80.7859 35.6444 79.8645 35.6444C78.6528 35.6444 77.5799 35.3785 76.6333 34.8592C75.6866 34.34 74.9545 33.6055 74.4118 32.6683C73.8817 31.7312 73.604 30.6674 73.604 29.477C73.604 28.2865 73.8564 27.2354 74.3613 26.2983C74.8662 25.3611 75.5604 24.6266 76.4439 24.0947C77.3275 23.5628 78.312 23.2969 79.4101 23.2969C80.5082 23.2969 81.4927 23.5628 82.3762 24.0821C83.2472 24.6013 83.9414 25.3231 84.4462 26.2603C84.9511 27.1974 85.2035 28.2485 85.2035 29.4263L85.1783 29.7556L85.1909 29.7429ZM77.1381 24.8799C76.4566 25.2598 75.9138 25.7917 75.4973 26.4882C75.0808 27.1848 74.8536 27.9573 74.7905 28.8311H84.0802C84.0297 27.9573 83.8025 27.1848 83.386 26.5009C82.9695 25.817 82.4141 25.2851 81.7325 24.8926C81.0509 24.5 80.281 24.31 79.4353 24.31C78.5897 24.31 77.8197 24.5 77.1381 24.8799Z'
                fill='#231916'
            />
            <path
                d='M96.4757 24.4503C97.2835 25.2228 97.6874 26.3499 97.6874 27.8443V35.5568H96.5766V33.3912C96.1854 34.1004 95.63 34.6576 94.8853 35.0629C94.1406 35.4681 93.2571 35.6581 92.2095 35.6581C90.8589 35.6581 89.7987 35.3415 89.0161 34.7083C88.2336 34.0751 87.8423 33.2266 87.8423 32.1881C87.8423 31.1496 88.2083 30.3645 88.9278 29.7313C89.6472 29.0981 90.7958 28.7814 92.3735 28.7814H96.5262V27.8063C96.5262 26.6665 96.2232 25.8054 95.6048 25.2228C94.9989 24.6276 94.1028 24.3363 92.9289 24.3363C92.1211 24.3363 91.3512 24.4756 90.6191 24.7543C89.887 25.0329 89.2559 25.4128 88.7511 25.8687L88.1704 25.0329C88.7763 24.4883 89.4958 24.0704 90.354 23.7665C91.1997 23.4625 92.0959 23.3105 93.0299 23.3105C94.5319 23.3105 95.6805 23.6905 96.4883 24.463L96.4757 24.4503ZM94.9358 33.9991C95.6426 33.5305 96.1727 32.8593 96.5262 31.9728V29.6933H92.3862C91.1997 29.6933 90.3288 29.9086 89.7987 30.3391C89.2686 30.7697 88.9909 31.3776 88.9909 32.1374C88.9909 32.8973 89.2812 33.5558 89.8744 34.0117C90.4676 34.4676 91.2881 34.6956 92.3609 34.6956C93.3707 34.6956 94.2164 34.4676 94.9232 33.9991H94.9358Z'
                fill='#231916'
            />
            <path d='M102.925 18.2578H104.086V35.5443H102.925V18.2578Z' fill='#231916' />
            <path
                d='M115.308 34.7847C115.017 35.0634 114.651 35.2786 114.21 35.4306C113.768 35.5826 113.313 35.6459 112.834 35.6459C111.786 35.6459 110.966 35.3546 110.398 34.7721C109.83 34.1895 109.539 33.379 109.539 32.3406V24.4002H107.305V23.3997H109.539V20.7402H110.701V23.3997H114.55V24.4002H110.701V32.2266C110.701 33.0118 110.89 33.6196 111.269 34.0376C111.647 34.4555 112.215 34.6581 112.947 34.6581C113.679 34.6581 114.336 34.4302 114.828 33.9869L115.32 34.7974L115.308 34.7847Z'
                fill='#231916'
            />
            <path
                d='M128.523 24.6279C129.406 25.5144 129.848 26.7934 129.848 28.4524V35.557H128.687V28.5411C128.687 27.1734 128.346 26.1349 127.652 25.413C126.957 24.6912 125.998 24.3366 124.761 24.3366C123.335 24.3366 122.212 24.7672 121.391 25.6157C120.571 26.4642 120.154 27.6166 120.154 29.0603V35.5443H118.993V18.2578H120.154V25.9196C120.571 25.0838 121.189 24.4379 122.01 23.9693C122.83 23.5134 123.802 23.2855 124.913 23.2855C126.427 23.2855 127.626 23.7287 128.51 24.6152L128.523 24.6279Z'
                fill='#231916'
            />
            <path
                d='M136.764 34.8475C135.83 34.3156 135.11 33.5811 134.58 32.6439C134.05 31.7068 133.785 30.643 133.785 29.4652C133.785 28.2875 134.05 27.1984 134.58 26.2612C135.11 25.3241 135.83 24.5896 136.764 24.0703C137.698 23.5511 138.746 23.2852 139.919 23.2852C140.891 23.2852 141.775 23.4751 142.583 23.855C143.378 24.235 144.022 24.7922 144.526 25.5267L143.668 26.1599C143.239 25.552 142.696 25.0961 142.053 24.7922C141.409 24.4882 140.702 24.3363 139.932 24.3363C138.985 24.3363 138.14 24.5516 137.395 24.9821C136.638 25.4127 136.057 26.0079 135.628 26.7931C135.199 27.5656 134.984 28.4648 134.984 29.4779C134.984 30.491 135.199 31.3775 135.628 32.1627C136.057 32.9352 136.638 33.5431 137.395 33.9737C138.14 34.4043 138.998 34.6195 139.932 34.6195C140.702 34.6195 141.409 34.4676 142.053 34.1636C142.696 33.8597 143.239 33.4038 143.668 32.7959L144.526 33.4291C144.034 34.1636 143.39 34.7082 142.583 35.1008C141.787 35.4807 140.904 35.6707 139.919 35.6707C138.746 35.6707 137.685 35.4047 136.764 34.8855V34.8475Z'
                fill='#231916'
            />
            <path
                d='M156.025 24.4503C156.833 25.2228 157.237 26.3499 157.237 27.8443V35.5568H156.126V33.3912C155.735 34.1004 155.18 34.6576 154.435 35.0629C153.69 35.4681 152.807 35.6581 151.759 35.6581C150.409 35.6581 149.348 35.3415 148.566 34.7083C147.783 34.0751 147.392 33.2266 147.392 32.1881C147.392 31.1496 147.758 30.3645 148.478 29.7313C149.197 29.0981 150.346 28.7814 151.923 28.7814H156.076V27.8063C156.076 26.6665 155.773 25.8054 155.155 25.2228C154.549 24.6276 153.653 24.3363 152.479 24.3363C151.671 24.3363 150.901 24.4756 150.169 24.7543C149.437 25.0329 148.806 25.4128 148.301 25.8687L147.72 25.0329C148.326 24.4883 149.046 24.0704 149.904 23.7665C150.75 23.4625 151.646 23.3105 152.58 23.3105C154.082 23.3105 155.23 23.6905 156.038 24.463L156.025 24.4503ZM154.486 33.9991C155.192 33.5305 155.723 32.8593 156.076 31.9728V29.6933H151.936C150.75 29.6933 149.879 29.9086 149.348 30.3391C148.818 30.7697 148.541 31.3776 148.541 32.1374C148.541 32.8973 148.831 33.5558 149.424 34.0117C150.017 34.4676 150.838 34.6956 151.911 34.6956C152.92 34.6956 153.766 34.4676 154.473 33.9991H154.486Z'
                fill='#231916'
            />
            <path
                d='M165.328 24.0061C166.123 23.5375 167.082 23.2969 168.206 23.2969V24.4366L167.928 24.4113C166.577 24.4113 165.53 24.8292 164.773 25.6777C164.015 26.5262 163.637 27.704 163.637 29.211V35.5431H162.475V23.3855H163.586V26.045C163.952 25.1585 164.533 24.4746 165.328 24.0061Z'
                fill='#231916'
            />
            <path
                d='M182.014 29.7429H171.588C171.614 30.6927 171.854 31.5286 172.308 32.2757C172.762 33.0103 173.368 33.5801 174.125 33.9854C174.895 34.3906 175.754 34.5933 176.713 34.5933C177.508 34.5933 178.24 34.454 178.909 34.1627C179.578 33.8714 180.146 33.4535 180.588 32.8963L181.257 33.6688C180.727 34.302 180.07 34.7959 179.275 35.1378C178.48 35.4798 177.609 35.6444 176.688 35.6444C175.476 35.6444 174.403 35.3785 173.457 34.8592C172.51 34.34 171.778 33.6055 171.235 32.6683C170.705 31.7312 170.427 30.6674 170.427 29.477C170.427 28.2865 170.68 27.2354 171.185 26.2983C171.689 25.3611 172.384 24.6266 173.267 24.0947C174.151 23.5628 175.135 23.2969 176.233 23.2969C177.331 23.2969 178.316 23.5628 179.199 24.0821C180.07 24.6013 180.765 25.3231 181.269 26.2603C181.774 27.1974 182.027 28.2485 182.027 29.4263L182.002 29.7556L182.014 29.7429ZM173.961 24.8799C173.28 25.2598 172.737 25.7917 172.321 26.4882C171.904 27.1848 171.677 27.9573 171.614 28.8311H180.903C180.853 27.9573 180.626 27.1848 180.209 26.5009C179.793 25.817 179.237 25.2851 178.556 24.8926C177.874 24.5 177.104 24.31 176.259 24.31C175.413 24.31 174.643 24.5 173.961 24.8799Z'
                fill='#231916'
            />
        </svg>
    );
};

export const CalculaterNavbar = () => {
    const { cx, oauthList, selectedUser, navigate, onClickLogout, GetUserList } = useNavbarHooks();
    const { pathname } = useLocation();
    const [splitPathName, setSplitPathName] = useState<string[]>([]);

    useEffect(() => {
        const splitPathNameResult = pathname.split('/');
        setSplitPathName(splitPathNameResult);
    }, [pathname]);

    return (
        <Wrapper>
            <LogoButton type='button' className='navi-logo-container' onClick={() => navigate('/home')}>
                <Logo />
            </LogoButton>
            <NavLayout>
                <NavLink to={`/home`} style={splitPathName[1] === 'calculateMonthDetail' || splitPathName[1] === 'home' ? navActive : navNotActive}>
                    정산
                </NavLink>
                <NavLink to={`/notices`} style={splitPathName[1] === 'notices' ? navActive : navNotActive}>
                    공지사항
                </NavLink>
                <NavLayout>
                    <NavLink to={`/productPriceList/mtm`} style={splitPathName[1] === 'productPriceList' ? navActive : navNotActive}>
                        제품가격표
                    </NavLink>
                    <SubNav>
                        <NavLink to={`/productPriceList/mtm`} style={({ isActive }) => (isActive ? subNavActive : subNavNotActive)}>
                            맞춤식단(MTM)
                        </NavLink>
                        <NavLink to={`/productPriceList/mts`} style={({ isActive }) => (isActive ? subNavActive : subNavNotActive)}>
                            건강기능식품(MTS)
                        </NavLink>
                        <NavLink to={`/productPriceList/mte`} style={({ isActive }) => (isActive ? subNavActive : subNavNotActive)}>
                            운동용품(MTE)
                        </NavLink>
                    </SubNav>
                </NavLayout>
                {/* <NavLink to={`/qrSubmit`} style={({ isActive }) => (isActive ? navActive : navNotActive)}>
          QR코드
        </NavLink> */}
            </NavLayout>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 15%;
    min-width: 280px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-right: 1px solid #d1d7dd;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
`;

const LogoButton = styled.button`
    border: none;
    background: none;
    padding: 25px 25px 30px 25px;
`;
const NavLayout = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    a {
        display: block;
        text-decoration: none;
    }
`;

const SubNav = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px 20px;

    a {
        padding: 10px 15px;
        text-decoration: none;
    }
`;

const navActive = {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    textDecoration: 'none',
    backgroundColor: '#e7f3fe',
    color: '#2176F6',
    fontWeight: '700',
    fontSize: '16px',
    padding: '18px 20px',
    borderLeft: '3px solid #2176F6',
};
const subNavActive = {
    color: '#2176F6',
    fontWeight: '700',
};

const navNotActive = {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    color: '#2B4E72',
    textDecoration: 'none',
    fontWeight: '700',
    fontSize: '16px',
    padding: '18px 20px',
    borderBottom: 'none',
    borderLeft: '3px solid #e7f3fe',
};
const subNavNotActive = {
    color: '#2B4E72',
};

// const Wrapper = styled.div`
//   width: 30%;
//   height: 80px;
//   background: #ffffff;
//   border-bottom: 2px solid #d2d2d2;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   padding: 0px 50px;
//   position: fixed;

//   .left-menu-container {
//     display: flex;
//     justify-content: center;
//   }

//   .left-menu-middle-container {
//     display: flex;
//     flex-direction: row;
//     column-gap: 15px;
//     justify-content: space-between;
//     align-items: center;
//   }

//   .right-menu-container {
//     display: flex;
//     align-items: center;
//   }

//   .navi-logo-container {
//     width: 20%;
//     display: flex;
//     justify-content: flex-start;
//     border: none;
//     background: none;
//   }

//   .navi-links-container {
//     height: 100%;
//     display: flex;
//     align-items: center;

//     & > Link {
//       text-decoration: none;
//       color: #2b4e72;
//       font-weight: 700;
//       font-size: 16px;
//     }
//   }

//   .nav-admin-container {
//     position: absolute;
//     right: 50px;
//     display: flex;
//     align-items: center;

//     & div > a {
//       font-weight: 700;
//       font-size: 16px;
//       color: #949390 !important;
//     }import { textAlign } from '@mui/system';

//     & > input {
//       margin-left: 20px;
//     }
//   }

//   .nav-logout-button {
//     border: none;
//     background: #ffffff;
//     color: #2b4e72;
//     font-weight: 700;
//     font-size: 16px;
//   }

//   .navi-menubar-container {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 100%;
//     right: 50px;
//     position: absolute;

//     & > button {
//       background: none;
//       border: none;
//     }

//   }

//   @media (min-width: 1024px) {
//     .navi-menubar-container {
//       display: none;
//     }
//   }

//   @media (max-width: 1024px) {
//     .navi-links-container {
//       display: none;
//     }

//     .nav-admin-container {
//       display: none;
//     }
//   }
// `;
