import { PASSWORD_REGEX, SPACE_REGEX } from '@utils/regex';
import { commonErrText } from 'constants/commonConstants';
import { InputValidateObject } from 'models/common/inputTypes';

// 유효성 검사
export const validateCheck: InputValidateObject = {
    password: (password, passwordConfirmation, setErrMsg) => {
        if (passwordConfirmation && password && passwordConfirmation === password && PASSWORD_REGEX.test(password)) {
            setErrMsg && setErrMsg('');
        } else if (passwordConfirmation && password && passwordConfirmation !== password && PASSWORD_REGEX.test(password)) {
            setErrMsg && setErrMsg('비밀번호가 일치하지 않습니다.');
        }

        if (SPACE_REGEX.test(password)) {
            return '공백은 사용할 수 없습니다.';
        } else if (PASSWORD_REGEX.test(password)) {
            return '';
        } else if (password && !PASSWORD_REGEX.test(password)) {
            return commonErrText.password;
        } else {
            return commonErrText.required;
        }
    },
    confirmPassword: (passwordConfirmation, password) => {
        if (password && passwordConfirmation && password === passwordConfirmation) {
            return '';
        } else if (password && passwordConfirmation && password !== passwordConfirmation) {
            return '비밀번호가 일치하지 않습니다.';
        } else {
            return '확인을 위해 비밀번호를 한 번 더 입력해주세요.';
        }
    },
};
