import { GetClientsApiResponse } from '@api/models/client/client';
import { isRequestSucceed } from '@api/reponses';
import { DoctorOnAPI } from '@api/request';
import ConfirmModal from '@components/modal/basicModal/ConfirmModal';
import useToggle from '@hooks/common/useToggle';
import { useSuspenseHook } from '@hooks/suspense';
import { validateCheck } from 'functions/validationFunctions';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import ClientInfo from './ClientInfo';
import AdminInfo from './AdminInfo';
import { useRecoilState } from 'recoil';
import { postCodeState } from '@recoils/postCode';

const AddClientPage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const [postCode, setPostCode] = useRecoilState(postCodeState);

    const [initData, setInitData] = useState<GetClientsApiResponse>();
    const [name, setName] = useState<string>('');
    const [code, setCode] = useState<string>('');
    const [tel, setTel] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [addressDetail, setAddressDetail] = useState<string>('');
    const [zipCode, setZipCode] = useState<string>('');
    const [bank, setBank] = useState<string>('');
    const [bankAccount, setBankAccount] = useState<string>('');
    const [bankHolderName, setBankHolderName] = useState<string>('');
    const [referrerList, setReferrerList] = useState<string[]>(['']);
    const [clientType, setClientType] = useState<number>(0);
    const [onDropdown, setOnDropdown] = useState<boolean>(false);
    const [onSMSDropdown, setOnSMSDropdown] = useState<boolean>(false);
    const [inputEdit, setInputEdit] = useState<boolean>(true);
    const [isUserInfoEdit, setIsUserInfoEdit] = useState<boolean>(false);
    const [businessNumber, setBusinessNumber] = useState<string>('');
    const [email, setEmail] = useState<string>(`${code}@doctoron.kr`);
    // const [mobile, setMobile] = useState<string>(initData?.tel || '');
    const [alimTalkReceivers, setAlimTalkReceivers] = useState<string[]>(['']);
    const [password, setPassword] = useState<string>('');
    const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
    const [acceptSMS, setAcceptSMS] = useState<number>(0);
    const [passwordErr, setPasswordErr] = useState<string>('');
    const [passwordConfirmationErr, setPasswordConfirmationErr] = useState<string>('');
    const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

    const { setLoading, setStopLoading, setErrorStatus } = useSuspenseHook();
    const [isAdminLeaveModal, setIsAdminLeaveModal] = useToggle();
    const [isClientLeaveModal, setIsClientLeaveModal] = useToggle();

    useEffect(() => {
        return () => {
            setPostCode({
                zipCode: '',
                address: '',
                detailAddress: '',
            });
        };
    }, []);

    useEffect(() => {
        if (state && state.id) {
            setInputEdit(false);
            clientListSearchApi();
        } else {
            setInputEdit(true);
        }
    }, [state]);

    useEffect(() => {
        setZipCode(postCode.zipCode);
        setAddress(postCode.address);
        setAddressDetail(postCode.detailAddress || '');
    }, [postCode]);

    useEffect(() => {
        setInitFunc();
    }, [initData]);

    // 특정 client 정보 가져오기
    const clientListSearchApi = async () => {
        try {
            const response = await DoctorOnAPI.shared.smsHospital.clientFindById(state.id);

            if (isRequestSucceed(response)) {
                console.log(response.data);
                setInitData(response.data);
                setEmail(`${response.data?.code}@doctoron.kr`.toLowerCase());
            }
            return response;
        } catch (e: any) {
            console.log(e);
            throw new Error(e);
        }
    };

    // 클라이언트 등록 api
    const createClientApi = async () => {
        setLoading();
        try {
            const regionKeywordList = address.split(' ').slice(0, 2);

            const response = await DoctorOnAPI.shared.smsHospital.createClient({
                name: name, // required
                // code: code, // required
                tel: tel, // required
                address: address, // required
                addressDetail: addressDetail,
                zipCode: zipCode, // required
                bank: bank,
                bankAccount: bankAccount,
                bankHolderName: bankHolderName,
                referrerList: referrerList,
                clientType: clientType,
                businessNumber: businessNumber,
                regionKeywordList: regionKeywordList,
            });

            if (isRequestSucceed(response)) {
                toast.success('클라이언트가 등록되었습니다.');
                navigate('/clientList');
                setStopLoading();
            }

            return response;
        } catch (e: any) {
            setStopLoading();
            throw new Error(e);
        }
    };

    // 클라이언트 수정 api
    const updateClientApi = async () => {
        setLoading();
        try {
            const regionKeywordList = address.split(' ').slice(0, 2);

            const response = await DoctorOnAPI.shared.smsHospital.updateClient(state.id, {
                name: name, // required
                // code: code, // required
                tel: tel, // required
                address: address, // required
                addressDetail: addressDetail,
                zipCode: zipCode, // required
                bank: bank,
                bankAccount: bankAccount,
                bankHolderName: bankHolderName,
                referrerList: referrerList,
                clientType: clientType,
                businessNumber: businessNumber,
                regionKeywordList: regionKeywordList,
            });

            if (isRequestSucceed(response)) {
                toast.success('클라이언트가 수정되었습니다.');
                setInitData(response.data);
                setInputEdit(false);
                setStopLoading();
            }

            return response;
        } catch (e: any) {
            setStopLoading();
            throw new Error(e);
        }
    };

    // 계정 기본 정보 등록 api
    const createAdminApi = async () => {
        setLoading();
        try {
            const response = await DoctorOnAPI.shared.smsHospital.createAdmin({
                clientId: state.id,
                email: email,
                mobile: tel,
                orderAlimtalkEnabled: acceptSMS === 0 ? true : false,
                alimTalkReceivers: alimTalkReceivers,
                password: password,
            });

            if (isRequestSucceed(response)) {
                toast.success('계정 기본 정보가 등록되었습니다.');
                console.log(response, 'resopnse');
                setInitData(response.data);
                setIsUserInfoEdit(false);
                setStopLoading();
            }

            return response;
        } catch (e: any) {
            setStopLoading();
            throw new Error(e);
        }
    };

    // 계정 기본 정보 update api
    const updateAdminApi = async () => {
        setLoading();
        try {
            const response = await DoctorOnAPI.shared.smsHospital.updateAdmin(state.id, {
                mobile: tel,
                orderAlimtalkEnabled: acceptSMS === 0 ? true : false,
                alimTalkReceivers: alimTalkReceivers,
            });

            if (isRequestSucceed(response)) {
                toast.success('계정 기본 정보가 수정되었습니다.');
                setInitData(response.data);
                setIsUserInfoEdit(false);
                setStopLoading();
            }

            return response;
        } catch (e: any) {
            setStopLoading();
            throw new Error(e);
        }
    };
    // client 삭제 update api
    const deleteClientApi = async () => {
        setLoading();
        try {
            const response = await DoctorOnAPI.shared.smsHospital.deleteClient(state.id);

            if (isRequestSucceed(response)) {
                toast.success('클라이언트가 삭제되었습니다.');
                setIsUserInfoEdit(false);
                setStopLoading();
                setIsClientLeaveModal();
                navigate('/clientList', { replace: true });
            }

            return response;
        } catch (e: any) {
            setStopLoading();
            throw new Error(e);
        }
    };
    // client admin 삭제 update api
    const deleteAdminApi = async () => {
        setLoading();
        try {
            const response = await DoctorOnAPI.shared.smsHospital.deleteAdmin(state.id);

            if (isRequestSucceed(response)) {
                toast.success('계정 기본 정보가 삭제되었습니다.');
                setInitData(response.data);
                setIsUserInfoEdit(false);
                setStopLoading();
                setIsAdminLeaveModal();
            }

            return response;
        } catch (e: any) {
            setStopLoading();
            throw new Error(e);
        }
    };

    const onHospitalInfoChange = (e: string, type: string, receiverIndex?: number) => {
        if (inputEdit) {
            switch (type) {
                case 'name':
                    setName(e);
                    break;
                // case 'code':
                //     setCode(e);
                //     break;
                case 'tel':
                    if (/^[0-9-]{0,}$/g.test(e)) setTel(e);
                    break;
                case 'address':
                    setAddress(e);
                    break;
                case 'addressDetail':
                    setAddressDetail(e);
                    break;
                case 'zipCode':
                    if (/^[0-9]{0,}$/g.test(e)) setZipCode(e);
                    break;
                case 'bank':
                    setBank(e);
                    break;
                case 'bankAccount':
                    if (/^[0-9-]{0,}$/g.test(e)) setBankAccount(e);
                    break;
                case 'bankHolderName':
                    setBankHolderName(e);
                    break;
                case 'businessNumber':
                    setBusinessNumber(e);
                    break;
                case 'referrerList':
                    setReferrerList([e]);
                    break;
                default:
                    break;
            }
            // 계정 기본 정보 등록 및 수정 상태일 경우// isUserInfoEdit은 계정 기본 정보 수정 상태이나, password 경우는 등록하기 시에는 노출 안 함.
        } else if (isUserInfoEdit) {
            switch (type) {
                case 'passwordConfirmation':
                    if (validateCheck.confirmPassword(e, password)) {
                        setPasswordConfirmationErr(validateCheck.confirmPassword(e, password));
                    } else {
                        setPasswordConfirmationErr('');
                    }
                    setPasswordConfirmation(e);
                    break;
                case 'password':
                    if (validateCheck.password(e, passwordConfirmation, setPasswordConfirmationErr)) {
                        setPasswordErr(validateCheck.password(e, passwordConfirmation, setPasswordConfirmation));
                    } else {
                        setPasswordErr('');
                    }
                    setPassword(e);
                    break;
                case 'alimTalkReceivers':
                    if (/^[0-9-]{0,}$/g.test(e)) {
                        const newReceivers = [...alimTalkReceivers];
                        newReceivers[receiverIndex!] = e;
                        setAlimTalkReceivers(newReceivers);
                    }
                    break;
                default:
                    break;
            }
        }
    };

    // input에 초기값 설정
    const setInitFunc = () => {
        if (initData && initData !== null) {
            setName(initData.name);
            setCode(initData.code.toLowerCase());
            setTel(initData.tel);
            setAddress(initData.address);
            setAddressDetail(initData.addressDetail || '');
            setZipCode(initData.zipCode);
            setBank(initData.bank);
            setBankAccount(initData.bankAccount);
            setBankHolderName(initData.bankHolderName);
            setReferrerList(initData.referrerList);
            setClientType(initData.clientType);
            setBusinessNumber(initData.businessNumber || '');
            setAlimTalkReceivers(initData.adminAccount?.alimTalkReceivers || ['']);
            // setMobile(initData.adminAccount?.mobile ?? '');
            setPassword('');
            setPasswordConfirmation('');
            setPasswordVisible(false);
            if (initData.adminAccount) {
                if (initData.adminAccount.orderAlimtalkEnabled === true) setAcceptSMS(0);
                else setAcceptSMS(1);
            } else {
                setAcceptSMS(0);
            }
        }
    };

    const handleDropdown = (idx: number, type?: string) => {
        if (type === 'clientType') {
            setClientType(idx);
        } else if (type === 'acceptSMS') {
            setAcceptSMS(idx);
        }
    };

    // 등록 화면에서 취소하기 버튼
    const clickCancelButton = () => {
        navigate('/clientList', { replace: true });
    };

    const clickEditButton = (type: string) => {
        if (type === 'cancel') {
            setInitFunc();
            setInputEdit(false);
        } else if (type === 'delete') {
            setIsClientLeaveModal();
        } else {
            updateClientApi();
        }
    };

    const clickUserInfoEditButton = (type: string) => {
        if (type === 'cancel') {
            if (!initData?.adminAccount) {
                setAcceptSMS(0);
                setAlimTalkReceivers(['']);
            } else {
                setAcceptSMS(initData.adminAccount.orderAlimtalkEnabled ? 0 : 1);
                setAlimTalkReceivers(initData.adminAccount.alimTalkReceivers);
            }
            setPassword('');
            setPasswordConfirmation('');
            setPasswordErr('');
            setPasswordConfirmationErr('');
            setIsUserInfoEdit(false);
        } else if (type === 'delete') {
            setIsAdminLeaveModal();
        } else if (type === 'create') {
            createAdminApi();
        } else if (type === 'update') {
            updateAdminApi();
        } else {
            setIsUserInfoEdit(true);
        }
    };
    return (
        <Layout>
            {isAdminLeaveModal && (
                <ConfirmModal
                    title='계정 기본 정보를 삭제하시겠습니까?'
                    content='계정 기본 정보가 완전히 삭제됩니다.'
                    firstButton='취소하기'
                    secondButton='삭제하기'
                    setIsLeaveModal={setIsAdminLeaveModal}
                    clickDeleteButtonInModal={deleteAdminApi}
                />
            )}
            {isClientLeaveModal && (
                <ConfirmModal
                    title='클라이언트를 삭제하시겠습니까?'
                    content='클라이언트 정보가 완전히 삭제됩니다.'
                    firstButton='취소하기'
                    secondButton='삭제하기'
                    setIsLeaveModal={setIsClientLeaveModal}
                    clickDeleteButtonInModal={deleteClientApi}
                />
            )}
            <Container>
                <ClientInfo
                    onHospitalInfoChange={onHospitalInfoChange}
                    name={name}
                    inputEdit={inputEdit}
                    setInputEdit={setInputEdit}
                    code={code}
                    tel={tel}
                    zipCode={zipCode}
                    address={address}
                    addressDetail={addressDetail}
                    bank={bank}
                    bankAccount={bankAccount}
                    bankHolderName={bankHolderName}
                    referrerList={referrerList}
                    clientType={clientType}
                    handleDropdown={handleDropdown}
                    onDropdown={onDropdown}
                    setOnDropdown={setOnDropdown}
                    state={state}
                    clickCancelButton={clickCancelButton}
                    createClientApi={createClientApi}
                    clickEditButton={clickEditButton}
                    businessNumber={businessNumber}
                />
            </Container>
            {state && (
                <Container>
                    <AdminInfo
                        initData={initData}
                        isUserInfoEdit={isUserInfoEdit}
                        onHospitalInfoChange={onHospitalInfoChange}
                        email={email}
                        password={password}
                        setPasswordVisible={setPasswordVisible}
                        passwordVisible={passwordVisible}
                        passwordErr={passwordErr}
                        passwordConfirmation={passwordConfirmation}
                        passwordConfirmationErr={passwordConfirmationErr}
                        acceptSMS={acceptSMS}
                        handleDropdown={handleDropdown}
                        onSMSDropdown={onSMSDropdown}
                        setOnSMSDropdown={setOnSMSDropdown}
                        alimTalkReceivers={alimTalkReceivers}
                        setAlimTalkReceivers={setAlimTalkReceivers}
                        clickUserInfoEditButton={clickUserInfoEditButton}
                    />
                </Container>
            )}
        </Layout>
    );
};

export default AddClientPage;

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 1024px;
    height: auto;
    border: 1px solid #c4c4c4;
    background-color: #ffffff;
    padding: 40px;
`;

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1024px;
    height: auto;
    gap: 25px;
`;
