import React from 'react';

const PrevSectionJumpIcon = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M11.495 7.49496C11.7684 7.2216 11.7684 6.77838 11.495 6.50501C11.2217 6.23165 10.7784 6.23165 10.5051 6.50501L5.50507 11.505C5.23171 11.7784 5.23171 12.2216 5.50507 12.495L10.5051 17.495C10.7784 17.7683 11.2217 17.7683 11.495 17.495C11.7684 17.2216 11.7684 16.7784 11.495 16.505L6.99 12L11.495 7.49496ZM17.495 7.49496C17.7684 7.2216 17.7684 6.77838 17.495 6.50501C17.2217 6.23165 16.7784 6.23165 16.5051 6.50501L11.5051 11.505C11.2317 11.7784 11.2317 12.2216 11.5051 12.495L16.5051 17.495C16.7784 17.7683 17.2217 17.7683 17.495 17.495C17.7684 17.2216 17.7684 16.7784 17.495 16.505L12.99 12L17.495 7.49496Z'
                fill='#989BA2'
            />
        </svg>
    );
};

export default PrevSectionJumpIcon;
