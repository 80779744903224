import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ListTitle } from '@components/listTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import _, { last } from 'lodash';
import { useRecoilValue } from 'recoil';
import { selectedUserState } from '@recoils/oauth';
import 'react-calendar/dist/Calendar.css';
import { DoctorOnAPI } from '@api/request';
import { ListSubTitle } from '../ListPage/components';
import { isRequestSucceed } from '@api/reponses';
import { GetClientsApiResponse } from '@api/models/client/client';
import SearchInput from '@components/searchBar/SearchInput';
import ClientListTable from './ClientListTable';
import BasicButton from '@components/editButton/BasicButton';
import { DropdownWithLabel } from '@components/dropdown/clientTypeDropdown';
import { filterClientTypeList } from 'models/clients/addClientPageTypes';
import usePaginationStateHooks from '@hooks/common/usePaginationStateHooks';

const ClientListPage = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [target, setTarget] = useState<string>('');
    const [searchValue, setSearchValue] = useState<string>('');
    const [totalPages, setTotalPages] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [hospitalData, setHospitalData] = useState<GetClientsApiResponse[]>();
    const [clientType, setClientType] = useState<number>(0);
    const [onDropdown, setOnDropdown] = useState<boolean>(false);

    const { setPage, currentPage, pageGroup, handlePageGroup } = usePaginationStateHooks(hospitalData);

    const selectedUser = useRecoilValue(selectedUserState);

    useEffect(() => {
        clientListSearchApi(currentPage, 10, searchValue);
    }, [currentPage, clientType]);

    useEffect(() => {
        const path = last(pathname.split('/') || '') || '';
        setTarget(path);
    }, [pathname]);

    // client list search api func
    const clientListSearchApi = async (page: number, limit: number, keyword?: string) => {
        try {
            const response = await DoctorOnAPI.shared.smsHospital.clientSearchList(page, limit, keyword, editClientType());

            if (isRequestSucceed(response)) {
                setHospitalData(response.data?.clients);
                setTotalPages(response.data?.pagination.totalPages || 0);
                setTotalCount(response.data?.pagination.totalCount || 0);
            }
            return response;
        } catch (e: any) {
            console.log(e);
        }
    };

    // search button click handler
    const clickSearchButton = () => {
        clientListSearchApi(1, 10, searchValue);
        setPage(1);
        handlePageGroup(0, 1);
    };

    // search value delete button click handler
    const clickSearchValueDeleteButton = () => {
        setSearchValue('');
        setClientType(0);
        clientListSearchApi(1, 10, '');
        setPage(1);
        handlePageGroup(0, 1);
    };

    // 등록하기 버튼
    const clickRegisterButton = () => {
        navigate('/addClient');
    };

    const handleDropdown = (idx: number) => {
        setPage(1);
        handlePageGroup(0, 1);
        setClientType(idx);
    };

    const editClientType = () => {
        if (clientType === 0) {
            return '';
        } else {
            return String(clientType - 1);
        }
    };

    return (
        <Wrapper>
            <ListTitle title='거래처'>
                <SearchContainer>
                    <DropdownWithLabel
                        label='타겟'
                        selected={clientType}
                        handleDropdown={handleDropdown}
                        type='clientType'
                        list={filterClientTypeList}
                        onDropdown={onDropdown}
                        setOnDropdown={setOnDropdown}
                        buttonEdit={true}
                        $dropdownWidth='100px'
                        $dropdownHeight='208px'
                        $height='40px'
                        $width='180px'
                        $labelWidth='50px'
                    />
                    <SearchInput searchValue={searchValue} onChange={setSearchValue} onChangeDeleteButton={clickSearchValueDeleteButton} handleSearchFilter={clickSearchButton} />
                    <BasicButton $width='100px' $height='40px' $type='outLine' onClick={clickRegisterButton}>
                        등록
                    </BasicButton>
                </SearchContainer>
            </ListTitle>
            <ListSubTitle target={target} level={selectedUser && selectedUser.user.level} />
            {hospitalData && hospitalData.length > 0 ? (
                <ClientListTable
                    data={hospitalData}
                    totalCount={totalCount}
                    currentPage={currentPage}
                    onChangeCurrentPage={setPage}
                    onChangeSectionIdx={handlePageGroup}
                    sectionIndex={pageGroup}
                    totalPages={totalPages}
                />
            ) : (
                <NoListContainer>표시 할 내용이 없습니다.</NoListContainer>
            )}
        </Wrapper>
    );
};

export default ClientListPage;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 4px;
`;

const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 24px;
    width: 100%;
`;

const NoListContainer = styled.div`
    height: 100%;
    @media (min-height: 768px) {
        height: 585px;
    }

    @media (min-height: 1024px) {
        height: 75%;
    }
`;
