import React, { useState } from "react";
import styled from "styled-components";
import { Modal, DatePicker, Spin } from 'antd';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import _ from "lodash";
import moment from "moment";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { DownloadButton } from "../DownloadButton";
import { 
  IOoUnospayDelivery, 
  OoDeliveryStatus,
  OoOrderPlatformType
} from "@api/models";
import { IOoHospital } from "@api/models/hospital";
import { IOoProducts } from "@api/models/productSetting/product";
import { IOoUnospayProvider } from "@api/models/unospay/provider";
import { useQuery } from "react-query";
import { useTlcProductList } from "@queries/unospay/tlcProductQuery";
import { useHospitalListQuery } from "@queries/hospital/useHospitalListQuery";
import { useUnospayProviderListQuery } from "@queries/unospay/unospayProviderListQuery";
import { useClientListQuery } from "@queries/client/useClientListQuery";
import { ITClient } from "@api/models/client/client";

const { RangePicker } = DatePicker;

interface Props {
  list: Array<IOoUnospayDelivery>;
}

function getHospitalTypeToStr(val: number) {
  switch (val) {
    case 0:
      return "병의원";
    case 1:
      return "한의원";
    case 2:
      return "B2B";
    case 3:
      return "피트니스"
    default:
      return "병의원";
  }
}

function getOrderPlatformStr(val: number): string {
  switch (val) {
    case OoOrderPlatformType.kiosk:
      return "키오스크";
    case OoOrderPlatformType.telephone:
      return "전화주문";
    case OoOrderPlatformType.mtmMall:
      return "자사몰";
    default:
      return "-";
  }
}

export const ExcelRawDataButton = ({ list }: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>([
    dayjs().subtract(7, 'day'),
    dayjs()
  ]);

  const { 
    data: productList, 
    isLoading: isProductLoading,
    refetch: refetchProducts 
  } = useTlcProductList();

  const { 
    data: clientList, 
    isLoading: isClientLoading,
    refetch: refetchClients 
  } = useClientListQuery();

  const { 
    data: providerList, 
    isLoading: isProviderLoading,
    refetch: refetchProviders 
  } = useUnospayProviderListQuery();

  const isLoading = isProductLoading || isClientLoading || isProviderLoading;

  const handleModalOpen = async () => {
    setModalVisible(true);
    await Promise.all([
      refetchProducts(),
      refetchClients(),
      refetchProviders()
    ]);
  };

  const getProductName = (externalCode: string) => {
    if (!productList) return "-";
    const product = productList.find((p: IOoProducts) => p.externalCode === externalCode);
    return product ? product.productName : "-";
  };

  const getOptionName = (externalCode: string, optionExternalCode: string) => {
    if (!productList) return "-";
    const option = productList
      .find((p: IOoProducts) => p.externalCode === externalCode)
      ?.optionList
      .find((o: any) => o.optionCode === optionExternalCode);
    return option ? option.optionName : "-";
  };

  const getClient = (code: string) => {
    if (!clientList) return undefined;
    const client = clientList.find((c: ITClient) => _.toUpper(c.code) === _.toUpper(code));
    return client;
  };

  const getProviderName = (code: string) => {
    if (!providerList) return "-";
    const provider = providerList.find((p: IOoUnospayProvider) => p.externalCode === code);
    return provider ? provider.name : "-";
  };

  const getHospitalSplitAddress = (code: string): Array<string> | undefined => {
    if (!clientList) return undefined;
    let result;
    clientList.forEach((e: ITClient) => {
      if(_.toUpper(e.code) === _.toUpper(code)){
        result = e.regionKeywordList;
      }
    });
    return result;
  };

  const getProductTypeToStr = (externalCode: string): string => {
    if (!productList) return "-";
    const product = productList.find((p: IOoProducts) => p.externalCode === externalCode);
    const getProductType = (type:number|undefined)=> {
      switch (type) {
        case 0:
          return 'MTM';
        case 1:
          return 'MTE';
        case 2:
          return 'MTS';
        case 3:
          return 'MTC';
        default:
          return '-';
      }
    }
    return product ? getProductType(product.productType) : "-";
  };

  const handleDownload = () => {
    const filteredList = list.filter(item => {
      const itemDate = dayjs(item.dateTime || item.timestamp);
      return itemDate.isAfter(dateRange[0].startOf('day')) && 
             itemDate.isBefore(dateRange[1].endOf('day'));
    });

    const today = moment(new Date()).format("YYYY-MM-DD");
    const excelFileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const excelFileExtension = ".xlsx";
    const excelFileName = `MTM매출관리_${today}`;

    const title = XLSX.utils.aoa_to_sheet([
      [
        "년", "월", "주문일", "구분", "거래처명", "업체명", "상품[대분류]", 
        "상품[중분류]", "상품명", "결제 금액", "고객명", "마진율", "지역-도", 
        "지역-시", "진료과", "구매방법", "재구매여부", "연락처", "결제 방법", 
        "보관방법", "기간", "개수"
      ]
    ]);

    title['!cols'] = [{ wch: 15 }];

    const book = XLSX.utils.book_new();
    for (let i = 0; i < filteredList.length; i++) {
      if (
        filteredList[i].status != OoDeliveryStatus.existAddress &&
        filteredList[i].status != OoDeliveryStatus.sendPackage
      ) {
        continue;
      }

      const hCode = _.trim(_.split(filteredList[i].affiliateId, "_")[1]);
      let dbOrderDate;
      if (filteredList[i].dateTime == "" || _.isUndefined(filteredList[i].dateTime)) {
        dbOrderDate = moment.utc(filteredList[i].timestamp).format("YYYY-MM-DD");
      } else {
        dbOrderDate = filteredList[i].dateTime;
      }

      const orderYear = moment(dbOrderDate).format("YYYY년");
      const orderMonth = moment(dbOrderDate).format("M월");
      const orderDay = moment(dbOrderDate).format("DD일");

      for (let j = 0; j < filteredList[i].salesMenu!.length || 0; j++) {
        let priceVal = parseInt(filteredList[i].salesMenu![j].menuPrice!) || 0;
        const optionCount = _.isUndefined(filteredList[i].salesMenu![j].salesOption)
          ? 0
          : filteredList[i].salesMenu![j].salesOption!.length;
        
        for (let k = 0; k < optionCount; k++) {
          priceVal += parseInt(filteredList[i].salesMenu![j].salesOption![k].optionPrice!) || 0;
        }
        priceVal = parseInt(filteredList[i].salesMenu![j].menuCount!) * priceVal;

        XLSX.utils.sheet_add_aoa(
          title,
          [[
            orderYear || "-",
            orderMonth || "-",
            orderDay || "-",
            getHospitalTypeToStr(getClient(hCode)?.clientType ?? 0) || "-",
            getClient(hCode)?.name || "-",
            getProviderName(_.split(filteredList[i].salesMenu![j].externalCode!, "_")[0]) || "-",
            getProductTypeToStr(filteredList[i].salesMenu![j].externalCode!) || "-",
            "",
            getProductName(filteredList[i].salesMenu![j].externalCode!) || "-",
            { v: priceVal, t: 'n', z: '#,##0' },
            filteredList[i].recipientName || "-",
            "",
            getHospitalSplitAddress(hCode)?.[0] || "-",
            getHospitalSplitAddress(hCode)?.[1] || "-",
            "",
            getOrderPlatformStr(filteredList[i].orderPlatform!) || "-",
            filteredList[i].reorderCount! > 0 ? "재구매" : "첫구매",
            filteredList[i].phoneNumber || "-",
            "유상",
            "",
            _.split(getOptionName(filteredList[i].salesMenu![j].externalCode!, filteredList[i].salesMenu![j].salesOption?.[0]?.externalCode ?? '') || "-", '/')[0] || "-",
            _.split(getOptionName(filteredList[i].salesMenu![j].externalCode!, filteredList[i].salesMenu![j].salesOption?.[0]?.externalCode ?? '') || "-", '/')[1] || "-",
          ]],
          { origin: -1 }
        );
      }
    }

    const range = XLSX.utils.decode_range(title["!ref"]!);
    for (let row = range.s.r + 1; row <= range.e.r; ++row) {
      const cell = XLSX.utils.encode_cell({ r: row, c: 9 });
      if (title[cell]) {
        title[cell].z = '#,##0';
      }
    }

    XLSX.utils.book_append_sheet(book, title, "매출내역");
    const excelButter = XLSX.write(book, { bookType: "xlsx", type: "array" });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
    
    setModalVisible(false);
  };
  
  return (
    <div style={{ marginRight: "20px", display: "flex" }}>
      <DownloadButton
        title="엑셀 다운로드"
        iconSrc="/img/ic_download.png"
        onClick={handleModalOpen}
      />
      
      <Modal
        title="엑셀 다운로드 기간 설정"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={handleDownload}
        okText="다운로드"
        cancelText="취소"
        okButtonProps={{ disabled: isLoading }}
      >
        <div style={{ padding: '20px 0' }}>
          {isLoading ? (
            <div style={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center',
              minHeight: '100px'
            }}>
              <Spin tip="데이터를 불러오는 중입니다..." />
            </div>
          ) : (
            <>
              <h4 style={{ marginBottom: '12px', fontWeight: 500 }}>날짜 선택</h4>
              <RangePicker
                value={dateRange}
                onChange={(dates) => {
                  if (dates) {
                    setDateRange([dates[0]!, dates[1]!]);
                  }
                }}
                format="YYYY-MM-DD"
              />
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};
